import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import { isValidSim } from '../../utils/validation';
import cameraApi from "../../api/cameraApi";
import cameraModelApi from "../../api/cameraModelApi";
import DeleteSimAssociation from "../setting/form/deleteSimAssociation";
import acceptSims from "../../utils/acceptSims";
import ModalFactory from "../../components/modal/ModalFactory"

const handleFocus = (event) => event.target.select();
class ProductSim extends Component {
    constructor() {
        super();
        this.state = {
            scanner: "",
            errors: {},
            camera: null,
            modelInformation: "",
            cameraModels: "",
            showDel: false,
            delSimNumber:"",
            acceptedSims: [],
            showSimError: false,
            simNumber: ""
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);

    };

    componentDidMount() {
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
    };

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {

    };

    assignSim = async (simNumber) => {
      const { t } = this.props;

      if (simNumber !== "") {

        const data = { "sim": simNumber }
        this.setState({ simNumber : {"sim": simNumber}})

        var isSimCanBeAdded = acceptSims(simNumber, this.state.acceptedSims, this.props.auth.user.role, this.showModalYesNo)
        if (isSimCanBeAdded == true) {
            cameraApi.putCameraAssignSim(this.state.camera.id, data)
            .then(res => {
                this.getCamera(this.state.camera.imei)
            })
            .catch(err => {
                const errors = err.response ? t(err.response.data.message) : err;
                this.setState({ errors: { scanner : errors }})
            });
        } else if (isSimCanBeAdded == false) {
            this.setState({ errors: { scanner : "That Sims is not accepted to match that CameraModel" }})
        }
      }
    }

    handleKeyPress = async e => {
        this.setState({ errors: {} });
        if (e.key === 'Enter' && e.target.value !== "") {
            if (isValidSim(e.target.value)) {
                if (this.state.camera) {
                  this.assignSim(e.target.value);
                } else {
                  this.setState({ errors: { scanner: "Scan camera first" } })
                }
                this.setState({ scanner: "" })
            }
            else if (e.target.id === "scanner") {
                const scanImei = e.target.value.split(/,|;/)[1]
                this.getCamera(scanImei)

            } else {
                this.setState({ errors: { scanner: "Scan not recognized" } })
                e.target.select();
            }
        }
    };

    getCamera = async imei => {
        cameraApi.getCamera(imei)
        .then(res => {
        let camera = res.data

        cameraModelApi.getCameraModel(res.data.cameraModel)
            .then(res => {
                var currentFW = res.data.versions.find(i => i.stage === 'default')
                var modelInformations = `${res.data.masterPackDescription}\nCurrent FW version : ${currentFW.name}\nRelease date : ${new Date(currentFW.date).toLocaleString()}\nGTIN : ${res.data.barcode}`
                this.setState({ scanner: "", camera: camera, modelInformation: modelInformations, acceptedSims: res.data.optionsSims })
            })
            .catch(err => {
                this.setState({ 
                    errors: { scanner: "Error - not able to find camera Model" },
                    camera: "", 
                    modelInformation: "",
                })
            })
            
        }) // re-direct to login on successful register
        .catch(err => {
            this.setState({ errors: { scanner: "Error finding camera imei:" + imei } })
        });
    }

    removeSimCard = async delSimNumber => {
        const { t } = this.props;
        let camInfo = this.state.camera;
        for (let i = 0; i < camInfo.sims.length; i++) { 
            if ( camInfo.sims[i] === delSimNumber) { 
                camInfo.sims.splice(i, 1); 
            }
        }
        let updateSims = {sims: camInfo.sims};
        cameraApi.putCamera(this.state.camera.id, updateSims)
            .then(res => {
                cameraApi.putCameraFootprint(this.state.camera.id, {"step": "unassignSim", "notes": delSimNumber})
                .then(res2 => {
                    this.setState({ delSimNumber: '', showDel: false})
                    this.getCamera(this.state.camera.imei)
                })
            })
            .catch(err => {
                const errors = err.response ? t(err.response.data.message) : err;
                this.setState({ errors: { scanner : errors }})
            });
    }

    handleDelClose = e =>{
        if (e.target.id === 'ok') {
            this.removeSimCard(this.state.delSimNumber)
        } else {
            this.setState({ delSimNumber: '', showDel: false})
        }
    }

    showDeleteWindow = e => {
        this.setState({ delSimNumber: e.target.id, showDel: true})
    }


    acceptYesNo = e => {
        const { t } = this.props;
        cameraApi.putCameraAssignSim(this.state.camera.id, this.state.simNumber)
        .then(res => {
            this.getCamera(this.state.camera.imei)
        })
        .catch(err => {
            const errors = err.response ? t(err.response.data.message) : err;
            this.setState({ errors: { scanner : errors }})
        });

        this.setState({ showSimError: false })
        return true
    }

    cancelYesNo = e => {
        this.setState({  showSimError: false, errors: { scanner : "That Sim is not accepted to match that CameraModel" }})
        return false
    }

    showModalYesNo = e => {
        this.setState({ showSimError: true })
    }


    render() {
        const { t } = this.props;
        const { errors } = this.state;
        const camera = this.state.camera || [];

        const addLineBreaks = string =>
          string.split('\n').map((text, index) => (
              <React.Fragment key={text + "-" + index}>
                  {text}
                  <br />
              </React.Fragment>
          ));

        const displaySim = camera => 
            camera && camera.sims && camera.sims.map((sim) => (
                <React.Fragment>
                    <tr key={sim}>
                        <td>{sim}</td>
                        <td><button type="button" id={sim} className="waves-effect waves-light btn-small  blue-grey darken-1 white-text" onClick={this.showDeleteWindow}   ><i id={sim} className="large material-icons ">delete</i></button></td>
                    </tr>
                </React.Fragment>
            ));

        return (
            <div>
                <div style={{ marginTop: "30px" }} className="container">
                {
                    this.state.showSimError === true && 

                    <ModalFactory modalMode="modalYesNo" buttonSaveText={t('overwrite_sims')} 
                     buttonCancelText={t('cancel')} 
                    onClickSave={this.acceptYesNo} 
                    onClickCancel={this.cancelYesNo}
                    show={this.state.showSimError} 
                    title={t('title_overwrite_sims')} 
                    paragraph={t('paragraph_overwrite_sims')} />
                }
                {this.state.showDel === true &&
                    <DeleteSimAssociation
                        delSim={this.state.delSimNumber}
                        onClose={this.handleDelClose}
                    />
                }
                    <div className="row valign-wrapper">
                        <div className="col s12 center-align">
                            <Row>
                                <Col s={12} m={3} >
                                </Col>
                                <Col s={12} m={6} >
                                    <h4>{t('dashboard_main.product_sim')}</h4>
                                    <h6>{t('dashboard_main.product_sim_desc')}</h6>
                                </Col>
                            </Row>
                            <form noValidate onSubmit={this.onSubmit}>
                                <Row>
                                    <div className="input-field col s4">
                                        <input
                                            onChange={this.onChange}
                                            onFocus={handleFocus}
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.scanner}
                                            error={errors.scanner}
                                            id="scanner"
                                            type="text"
                                            autoFocus
                                            className={classnames("", {
                                                invalid: errors.scanner
                                            })}
                                        />
                                        <label htmlFor="scanner" className="active">{t('dashboard_main.scanner')}</label>
                                        <span id="scannerMessage" className="red-text">
                                            {errors.scanner}
                                        </span>
                                    </div>
                                </Row>
                                <Row>
                                    <Col s={12} m={6} >
                                        <div className="infoCenter" id='modelInformation'>
                                            <span>Model</span>
                                            <p>{addLineBreaks(this.state.modelInformation)}</p>
                                        </div>
                                    </Col>
                                    <Col s={12} m={6} >
                                        <div className="infoCenter" id='modelInformation'>
                                          <span>Camera</span>
                                          <p>
                                            <span>IMEI : { camera.imei}</span><br></br>
                                            <span>SN : { camera.imei}</span>
                                          </p>
                                          
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                                <table style={{ marginTop: "25px" }} >
                                    <thead>
                                        <tr style={{ backgroundColor: "#f2f2f2" }}>
                                            <td>Sims</td>
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displaySim(this.state.camera)}
                                    </tbody>
                                    </table>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ProductSim.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default compose(
    withTranslation(),
    connect(
        mapStateToProps
    )
)(ProductSim);
