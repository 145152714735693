import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import CardInfo from "../dashboard/card/CardInfo"
import DashboardMat from "../../components/material/DashboardMat";



function Dashboard (props) {
  const { user} = props.auth;
  return (
    <div>
      <div style={{ minHeight: "75vh", marginTop: "30px" }} className="container">
        <div className="row valign-wrapper">
          <div className="col s12 center-align dashboard_containter">
              <DashboardMat user={user} cardInfo={CardInfo()}></DashboardMat>
          </div>
        </div>
      </div>
    </div>
  )
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(Dashboard);