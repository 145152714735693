import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import { Row, Col } from "react-materialize";
import classnames from "classnames";
import "../../App.css";

import ElementTable from "../../components/material/ElementTable"
import ButtonFactory from "../../components/material/ButtonFactory"
import rmaInfoApi from "../../api/rmaInfoApi"
import cameraApi from "../../api/cameraApi"
import { cameraValidation } from "../../utils/validation";

const handleFocus = (event) => event.target.select();
const SFbaseUrl = "https://gtx--partial.lightning.force.com/lightning/r/Case/"

function RMAInfo (props) {
    const { t } = props;
    const [scanner, setScanner] = useState("");
    const [errors, setErrors] = useState("");
    const [notes, setNotes] = useState("");
    const [cameraId, setCameraId] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [data, setData] = useState({});
    const [snackBar, setSnackBar] = useState([]);
    const [snackBarError, setSnackBarError] = useState([]);

    useEffect(() => {
      setIsDisabled(shouldSaveFootPrintDisabled())
   }, [data, cameraId]);

    const openSnackBar = (text, isSuccess) => {
        const newSnackBar = [];
        newSnackBar.push({text: text})
        if (isSuccess == true) {
          setSnackBar(newSnackBar);
        } else {
          setSnackBarError(newSnackBar);
        }
        setTimeout(() => {
          const emptyArray = [];
          setSnackBar(emptyArray);
          setSnackBarError(emptyArray);
          }, 3000);
    };

      function callBackgetRmaInfo(response) {
            if (response.length > 0) {
                openSnackBar("Get RMA Info Success", true)
                setData(response[0])
            } else {
                openSnackBar("RMA Not Found", false)
                setData({})
            }
      }

      function callBackError(response) {
        openSnackBar("Server Error : " + response, false)
      }

      function onChange(e) {
        setScanner(e.target.value);
      };

      function handleKeyPress(e) {
        if (e.key === 'Enter' && e.target.value !== "") {
            if(isValidData()) {
                setErrors("")
                rmaInfoApi.getRmaInfo(takeOnlyCaseNumber(), callBackgetRmaInfo, callBackError)
            } else if (isValidDataCamera()) {
              setErrors("")
              cameraApi.getCamera(takeOnlyIMEI()).then(
                res => {
                  openSnackBar("Get Camera Success", true)
                  setCameraId(res.data.id)
                }
              ).catch(err => {
                openSnackBar("Get Camera Failed", false)
                setCameraId("")
              })
            }
             else {
                setErrors("Errors - format RMA = RMA-12345678 or 12345678. Format Camera => IMEI = 15 numbers")
            }
          }
      }

      function shouldSaveFootPrintDisabled() {
        var disabled = false
        if ( !(data.Id != undefined && cameraId != "") ) {
          disabled = true
        }

        return disabled
      }

      function takeOnlyIMEI() {
        var onlyNumber = new RegExp(/\d{15}/).exec(scanner);
        return onlyNumber[0]
      }

      function takeOnlyCaseNumber() {
        var onlyNumber = new RegExp(/\d{8}/).exec(scanner);
        return onlyNumber[0]
      }

      function isValidData() {
        var returnValue = false; 
          if ((new RegExp(/^RMA-\d{8}/).test(scanner) && scanner.length == 12 )|| (new RegExp(/\d{8}/).test(scanner)) && scanner.length == 8) {
            return true;
          }
          return returnValue;
      }

      function isValidDataCamera() {
        var returnValue = false; 
          if ((new RegExp(/\d{15}/).test(scanner))) {
            return true;
          }
          return returnValue;
      }

      function saveFootPrint() {
          const newStage = {
            step: "RMA",
            notes: notes
          }

          cameraApi.putCameraFootprint(cameraId, newStage)
          .then(res => {
              setCameraId("")
              setData({})
              setNotes("")
              openSnackBar("Put Camera FootPrints Success", true)
          })
          .catch(err => {
              openSnackBar("Put Camera FootPrints Failed", false)
          });
      }

    return (
        <div>
            <Row className="center-align">
                <Col s={12} >
                    <h4>{t('dashboard_main.rmaInfo')}</h4>
                    <h6>{t('dashboard_main.rmaInfo_desc')}</h6>
                </Col>
            </Row>
            <Row className="center-align">
                <Col s={6} offset="s3">
                    <div className="input-field">
                        <input
                        onChange={onChange}
                        onFocus={handleFocus}
                        onKeyPress={handleKeyPress}
                        value={scanner}
                        error={errors}
                        id="scanner"
                        type="text"
                        autoFocus
                        className={classnames("", {
                          invalid: errors
                        })}
                        />
                        <label htmlFor="scanner" className="active">{t('dashboard_main.scanner')}</label>
                        <span id="scannerMessage" className="red-text">
                            {errors}
                        </span>
                    </div>
                </Col>
            </Row>
            <ElementTable data={data} text1="Status : " accessor1="Status" text2="Nature of requests : " accessor2="Nature_of_Request__c" />
            <ElementTable data={data} text1="Resolution Code : " accessor1="Resolution_Code__c" text2="RMA State : " accessor2="RMA_STATE__c" />
            <Row className="center-align">
                <Col s={6} offset="s3">
                    {data.attributes && <a href={SFbaseUrl + data.Id + '/view'} target="_blank">Link to SalesForce RMA</a> }
                </Col>
            </Row>
            <Row className="center-align">
              <Col s={2} offset="s1" className="right-align">
                  <p style={{fontWeight: "bold"}}>Camera ID : </p>
              </Col>
              <Col s={3} className="left-align">
                  <p>{cameraId} </p> 
              </Col>
                <Col s={4} >
                <div className="input-field">
                    <input
                      onChange={e => setNotes(e.target.value)}
                      value={notes}
                      id="notes"
                      type="text"
                    />
                    <label htmlFor="notes" className="active">Notes Footprints</label>
                  </div>
                </Col>
            </Row>
            <Row className="center-align">
                <Col s={3} offset="s6">
                    <ButtonFactory disabled={isDisabled} name="FootPrint" text="Save FootPrint" onClick={saveFootPrint} />
                </Col>
            </Row>
            <div className = {snackBarError.length > 0 ? ['snackbarError', 'show'].join(" ") : 'snackbarError'}>
                { snackBarError.length > 0 ? snackBarError[0].text : '' }
            </div>
            <div className = {snackBar.length > 0 ? ['snackbar', 'show'].join(" ") : 'snackbar'}>
                { snackBar.length > 0 ? snackBar[0].text : '' }
            </div>
      </div>
    )
}

RMAInfo.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });
  
  export default compose(
    withTranslation(),
    connect(
      mapStateToProps
    )
  )(RMAInfo);