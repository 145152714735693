import {
    SET_SEARCH_RETURN,
    LOADING
  } from "../actions/types";
  const initialState = {
    isAuthenticated: false,
    user: {},
    loading: false,
    searchResult : []
  };
  export default function(state = initialState, action) {
    switch (action.type) {
      case SET_SEARCH_RETURN:
        return {
          ...state,
          searchResult: action.payload
        };
      case LOADING:
        return {
          ...state,
          loading: true
        };
      default:
        return state;
    }
  }
