import React, { Component } from "react";
import { Col } from "react-materialize";

import { prodapiAxiosClient } from "../../../api/prodApiClient";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
class EditVersions extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      versions: [],
      versionName: "",
      showEditVersion: true
    }
  }

  componentDidMount() {
    this.setState({ name: this.props.nameCamera })
    let name = this.props.nameCamera

    prodapiAxiosClient
      .get("/api/v1/cameraModel/getinfo/" + name)
      .then(res => {
        let models = res.data;
        this.setState({ versions: models.versions })
      })
      .catch(err =>
        console.log("error : " + err)
      );
  }


  cancelButton = e => {
    this.setState({ showEditVersion: false })
  }

  handleChange(event) {
    if (event.target.id === "newVersion") {
      this.setState({ versionName: event.target.value })
    }
  }
  onSubmit = e => {
    let upName = this.state.name
    let upVersion = this.state.versionName
    prodapiAxiosClient
      .put("/api/v1/cameraModel/versions/" + upName + "/" + upVersion)
      .then(res => {

      })
      .catch(err => {
        console.log("ERROR !")
      });
  }




  render() {
    let allVersions = []
    if (this.state.versions) { 
      allVersions = this.state.versions.map((item, key) =>
      <tr key={item.name}>
        <td>{item.name}</td>
        <td> {item.stage}</td>
        <td> {new Date(item.date).toLocaleString()}</td>
      </tr>
    );}


    const { t } = this.props;

    const hideStyle = this.state.showEditVersion ? {} : { display: 'none' };
    return (
      <div style={hideStyle}>
        <div id="versions" style={{ position: "fixed", width: "450px", backgroundColor: "#e6e6e6", borderRadius: "7px", paddingTop: "0px", marginTop: "-466px", marginLeft: " calc(56% - 730px)", zIndex: "999", boxShadow: "9px 11px 20px 0px" }} >
          <Col s={12} >
            <h3 className="center-align defaultTheme white-text" >
              {t('setting.version_edit')}
            </h3>
          </Col>
          <form name="versionForm" id="versionForm" onSubmit={this.onSubmit}>
            <div id="listVersions" style={{ overflow: "auto", maxHeight: "350px", padding: "15px" }}>
              <table style={{ marginTop: "25px" }} >
                <thead>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td>{t('setting.version')}</td>
                    <td>{t('setting.version_stage')}</td>
                    <td>{t('setting.date')}</td>
                  </tr>
                </thead>
                <tbody>
                  {allVersions}
                </tbody>
              </table>
            </div>
            <label className="active" htmlFor="newVersion" style={{ width: "100px", display: "inline-block" }}>{t('setting.new_version')}</label>
            <input type="text" id="newVersion" className=" center-align " value={this.state.versionName} onChange={e => this.setState({ versionName: e.target.value })} style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "45%", marginTop: "10px" }} />
            <hr />
            <div className="row " style={{ float: "right" }}>
              <ButtonFactory name='Submit' text={t('ok')} onClick={this.onSubmit} style={{marginRight:"35px"}}/>
              <ButtonFactory name='Cancel' text={t('cancel')} onClick={this.cancelButton} style={{marginRight:"15px"}}/>
            </div>
          </form>
        </div>
      </div>
    );
  };

};

EditVersions.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(EditVersions);