import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from "redux";
import '../../../src/loader.css';
import { ExportToCsv } from 'export-to-csv';
import DatePickerFactory from "../../components/material/DatePicker";
import dayjs from 'dayjs';
import reportingApi from  "../../api/reportingApi";
import logApi from "../../api/logApi";
import jwt_decode from "jwt-decode";
import ReactTableBuilder from "../../utils/reactTableBuilder";
const exceljs = require('exceljs');

class Reporting extends Component {
  constructor() {
    super();

    this.state = {
      startDate: dayjs(),
      endDate: dayjs(),
      errors: {},
      report: "",
      reportUrl : "",
      params: [],
      query: "",
      queryID: "",
      dataReport: [],
      displayColumns: [],
      search: "",
      txtInput: "",
      titleReport: "",
      authorized: false
    };
  };

  componentDidMount() {
    const queryParams = window.location.href;
    const querySplit = queryParams.split("/");
    const urlqueryLentgh = querySplit.length
    const queryId= querySplit[urlqueryLentgh -1]
    this.setState({ reportUrl: queryId })
    this.setDate();

    reportingApi.getReportById(queryId)
    .then(res => {
      // Sets authorized to true if the role or the user have the authorization to see the report
      const decoded = jwt_decode(localStorage.getItem("jwtToken"));
      if(res.data.roles.includes(decoded.user.role) || res.data.users.includes(decoded.user.email)) {
        this.setState({ authorized: true });
      }
      let body = {
        params: [],
        id: res.data.id
      }
      this.setState({ params: res.data.params, query: res.data.query, queryID: res.data.id, displayColumns: res.data.displayColumns, search: res.data.search, titleReport: res.data.textSpan })
      res.data.params.forEach(param => {
        if (param.name === "dateFrom") {
          body.params.push({ name: param.name, value: this.state.startDate, type: param.type })
        } else if (param.name === "dateTo") {
          body.params.push({ name: param.name, value: this.state.endDate, type: param.type })
        } else {
          body.params.push({ name: param.name, value: "", type: param.type })
        }
      });
      // ici on va chercher les infos d'apres les info recueilli
      reportingApi.executeQuery(this.state.queryID, body)
      .then(res=>{
        this.setState({dataReport: res.data})
      })
    })
    .catch(err=>{
      console.log(err);
    })

  }

  setDate = () => {
    const date = dayjs();
    let startDate = date.add(-7, 'day');
    this.setState({ endDate: dayjs(), startDate: startDate})
  }

  handleKeyPress = e => {
    //const { t } = this.props;
    this.setState({ errors: {} });
    if (e.key === 'Enter' && e.target.value !== "") {
      this.onSubmit(e);
    }
  }

  onSubmit = e => {
    const { t } = this.props;
    let submitbody = {
      params: [],
      id: this.state.queryID
    };
     //do reports here create dynamic body
    this.state.params.map((p => {
      if (p.name.includes('date')) {
        if (p.name === 'dateFrom') {
          submitbody.params.push({ name: p.name, value: this.state.startDate, type: "date" })
        } else {
          submitbody.params.push({ name: p.name, value: this.state.endDate, type: "date" })
        }
      } else {
        const inputName = document.getElementById("t" + p.name).value
        submitbody.params.push({ name: p.name, value: inputName, type: "text"})
      }
    }))
    reportingApi.executeQuery(this.state.queryID, submitbody)
    .then(res=>{
      this.setState({dataReport: res.data})
    })
    e.preventDefault();
  }
   
  onClearReport = e => {
    this.setState({ dataReport: [], startDate: dayjs() });
    this.setDate();
  }

  async exportToExcel() {
    this.state.dataReport.forEach(data => {
      Object.keys(data).forEach(field => {
        if (Array.isArray(data[field]) && data[field].length === 1) {
          data[field] = data[field][0]
        }
      })
    })
    const workbook = new exceljs.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
  
    // Add data to the worksheet
    worksheet.addTable({
      name: 'MyTable',
      ref: 'A1',
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium2',
        showRowStripes: true,
      },
      columns: this.state.displayColumns.map(column => ({ name: column, filterButton: true })),
      rows: this.state.dataReport.map(rowData => this.state.displayColumns.map(column => rowData[column]))
    });

    worksheet.columns.forEach(column => {
      column.width = 30
    })

    try {
      const d = new Date()
      const fileName = this.state.titleReport.replace(/ /g, "_") + d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      link.click()
      URL.revokeObjectURL(link.href)
    } catch (err) {
      console.log('Error exporting data to Excel:', err);
    }
  }

  exportToCsv = () => {
    let data = this.state.dataReport;
    let newData = [];
    
    for(var i = 0; i < data.length; i++) {
      //construction d'une string pour mettre les datas,en bouclan avec les colones que l'on veut afficher, convertie en json plus loin
      var stringData = "{"
      for(var j = 0; j < this.state.displayColumns.length; j++){  
        var splitCol = this.state.displayColumns[j].split(".")
        if(splitCol.length === 1){
          stringData = stringData + '"'+this.state.displayColumns[j]+'"'+":"+'"'+data[i][this.state.displayColumns[j] ]+'"'
        }else{
          if(this.state.displayColumns[j].includes("date")){
            stringData = stringData + '"'+this.state.displayColumns[j]+'"'+':'+'"'+ new Date(data[i][splitCol[0]][splitCol[1]]).toLocaleString("en-CA", { hour12: false }).replace(/,/g, '')+'"'
          }else{
            stringData = stringData + '"'+this.state.displayColumns[j]+'"'+':'+'"'+ data[i][splitCol[0]][splitCol[1]]+'"'
          }
        }
        if(j < this.state.displayColumns.length -1 ){
          stringData = stringData + ","
         
        }
      }
      stringData = stringData +  "}"
      stringData = stringData.replace(/\r/g, "")
      newData[i] = JSON.parse(stringData)
    };
    let d = new Date();
    const options = {
      fieldSeparator: ';',
      filename: this.state.titleReport + d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate(),
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      headers: this.state.displayColumns
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(newData);
  }

  onExport = (e) => {
    if (e.target.id === "btnExportExcel") {
      this.exportToExcel()
    } else {
      this.exportToCsv()
    }
    const logInfo = {
      message: this.props.auth.user.name + " has exported " + this.state.titleReport
    }
    logApi.logInfo(logInfo)
    .catch(err =>
      console.log("error : " + err)
    );
  }

  setStartDate = e => {
    this.setState({ startDate: dayjs(e.target.value) });
  }

  setEndDate = e => {
    this.setState({ endDate: dayjs(e.target.value) });
  }

  setTxtInput = e => {
    this.setState({ txtInput: e});
  }

  render() {
    const { errors, startDate, endDate, dataReport, params, displayColumns, titleReport } = this.state;
    const { t } = this.props; 
    var columns = []
    columns = displayColumns.map((col) => {
      if (col.includes('date')) {
        return {
          Header: t('setting.date'),
          id: 'date',
          accessor: col,
          Cell: ({ value }) => <div>{new Date(value).toLocaleString(navigator.language, { hour12: false }).replace(/,/g, '')}</div>
        }
      } else {
        return {
          Header: col,
          id: col,
          accessor: col,
          Cell: ({ value }) => <div>{value}</div>,
        }
      }
    })
    // If the role or the user is authorized, they can see the page
    if (this.state.authorized) {
      return (
        <div>
          <div style={{ marginTop: "30px" }} className="container">
            <div className="row valign-wrapper">
              <div className="col s12 center-align">
                <form noValidate onSubmit={this.onSubmit} >
                  <Row>
                    <Col s={12} m={12} >
                   
                      <h4>{t('dashboard_main.reporting')}</h4>
                      <h6>{titleReport}</h6>
                    </Col>
                  </Row>
                  <Row>
                      {params.map((item, index) => {
                        if(item.type === "date" && item.name === "dateFrom"){
                        return  <Col s={12} m={4} >
                                  <div className="input-field" style={{ top: "1rem" }}>
                                  <DatePickerFactory value={startDate} label={item.name} id={item.name} onChange={this.setStartDate} /> 
                                  </div>
                                </Col>
                        }
                        else if( item.type !== "date"){
                          return <Col s={12} m={4} ><div className="input-field" style={{ top: "1rem" }}><input   error={errors.scanner}  id={"t"+item.name} type="text"  />
                          <label className="active" htmlFor={"t"+item.name}>{item.name}</label></div> </Col>
                        }
                      })}
                    <Col s={12} m={4} >
                      {params.map((item, index) => {
                        if(item.type === "date" && item.name === "dateTo"){
                          return  <div className="input-field" style={{ top: "1rem", left: "2rem" }}>
                                    <DatePickerFactory value={endDate} label={item.name} id={item.name} onChange={this.setEndDate}  />
                                  </div>
                      }
                    })}
                    </Col> 
                  </Row>
                  <Row>
                    <Col s={12} m={12}>
                      <button
                        style={{
                          width: "250px",
                          borderRadius: "3px",
                          letterSpacing: "1.5px",
                          top: "3rem",
                          right: "5rem"
                        }}
                        type="submit"
                        id="btnsubmit"
                        error={errors.report}
                        disabled={ startDate === "" || endDate === "" }
                        className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                        >{t('dashboard_main.generate_report')}
                      </button>
                      <button
                        style={{
                          width: "250px",
                          borderRadius: "3px",
                          letterSpacing: "1.5px",
                          top: "3rem",
                          right: "2rem"
                        }}                      
                        type="cancel"
                        id="btncancel"
                        error={errors.report}
                        onClick={this.onClearReport}
                        disabled={ dataReport.length === 0 }
                        className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                        >{t('dashboard_main.clear_report')}
                      </button>
                      <button
                        style={{
                          width: "250px",
                          borderRadius: "3px",
                          letterSpacing: "1.5px",
                          top: "3rem",
                          left: "1rem",
                          right: "1rem"
                        }}
                        type="button"
                        id="btnExportCsv"
                        error={errors.report}
                        onClick={this.onExport}
                        disabled={ dataReport.length === 0 }
                        className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                        >{t('dashboard_main.export_csv')}
                      </button>
                      <button
                        style={{
                          width: "250px",
                          borderRadius: "3px",
                          letterSpacing: "1.5px",
                          top: "3rem",
                          left: "4rem"
                        }}
                        type="button"
                        id="btnExportExcel"
                        error={errors.report}
                        onClick={this.onExport}
                        disabled={ dataReport.length === 0 }
                        className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                        >{t('dashboard_main.export_excel')}
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
            <div>
              <Row>
                <Col s={12} m={12}>
                  <div className="input-field" style={{ fontWeight:"bold", top: "4rem", bottom: "2rem",fontSize: "large" }}></div>
                  <ReactTableBuilder columns={columns} data={dataReport} maxRows={10} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      );
    } else {
      return <h3>{t('message.not_authorized')}</h3>;
    }
  }
}

Reporting.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
withTranslation(),
connect(
  mapStateToProps
)
)(Reporting);