import React, { useState } from "react";
import { Col } from "react-materialize";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
import cameraModelApi from "../../../api/cameraModelApi";

function EditMasterPackQuantities(props) {
  const { t } = props;
  const [masterpackQuantities, setMasterpackQuantities] = useState(props.masterPackQuantities || [])
  const [render, setRender] = useState(false)
  const [error, setError] = useState('')

  /**
   * Adds empty fields so that the user can fill the up
   */
  function addElement() {
    setMasterpackQuantities([...masterpackQuantities, { barcode: '', masterpackQuantity: '' }])
  }

  /**
   * Removes an element from the array and refreshes the page to see the modifications
   */
  function deleteElement(e) {
    const index = masterpackQuantities.findIndex(masterpackQuantity => masterpackQuantity.barcode === e.target.id)
    masterpackQuantities.splice(index, 1)
    setMasterpackQuantities(masterpackQuantities)
    setRender(!render)
  }

  /**
   * Validates the data before sending the update to the api
   * Updates the collection cameraModel
   */
  function onSubmit() {
    if (validation()) {
      const editCameraModel = {
        name: props.cameraName, 
        barcodeByMasterpack: masterpackQuantities
      }
      cameraModelApi.putCameraModel(editCameraModel)
      .then(res => {
        props.onClose(masterpackQuantities);
      })
      .catch(err => {
        const errors = err.response ? err.response.data : err;
        console.log(errors)
      });
    }
  }

  /**
   * Verifies that there is no empty field and that the masterpackQuantity is a number
   * @returns true if the validation passes, false otherwise
   */
  function validation() {
    let valid = true
    masterpackQuantities.forEach((item) => {
      if (item.barcode === '' || item.masterpackQuantity === '') {
          setError(t('error_empty_field'))
          valid = false
      } else {
        let masterpackQuantity = parseInt(item.masterpackQuantity);
        if (isNaN(masterpackQuantity)) {
            setError(t('error_invalid_quantity'))
            valid = false
        } else {
          item.masterpackQuantity = masterpackQuantity;
          if (validationDuplicateBarcode(item.barcode)) {
            setError(`${t('error_barcode_duplicate_start')} ${item.barcode} ${t('error_barcode_duplicate_end')}`)
            valid = false
          }
        }
      }
    });
    return valid
  };

  /**
   * Verifies that the barcode the user wants to add doesn't already exist in another camera model
   * @returns true if the barcode already exist, false otherwise
   */
  function validationDuplicateBarcode(barcodeToCheck) {
    const barcodeAlreadyExists = props.cameraModels.some(camera =>
      camera.barcodeByMasterpack?.some(item => item.barcode === barcodeToCheck)
    );
    return barcodeAlreadyExists
  }


  /**
   * Close the page when the user presses on the "Cancel" button
   */
  function cancelButton() {
    props.onClose("cancel");
  }

  /**
   * Removes any errors
   * Updates the value in masterpackQuantites with the new value received from the onChange event
   */
  function handleInputChange(event, index) {
    setError('')
    const { name, value } = event.target;
    setMasterpackQuantities(prevState => {
      const newMasterPackQuantities = [...prevState];
      newMasterPackQuantities[index][name] = value;
      return newMasterPackQuantities;
    });
  }

    return(
      <div id="editMasterpackQuantities" style={{  maxHeight: "95%", position:"fixed", width:"700px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"0px", zIndex:"999",overflowY:"scroll", top: "40%", left: "50%", transform: "translate(-50%, -50%)" }} className="container center-align">
       <Col s={12} >
        <h3 className="center-align defaultTheme white-text" >
          {t('input.edit_masterpack_quantities')}
        </h3>
       </Col>
       <form onSubmit={onSubmit} name="userForm" id="userForm">
       <table style={{ marginTop: "25px" }} >
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2" }}>
              <td className="center-align">{t('input.barcode')}</td>
              <td className="center-align">{t('input.quantity')}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {masterpackQuantities.map((masterpackQuantity, key) => (
                <tr key={key}>
                  <td>
                    <input 
                      id="barcode" 
                      type="text" 
                      name="barcode" 
                      className=" center-align " 
                      value={masterpackQuantity.barcode} 
                      onChange={event => handleInputChange(event, key)}
                      style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
                  </td>
                  <td>
                    <input 
                      id="masterpackQuantity" 
                      type="text" 
                      name="masterpackQuantity" 
                      className=" center-align "
                      value={masterpackQuantity.masterpackQuantity} 
                      onChange={event => handleInputChange(event, key)}
                      style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
                  </td>
                    <td><ButtonFactory name={masterpackQuantity.barcode} icon='delete' onClick={deleteElement}/></td>
                </tr>
            ))}
            <tr>
                <td colSpan={3} style={{ textAlign: "center" }} >
                    <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={addElement} />
                </td>
            </tr>
          </tbody>
        </table>
        <div className="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1, textAlign: 'center', alignSelf: 'center' }}>
            {error !== '' && <div>{error}</div>}
          </div>
          <div style={{ display: 'flex', gap: '20px' }}>
            <ButtonFactory name='Submit' text={t('ok')} onClick={onSubmit} style={{ marginTop:"10px"}}/>
            <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{ marginTop: "10px"}}/>
          </div>
        </div>
       </form>
      </div>
    );
}

EditMasterPackQuantities.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(EditMasterPackQuantities);
