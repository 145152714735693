import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";
import { isLocalPrinterReady, printDatamatrixSerialNumber } from '../../utils/prodlocalhelper';
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import cameraApi from "../../api/cameraApi";
import cameraModelApi from "../../api/cameraModelApi";
import { getProductPageLink} from "../../utils/site";
import { getSimLetter } from "../../utils/simSupplierUtils";
import simSupplierApi from "../../api/simSupplierApi";

const handleFocus = (event) => event.target.select();

class PrintSim extends Component {
  constructor() {
    super();
    this.state = {
      sim: "",
      scanner: "",
      printerStatus: "Loading...",
      printerName: "",
      simSuppliers: [],
      errors: {},
      camId: "",
      serialNumber: "",
      datamatrix: "",
      modelInformation: ""
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

  };

  getSimLetter = sim => {
    return getSimLetter(sim, this.state.simSuppliers);
  }

  loadSimSuppliers = () => {
    simSupplierApi.getSimSuppliers()
      .then(res => {
        this.setState({ simSuppliers: res.data })
      })
      .catch(err =>
        this.setState({ simSuppliers: [] })
      );
  }

  componentDidMount() {
    this.loadSimSuppliers()
    const { t } = this.props;

    isLocalPrinterReady((err, res) => {
      if (err) {
        let errors = { printer: err }
        this.setState({
          printerStatus: "ERROR",
          printerName: t("dashboard_main.local_printer_not_ready"),
          errors: errors
        })
      } else {
        this.setState({
          printerStatus: res.data.status,
          printerName: res.data.name
        })
      }
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
  };



  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {

  };


  handleKeyPress = e => {
    this.setState({ errors: {} });
    if (e.key === 'Enter' && e.target.value !== "") {

      if (e.target.id === "scanner") {
        const imei = e.target.value.split(/,|;/)[1]
        const sn = e.target.value.split(/,|;/)[2]
        this.setState({ serialNumber: sn })
        const datamatrix = `${sn},${getProductPageLink()},`.padEnd(45,'-')

        cameraApi.getCamera(imei)
        .then(res => {
          this.setState({ camId: res.data.id })
          let camID = res.data.id
          let camSims = res.data.sims

          let sim1, sim2
          let indicator = [];
          if (camSims.length === 1) {
            sim1 = camSims[0]
            indicator.push(this.getSimLetter(sim1));
          } else if (camSims.length > 1) {
            sim1 = camSims[0]
            sim2 = camSims[1]
            indicator.push(this.getSimLetter(sim1));
            indicator.push(this.getSimLetter(sim2));
          }

          const newStage = {
            "step": "printColorBox",
            "notes": ""
          }

          const data = {
            serialNumber: sn,
            datamatrix: datamatrix,
            simsIndicator: indicator,
            numberOfCopies: 1
          }

          cameraModelApi.getCameraModel(res.data.cameraModel)
          .then(res => {
            var currentFW = res.data.versions.find(i => i.stage === 'default')
            var modelInformations = `${res.data.masterPackDescription}\nCurrent FW version : ${currentFW.name}\nRelease date : ${new Date(currentFW.date).toLocaleString()}\nGTIN : ${res.data.barcode}`
            this.setState({ modelInformation: modelInformations, errors: { scanner: "" } })
          })
          .catch(err => {
            this.setState({ errors: { scanner: "Error - not able to print" } })
          })
          cameraApi.putCameraFootprint(camID, newStage)
          .then(res => {
            printDatamatrixSerialNumber(data, (err, res) => {
              if (err) {
                let errors = { printer: err.message }
                this.setState({
                  printerStatus: "ERROR",
                  errors: errors
                })
              }
            });
          })
          .catch(err => {
            const errors = err.response
            if (err.response === 404) {
              errors.valid = false;
            }
            this.setState({ errors: errors })
          });
        }) // re-direct to login on successful register
        .catch(err => {
          this.setState({ errors: { scanner: "Error - not able to print" } })
        });
        this.setState({ scanner: "" })
      } else {
        this.setState({ errors: { scanner: "Scan not recognized" } })
        e.target.select();
      }
    }
  };

  render() {
    const { errors } = this.state;
    const { t } = this.props;

    const addLineBreaks = string =>
      string.split('\n').map((text, index) => (
        <React.Fragment key={text + "-" + index}>
          {text}
          <br />
        </React.Fragment>
      ));

    return (
      <div>
        <div style={{ marginTop: "30px" }} className="container">
          <div className="row valign-wrapper">
            <div className="col s12 center-align">
              <Row>
                <Col s={0}>
                  <Link to="/" className="btn-flat waves-effect">
                    <i className="material-icons left" >keyboard_backspace</i> {t('userprofile.back_to_home')}
                  </Link>
                </Col>
                <Col s={12} m={6} >
                  <h4>{t('dashboard_main.print_colorbox_label')}</h4>
                  <h6>{t('dashboard_main.print_colorbox_label_desc')}</h6>
                </Col>
                <Col s={12} m={3}>
                  <i className={classnames("large material-icons", {
                    "green-text": this.state.printerStatus === "ready",
                    "red-text": this.state.printerStatus !== "ready"
                  })}>print</i>
                  <div>{this.state.printerName}</div>
                </Col>
              </Row>
              <form noValidate onSubmit={this.onSubmit}>
                <Row>
                  <div className="input-field col s4">
                    <input
                      onChange={this.onChange}
                      onFocus={handleFocus}
                      onKeyPress={this.handleKeyPress}
                      value={this.state.scanner}
                      error={errors.scanner }
                      id="scanner"
                      type="text"
                      autoFocus
                      className={classnames("", {
                        invalid: errors.scanner
                      })}
                    />
                    <label htmlFor="scanner" className="active">{t('dashboard_main.scanner')}</label>
                    <span id="scannerMessage" className="red-text">
                      {errors.scanner}
                    </span>
                  </div>
                </Row>
                <Row>
                  <Col s={12} m={4} >
                    <div className="infoCenter" id='modelInformation'>
                      {<p>{addLineBreaks(this.state.modelInformation)}</p>}
                    </div>
                  </Col>
                </Row>
              </form>
            </div>

          </div>
        </div>
      </div>
    );
  }
}
PrintSim.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(PrintSim);
