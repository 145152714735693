import React, { useEffect, useState } from "react";
import { Row, Col } from "react-materialize";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import EditReport from "./form/editReport"
import CreateReport from "./form/addReport"
import DeleteReport from "./form/deleteReport"
import LinkUserReport from "./form/linkUserReport"
import ButtonFactory from "../../components/material/ButtonFactory";
import reportingApi from "../../api/reportingApi";
import { t } from "i18next";

function ManageReporting (props) {
  const [reports, setReports] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [showLink, setShowLink] = useState(false)
  const [idClicked, setIdClicked] = useState("");

   useEffect(() => {
    reportingApi.getListReport()
    .then(res => {
      setReports(res.data);
    })
    .catch(err => {
        console.log(err)
    });
  }, [showAdd, showEdit, showDel]);

  function showEditWindow (e) {
    setIdClicked(e.target.id);
    setShowEdit(!showEdit);
    setShowDel(false);
    setShowAdd(false);
    setShowLink(false)
  };

  function showAddWindow() {
    setShowAdd(!showAdd);
    setShowDel(false);
    setShowEdit(false);
    setShowLink(false)
  }

  function showDeleteWindow(e) {
    setIdClicked(e.target.id);
    setShowDel(!showDel);
    setShowEdit(false);
    setShowAdd(false);
    setShowLink(false)
  }

  function showLinkCameraModel(e) {
    setIdClicked(e.target.id)
    setShowLink(!showLink)
    setShowAdd(false)
    setShowDel(false)
    setShowEdit(false)
  }

  function handleAddClose() {
    setShowAdd(false);
  }
  function handleEditClose() {
    setShowEdit(false);
  }
  function handleDeleteClose() {
    setShowDel(false);
  }
  function handleLinkClose() {
    setShowLink(false)
  }

  function makeTable() {
    return reports.map((report, key) =>
    <tr key={report.id}>
      <td>{report.name}</td>
      <td>{report.search}</td>
      <td>{report.model}</td>
      <td>{report.query}</td>
      <td><ButtonFactory name={report.id} icon='edit' onClick={showEditWindow}/></td>
      <td><ButtonFactory name={report.id} icon='delete' onClick={showDeleteWindow}/></td>
      <td><ButtonFactory name={report.id} icon='person' onClick={showLinkCameraModel}/></td>
    </tr>)
  }
    return (
      <div>
        <div>
          <div style={{ marginTop: "30px"}} className="container">
            {showEdit &&
              <EditReport
                reportId={idClicked}
                reports={reports}
                onClose={handleEditClose}
              />
            }
            {showAdd &&
              <CreateReport
                onClose={handleAddClose}
              />
            }
            {showDel &&
              <DeleteReport
                reportId={idClicked}
                reports={reports}
                onClose={handleDeleteClose}
              />
            }
            {showLink &&
              <LinkUserReport
                reportId={idClicked}
                reports={reports}
                onClose={handleLinkClose}
              />
            }
            <Row>
              <Col s={1}>
                <div className="row">
                  	<ButtonFactory href="#/settings" icon='arrow_back' />
                </div>
              </Col>
              <Col s={12} m={2} >
              </Col>
              <Col s={12} m={6} >
                <h4>{t('dashboard_main.manage_reporting_title')}</h4>
              </Col>
            </Row>
            <div>
              <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={showAddWindow}/>
            </div>
            <table style={{ marginTop: "25px" }} >
              <thead>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td>{t('input.name')}</td>
                  <td>{t('setting.search_type')}</td>
                  <td>{t('setting.database_model')}</td>
                  <td>{t('setting.query')}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {makeTable()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );       
}

ManageReporting.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(ManageReporting);
