import React from "react";
import ButtonIcon from './ButtonIcon'

function ButtonIconLarge(props) {
    return (
        <ButtonIcon 
            onClick={props.onClick}
            name={props.name}
            icon={props.icon}
            className="waves-effect waves-light btn-large defaultTheme card-link"
        />
    );
}
export default ButtonIconLarge;