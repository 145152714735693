import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import classnames from "classnames";
import cameraApi from "../../api/cameraApi";
import { getLogTxt, getAllDiskLetter, sdFormat, getLastPhotoDay, getLastPhotoNight } from '../../utils/prodlocalhelper';
import ButtonFactory from "../../components/material/ButtonFactory"
import { Row, Col } from "react-materialize";
import ModalFactory from "../../components/modal/ModalFactory";

const photoType = {
  NIGHT: "night",
  DAY: "day"
}

function SdCard (props) {
    const { t } = props;
    const [sdCardStatus, setSdCardStatus] = useState(false);
    const [imeiCell, setImeiCell] = useState("")
    const [letter, setLetter] = useState("")
    const [allLetter, setAllLetter] = useState([])
    const [lastImage, setLastImage] = useState();
    const [snackBar, setSnackBar] = useState([]);
    const [snackBarError, setSnackBarError] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [simsCell, setSimsCell] = useState([])
    const [simsDB, setSimsDB] = useState([])
    const [imeiDB, setImeiDB] = useState("")

  useEffect(() => {
    getAllDiskLetter(cb)
  }, []);

  useEffect(() => {
    if (letter) {
      getInfoLog()
    }
  }, [ letter ])

  function openModal () {
    setShowModal(true)
  }

  function closeModal () {
    setShowModal(false)
  }

  const openSnackBar = (text, isSuccess) => {
    const newSnackBar = [];
    newSnackBar.push({text: text})
    if (isSuccess) {
      setSnackBar(newSnackBar);
    } else {
      setSnackBarError(newSnackBar);
    }
    setTimeout(() => {
      const emptyArray = [];
      setSnackBar(emptyArray);
      setSnackBarError(emptyArray);
      }, 3000);
  };

  function cbLogFile(data) {
    if (data.data.error) {
      if (data.data.error === "not found") {
        openSnackBar(t('cellnotfound'), false)
      } else {
        openSnackBar(data.data.error, false)
      }
    } else {
      setLastImage()
      setImeiCell(data.data.imei)
      setSimsCell(data.data.sims)
      cameraApi.getCamera(data.data.imei).then(res => {
        setImeiDB(res.data.imei)
        if (res.data.sims.length > 1 ||  data.data.sims.length > 1) {
          if (res.data.sims[0] !== data.data.sims[0]) {
            res.data.sims.reverse()
          }
        }
        
        setSimsDB(res.data.sims)
      })
      .catch (err => {
        setImeiDB("Not found")
      })
    }
  }

  function getInfoLog() {
    getLogTxt(letter, cbLogFile)
  }

  function formatSdCard() {
    sdFormat(letter, cbSdFormat)
  }

  function cbSdFormat(data) {
    console.log(data)
    openSnackBar(t('formatSDSuccess') + " " + letter, true)
    setShowModal(false)
  }

  const selectOnChangeLetter = (e) => {
    setLetter(e.target.value)
  }

  function getAllLetter() {
    var html = [];
    var name = [...allLetter]
    name.forEach(str => {
      html.push( <option value={Object.keys(str)}>{str}</option>)
    })
    return html;
  }
  
  function cb(data, err) {
    if (err) {
      console.log(err)
      return
    }
    setAllLetter(data.data)
    setLetter(data.data[0])
    if (data.data.length > 0) {
      openSnackBar(t('getAllSdCard'), true)
      setSdCardStatus(true)
    } else {
      openSnackBar(t('noSdCardDetected'), true)
      setSdCardStatus(false)
    }
  }

  function showLastPhoto(type) {
    if (type === photoType.DAY) {
      getLastPhotoDay(letter, cbPhoto)
    } else if (type === photoType.NIGHT) {
      getLastPhotoNight(letter, cbPhoto)
    }
  }

  function cbPhoto(data) {
    if (data.data.error === "no image found") {
      openSnackBar(t('noPhotoFound'), false)
    } else {
      openSnackBar(t('getPhotoSuccess'), true)
      setLastImage(data.data)
    }
  }

  function checkImei() {
    if (imeiCell === "" || imeiCell == null) {
      return <p></p>
    }
    if (imeiDB === imeiCell) { 
      return <p className="green-text" style={{fontSize: "20px", textAlign: "center", marginBlockStart: "0px", marginBlockEnd: "0px"}}> &#10004; </p>
    } else {
      return <p className="red-text" style={{fontSize: "20px", textAlign: "center", marginBlockStart: "0px", marginBlockEnd: "0px"}}>&#10060;</p>
    }
  }

  function checkSims0() {
    if (simsCell[0] === "" || simsCell[0] == null) {
      return <p></p>
    }
    if (simsCell[0] === simsDB[0]) { 
      return <p className="green-text" style={{fontSize: "20px", textAlign: "center", marginBlockStart: "0px", marginBlockEnd: "0px"}}> &#10004; </p>
    } else {
      return <p className="red-text" style={{fontSize: "20px", textAlign: "center", marginBlockStart: "0px", marginBlockEnd: "0px"}}>&#10060;</p>
    }
  }

  function checkSims1() {
    if ((simsCell[1] == null && simsDB[1] == null)) {
      return <p></p>
    }
    if (simsCell[1] === simsDB[1]) { 
      return <p className="green-text" style={{fontSize: "20px", textAlign: "center", marginBlockStart: "0px", marginBlockEnd: "0px"}}> &#10004; </p>
    } else {
      return <p className="red-text" style={{fontSize: "20px", textAlign: "center", marginBlockStart: "0px", marginBlockEnd: "0px"}}>&#10060;</p>
    }
  }

  function isMore1Sims() {
    if ((simsDB && simsDB.length > 1) || (simsCell && simsCell.length > 1)) {
      return true
    }
    return false
  }

    return (
        <div style={{ marginTop: "30px",marginLeft: "5%", justifyContent:'center', alignItems:'center', width:'90%'}}>
            <div>
            <Row  className="center-align">
            <Col s={3} m={3} >
                  <ButtonFactory style={{ marginTop: "2rem" }} name="SD Card View" text={t('product_detail_view')} icon='edit' onClick={props.button} />
                </Col>
              <Col m={6} s={6}>
                  <h4>{t('dashboard_main.productDetail')}</h4>
                    <h6>{t('dashboard_main.sdCard_desc')}</h6>
              </Col>
              <Col m={2} s={2}>
              <div className="input-field right" style={{marginTop:"-15px"}}>
                  <i className={classnames("large material-icons", {
                  "green-text": sdCardStatus === true,
                  "red-text": sdCardStatus === false
                  })}>sd_storage</i>
                  <div style={{textAlign: "center"}} >SD Card</div>
              </div>
              </Col>
            </Row>
            <Row>
            <Col m={3} s={3}>
              <Row>
                <Col m={9} s={9}>
                  <select style={{marginLeft:"-10px"}} name="sdCard" id="sdCard" onChange={selectOnChangeLetter}>
                        {getAllLetter()}
                  </select>
                </Col>
                <Col m={3} s={3}>
                  <ButtonFactory style={{marginTop:"10px"}} icon='refresh' onClick={function() { getAllDiskLetter(cb) }}/>
                </Col>
              </Row>
              <Row>
                <ButtonFactory name="Get Card" text={t('get_card')} style={{width:"100%"}} icon='edit' onClick={function() { getInfoLog() }}/>
              </Row>
              <Row>
              <ButtonFactory name="showLastPhoto" text={t('show_last_photo_day')} style={{width:"100%"}} icon='edit' onClick={function() { showLastPhoto(photoType.DAY) }}/>
              </Row>
              <Row>
              <ButtonFactory name="showLastPhoto" text={t('show_last_photo_night')} style={{width:"100%"}} icon='edit' onClick={function() { showLastPhoto(photoType.NIGHT) }}/>
              </Row>
              <Row>
              <ButtonFactory name="Format SD Card" text={t('format_sd_card')} style={{width:"100%", backgroundColor: "red"}} icon='edit' onClick={function() { openModal() }}/>
              </Row>
            </Col>
            <Col m={9} s={9}>
                {
                lastImage &&
                <Row  className="center-align">
                  <Col m={12} s={12}>
                    <img src={'data:image/jpeg;base64,' + lastImage} alt="Last" width="75%" height="75%"/>
                  </Col>
                </Row>
                }
                {
                  !lastImage &&
                  <>
                  <Row  className="center-align">
                  <Col m={2} s={2}>
                    </Col>
                    <Col m={4} s={4}>
                      <p style={{fontWeight: "bold"}}>{t('cameraFromDB')}</p>
                    </Col>
                    <Col m={4} s={4}>
                      <p style={{fontWeight: "bold"}}>{t('cameraFromCell')}</p>
                    </Col>
                  </Row>
                  <Row  className="center-align">
                    <Col m={2} s={2}>
                      <p style={{fontWeight: "bold"}}>IMEI</p>
                    </Col>
                    <Col m={4} s={4}>
                      <p>{imeiDB}</p>
                    </Col>
                    <Col m={4} s={4}>
                      <p>{imeiCell}</p>
                    </Col>
                    <Col m={2} s={2}>
                      { checkImei() }
                    </Col>
                  </Row>
                  <Row  className="center-align">
                    <Col m={2} s={2}>
                      <p style={{fontWeight: "bold"}}>SIMS 1</p>
                    </Col>
                    <Col m={4} s={4}>
                      <p>{simsDB[0]}</p>
                    </Col>
                    <Col m={4} s={4}>
                      <p>{simsCell[0]}</p>
                    </Col>
                    <Col m={2} s={2}>
                      { checkSims0() }
                    </Col>
                  </Row>
                  <Row  className="center-align">
                    {isMore1Sims() &&
                    <Col m={2} s={2}>
                      <p style={{fontWeight: "bold"}}>Sims 2</p>
                    </Col>
                    }
                    {!isMore1Sims() &&
                      <Col m={2} s={2}>
                      </Col>
                    }
                    <Col m={4} s={4}>
                      <p>{simsDB && simsDB.length > 1 && simsDB[1]}</p>
                    </Col>
                    <Col m={4} s={4}>
                      <p>{simsCell && simsCell.length > 1 && simsCell[1]}</p>
                    </Col>
                    <Col m={2} s={2}>
                      { checkSims1() }
                    </Col>
                  </Row>
                  </>
                }
            </Col>
            </Row>
            <ModalFactory modalMode="modalYesNo" buttonSaveText={t('format_sd_card')}  buttonCancelText={t('cancel')}  onClickSave={formatSdCard} onClickCancel={closeModal} show={showModal} title={t('formatSDCardQuestion') + " : " + letter} paragraph={t('formatSDCardExplanation')} />
            </div>
            <div className = {snackBar.length > 0 ? ['snackbar', 'show'].join(" ") : 'snackbar'}>
                { snackBar.length > 0 ? snackBar[0].text : '' }
            </div>
            <div className = {snackBarError.length > 0 ? ['snackbarError', 'show'].join(" ") : 'snackbarError'}>
                { snackBarError.length > 0 ? snackBarError[0].text : '' }
            </div>
        </div>
      );
}

SdCard.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });
  
  export default compose(
    withTranslation(),
    connect(
      mapStateToProps
    )
  )(SdCard);