import React from "react";

function ButtonIcon(props) {
    let className = (props.className) ? props.className : "waves-effect waves-light btn-small defaultTheme card-link"
    return (
        <button 
            type="button" 
            id={props.name}
            className={className}
            onClick={props.onClick}
            style={props.style}>
            <i 
                id={props.name}
                className="large material-icons">{props.icon}
            </i>
        </button>
    );
}
export default ButtonIcon;