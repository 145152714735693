export const ROLES_AVAILABLE = [
    'Student', 
    'Production', 
    'Shipping', 
    'Manager', 
    'Admin', 
    'Return', 
    '3pl',
    'Shipping CM',
    'Production CM',
    'Manager CM',
    'Reporting',
];