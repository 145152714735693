import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import masterpackApi from "../../api/masterpackApi";
import ReactToPrint from "react-to-print";
import MasterPackLabel from "../../components/label/masterpack"


class ViewMasterpack extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      MasterBarcode: "",
      QTY: "",
      camBarcode: "",
      cameraModel: "",
      masterPack: {}
    };

    //this.onSubmit = this.onSubmit.bind(this);
    //this.handleKeyPress = this.handleKeyPress.bind(this);
  };

  UNSAFE_componentWillMount() {
  }

  componentDidMount() {

    masterpackApi.getMasterpack(this.props.match.params.id)
      .then(res => {
        this.setState({ MasterBarcode: res.data.barcode, QTY: res.data.quantityInPack, camBarcode: res.data.cameraModel.barcode, cameraModel: res.data.cameraModel.name, masterPack : res.data})
      })
      .catch(err => {
        const errors = { gerenal: "Masterpack not found" };
        this.setState({ errors: errors })
      });

    this.setState({ sticker: 'none' })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newmasterpack.items) {
      this.setState({
        items: nextProps.newmasterpack.items
      });

    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }



  onChange = e => {

  };

  onSubmit = e => {
    
    e.preventDefault();
  };

  onPrintCam = e => {
    
  }

  render() {
    const { errors, masterPack } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div style={{ marginTop: "30px", minHeight: "75vh" }} className="container">
          <div className="row valign-wrapper">
            <div className="col s12 center-align">

              {/* Page title */}
              <Row style={{ marginBottom: "3rem" }}>
                <Col s={12} m={6} >
                  <h4>{t('dashboard_main.masterPackDetail')}</h4>
                </Col>
              </Row>

              <form noValidate onSubmit={this.onSubmit}>
                <Row>
                  <div className="input-field col s2">
                    <span className="active valign-wrapper">{t('dashboard_main.camera_model')}: {this.state.cameraModel} </span>
                  </div>
                  <div className="input-field col s2">
                    <span className="active valign-wrapper">{t('input.gtin_number')}:  {this.state.camBarcode}  </span>
                  </div>
                  <div className="input-field col s3">
                    <span >{t('input.masterPack_barcode')}: <br /> {this.state.MasterBarcode}</span>
                  </div>
                </Row>
                <Row>
                  <ReactToPrint
                    trigger={() => <button className="btn btn-large waves-effect waves-light hoverable blue accent-3 col m4 offset-m1"
                      style={{
                        width: "250px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        marginTop: "1rem",
                        marginLeft: "2px"
                      }}
                      type="button"
                    >
                      {t('dashboard_main.print')}
                    </button>}
                    content={() => {
                      return this.componentRef
                    }}
                    onAfterPrint={this.handleAfterPrint}
                  />
                </Row>
                <div className="center" ref={el => (this.componentRef = el)}>
                  { masterPack.id ? <MasterPackLabel mp={masterPack} /> : null}
                  
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ViewMasterpack.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(ViewMasterpack);
