import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import classnames from "classnames";
import { fwUpdateFlash, fwUpdateFlashStatus, startLoopFastBoot, stopLoopFastBoot, getStatusFastBootLoop } from '../../utils/prodlocalhelper';
function FirmwareUpdate(props) {
  const { t } = props;

  const [prodlocal, setProdlocal] = useState(false)
  const [requestId, setRequestId] = useState(" ");
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [startTime, setStartTime] = useState(" ");
  const [error, setError] = useState("");
  const [errorFastBoot, setErrorFastBoot] = useState("");
  const [version, setVersion] = useState("");
  const [statusLoop, setStatusLoop] = useState(false);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        onGetfwUpdateFlashStatus();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isLoading]);

  useEffect(() => {
    getStatusLoop()
  }, [statusLoop])

  function onGetfwUpdateFlashStatus(e) {

    fwUpdateFlashStatus(requestId, (err, res) => {
      if (err) {
        setError(err)
      } else {
        setError('')
        setRequestId(res.data.requestId)
        setStartTime(res.data.startTime)
        let newLogs = res.data.log || []
        setLogs(newLogs);
        setIsLoading(newLogs.join('\n').indexOf("Successfuly") === -1)
      }
    });
  }

  function onFWUpdateFlash(e) {
    fwUpdateFlash((err, res) => {
      if (err) {
        setError(err)
      } else {
        setError('')
        setRequestId(res.data.requestId)
        setIsLoading(true);
      }
    });
  }

  function startFastLoop() {
    startLoopFastBoot((err, res) => {
      setErrorFastBoot(res.data.error)
    });
  }

  function stopFastLoop() {
    stopLoopFastBoot((err, res) => {
      setErrorFastBoot(res.data.error)
    });
  }

  function getStatusLoop() {
    getStatusFastBootLoop((err, res) => {
      setVersion(res.data.version)
      setProdlocal(true)
      setStatusLoop(res.data.status)
    });
  }

  function handleSwitch() {
    if (statusLoop == false) {
      startFastLoop()
      setStatusLoop(!statusLoop)
    } else {
      stopFastLoop()
      setStatusLoop(!statusLoop)
    }
  }

  const handleFocus = (event) => event.target.select();

  return (
    <div style={{ marginTop: "30px", marginLeft: "10%", justifyContent: 'center', alignItems: 'center', width: '80%' }}>
      <div className="row center-align">
        <h4>{t('dashboard_main.firmwareUpdate')}</h4>
        <h6>{t('dashboard_main.firmwareUpdate_desc')}</h6>
      </div>
      <div className="row">
        <div className="col m4 s4">
          <button type="button"
            onClick={onFWUpdateFlash}
            className="btn waves-effect waves-light"
            id="getfwUpdateFlashStatus"
            disabled={isLoading}
          >{t('inventory.firmwareUpdate.startUpdateFirmware')}
          </button>
        </div>
        {/* <div className="col m4 s4">
          <h6>Fast Boot Loop : </h6>
          <label>Stopped</label>

          <i onClick={handleSwitch} className={classnames("medium material-icons", {
            "green-text": statusLoop === true,
            "red-text": statusLoop === false
          })}>{statusLoop ? "toggle_on" : "toggle_off"} </i>
          <label>Running</label>
          <br />
          <span className="red-text" id="requestFastBootMessage">
            {errorFastBoot}
          </span>
        </div> */}
        <div className="col m4 s4">
          <i className={classnames("large material-icons", {
            "green-text": prodlocal === true,
            "red-text": prodlocal === false
          })}>check_circle_outline</i>
          {prodlocal && <div>GGServices version : {version}</div>}
          {!prodlocal && <div>GGServices not installed</div>}
        </div>
        <div className="col m6 s6">
          <div className={isLoading ? 'loaderFix' : ''}></div>
        </div>
      </div>
      <div className="row">
        {isLoading}
      </div>
      <div className="row">
        <div className="col s12 m6 input-field left">
          <input
            value={requestId}
            id="requestId"
            type="text"
            readOnly
            className={classnames("", {
              invalid: error
            })}
          />
          <span id="requestIdMessage">
            {error}
          </span>
          <label className="active" htmlFor="requestId">{t('inventory.firmwareUpdate.requestId')}</label>
        </div>
        <div className="col s12 m6 input-field left">
          <input
            value={startTime}
            id="startTime"
            type="text"
            readOnly
          />
          <label className="active" htmlFor="startTime">{t('inventory.firmwareUpdate.startTime')}</label>
        </div>
      </div>
      <div className="row">
        <div className="col m12 s12 input-field left">
          <label className="active">{t('inventory.firmwareUpdate.logs')}</label>
          {logs && logs.map((el, index) => { return <p className="noMargin" key={index}>{el}</p> })}

        </div>
      </div>
    </div>
  );
}

FirmwareUpdate.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(FirmwareUpdate);