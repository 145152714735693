export const getSite = () => {

  const hostname = window.location.host;
  var site = 'spypoint'
  // if the hostname contains one of the expected organizzazione, then we take it
  if (hostname.includes('spypoint')) {
    site = 'spypoint'
  } else if (hostname.includes('vosker')) {
    site = 'vosker'
  }

  return site
}


export const getProductPageLink = () => {
  const site = getSite()

  if (site.toLowerCase() === 'vosker') {
    return 'https://www.vosker.com'
  } else {
    return 'https://www.spypoint.com'
  }
}