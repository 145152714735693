import React, { useEffect, useState } from "react";
import { Row, Col } from "react-materialize";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import EditCameraModel from "./form/editCameraModel";
import AddCameraModel from "./form/addCameraModel";
import DeleteCameraModel from "./form/deleteCameraModel";
import cameraModelApi from "../../api/cameraModelApi";
import ButtonFactory from "../../components/material/ButtonFactory";

function Camera (props) {
  const { t } = props;

  const [cameraModels, setCameraModels] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [idClicked, setIdClicked] = useState("");
  const [editInfo, setEditInfo] = useState();

   useEffect(() => {
    cameraModelApi.getCameraModels()
    .then(res => {
      setCameraModels(res.data);
    })
    .catch(
    );
  }, [showDel, showAdd, showEdit]);

  function showEditWindow (e) {
    setIdClicked(e.target.id);
    cameraModelApi.getCameraModel(e.target.id)
    .then(res => {
      setEditInfo(res.data);
      setShowEdit(!showEdit);
    })
    .catch(err =>
      console.log("error : " + err)
    );
  };

  function showDeleteWindow(e) {
    setIdClicked(e.target.id);
    setShowDel(!showDel);
  }

  function showAddWindow() {
    setShowAdd(!showAdd);
  }

  function handleDelClose() {
    setShowDel(false);
  }
  function handleAddClose() {
    setShowAdd(false);
  }
  function handleEditClose() {
    setShowEdit(false);
  }

  function makeTable() {
    return cameraModels.map((item, key) =>
    <tr key={item.id}>
      <td>{item.name}</td>
      <td><ButtonFactory name={item.name} icon='edit' onClick={showEditWindow}/></td>
      <td><ButtonFactory name={item.name} icon='delete' onClick={showDeleteWindow}/></td>
    </tr>)
  }

    return (
      <div>
        <div>
          <div style={{ marginTop: "30px"}} className="container">
            {showEdit === true &&
              <EditCameraModel
                nameCamera={idClicked}
                model={editInfo}
                cameraModels={cameraModels}
                onClose={handleEditClose}
              />
            }
            {showAdd === true &&
              <AddCameraModel 
                onClose={handleAddClose}
              />
            }
            {showDel === true &&
              <DeleteCameraModel
                delCameraModel={idClicked}
                onClose={handleDelClose}
              />
            }
            <Row>
              <Col s={1}>
                <div className="row">
                  	<ButtonFactory href="#/settings" icon='arrow_back' />
                </div>
              </Col>
              <Col s={12} m={2} >
              </Col>
              <Col s={12} m={6} >
                <h4> {t('setting.camera_maintenance')}</h4>
              </Col>
            </Row>
            <div>
              <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={showAddWindow}/>
            </div>
            <table style={{ marginTop: "25px" }} >
              <thead>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td>{t('setting.camera_name')}</td>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {makeTable()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );       
}

Camera.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(Camera);
