
import { useTranslation } from 'react-i18next';

function CardInfoSettings () {
    const { t } = useTranslation();

    return [
        {
          idCard:"settingUser",
          toLink:`/setting/user`,
          keyCard:"dbms_2",
          textSpan:t('setting.user_maintenance'),
          textAction:t('setting.user_maintenance'),
          text:t('dashboard_main.manage_settings'),
          roles:["Admin"],
          sites:["spypoint", "vosker"]
        },
        {
          idCard:"settingsCamera",
          toLink:`/setting/camera`,
          keyCard:"dbms_3",
          textSpan:t('setting.camera_maintenance'),
          textAction:t('setting.camera_maintenance'),
          text:t('dashboard_main.manage_settings_action'),
          roles:["Admin"],
          sites:["spypoint", "vosker"]
        },
        {
          idCard:"settingsPlant",
          toLink:`/setting/plant`,
          keyCard:"dbms_4",
          textSpan:t('setting.plant_maintenance'),
          textAction:t('setting.plant_maintenance'),
          text:t('setting.plant_maintenance'),
          roles:["Admin"],
          sites:["spypoint", "vosker"]
        },
        {
          idCard:"settingsRegex",
          toLink:`/setting/manageTestQC`,
          keyCard:"dbms_4",
          textSpan:t('dashboard_main.manage_testQC_title'),
          textAction:t('dashboard_main.manage_testQC_title'),
          text:t('dashboard_main.manage_testQC'),
          roles:["Admin"],
          sites:["spypoint", "vosker"]
        },
        {
          idCard:"settingsReporting",
          toLink:`/setting/reporting`,
          keyCard:"dbms_4",
          textSpan:t('dashboard_main.manage_reporting_title'),
          textAction:t('dashboard_main.manage_reporting_title'),
          text:t('dashboard_main.manage_reporting'),
          roles:["Admin"],
          sites:["spypoint", "vosker"]
        },
        {
          idCard:"settingsSims",
          toLink:`/setting/manageSimSuppliers`,
          keyCard:"dbms_4",
          textSpan: t('setting.sim_supplier_maintenance'),
          textAction:t('setting.sim_supplier_maintenance'),
          text:t('dashboard_main.manage_sim_suppliers'),
          roles:["Admin"],
          sites:["spypoint", "vosker"]
        }
      ];
}
export default CardInfoSettings;