import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import {registerValidation} from '../../utils/validation';
import { useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import Button from '../../components/material/Button';

function Register (props) {
  const { t } = props;
  const { register, handleSubmit, errors , watch} = useForm()

  useEffect(() => {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (props.auth.isAuthenticated) {
      props.history.push("/dashboard");
    }
  });

  const onSubmit = formData => {
    const newUser = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      password: formData.password,
      password2: formData.password2
    };
    let invalid = registerValidation(newUser)
    if (invalid.envoyer) {
      props.registerUser(newUser, props.history);
    }
  }

return (
  <div className="container">
    <div className="row">
      <div className="col s8 offset-s2">
        <Link to="/" className="btn-flat waves-effect">
          <i className="material-icons left">keyboard_backspace</i> Back to
          home
        </Link>
        <div className="col s12" style={{ paddingLeft: "11.250px" }}>
          <h4>
            <b>Register</b> below
          </h4>
          <p className="grey-text text-darken-1">
            Already have an account? <Link to="/login">Log in</Link>
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-field col s12">
            <input
              id="firstName"
              name="firstName"
              type="text"
              ref={register({ 
                required: t('input.first_name_required'),
                pattern: {
                value: /^[a-zA-Z\- ]{2,30}$/,
                message: t('input.first_name_invalid')
              }})
            }
            />
            <label htmlFor="firstName">{t('input.first_name')}</label>
            {errors.firstName  && <p className="red-text" >{errors.firstName.message}</p>}
          </div>
          <div className="input-field col s12">
            <input
              name="lastName"
              id="lastName"
              type="text"
              ref={register({ required: t('input.last_name_required'),
              pattern: {
                value: /^[a-zA-Z\- ]{2,30}$/,
                message: t('input.last_name_invalid')
            }})}
            />
            <label htmlFor="lastName">{t('input.last_name')}</label>
            {errors.lastName  && <p className="red-text" >{errors.lastName.message}</p>}
          </div>

          <div className="input-field col s12">
            <input
              name="email"
              error={errors.email}
              id="email"
              type="email"
              ref={register({ required: t('input.email_required'),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: t('input.email_invalid')
              }})}
            />
            <label htmlFor="email">{t('input.email')}</label>
            {errors.email  && <p className="red-text" >{errors.email.message}</p>}
          </div>
          <div className="input-field col s12">
            <input
              name="password"
              id="password"
              type="password"
              ref={register({ required: t('input.password_required'),
                pattern: {
                  value:  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/,
                  message: t('input.password_invalid')
              },
            })}
            />
            <label htmlFor="password">{t('input.password')}</label>
            {errors.password  && <p className="red-text" >{errors.password.message}</p>}
          </div>
          <div className="input-field col s12">
            <input
              name="password2"
              id="password2"
              type="password"
              ref={register({ 
                required: t('input.password_required'),
                 pattern: {
                  value:  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/,
                  message: t('input.password_invalid'),
                },
                validate: value => value === watch('password') || t('input.password_different')
            })}
            />
            <label htmlFor="password2">{t('input.password_confirm')}</label>
            {errors.password2  && <p className="red-text" >{errors.password2.message}</p>}
          </div>
          <div className="col s12" style={{ paddingLeft: "11.250px" }}>
            <Button text="Sign up"></Button>
          </div>
        </form>
      </div>
    </div>
  </div>
);
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    { registerUser }
  )
)(withRouter(Register));
