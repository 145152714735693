import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import classnames from "classnames";
import { Link } from "react-router-dom";
import ButtonFactory from "../../components/material/ButtonFactory"
import { Row, Col } from "react-materialize";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import shippingApi from "../../api/shippingApi";
import { isValidSerialNumber, isValidMasterpackBarcode } from '../../utils/validation';
import ReactTableBuilder from "../../utils/reactTableBuilder";

const handleFocus = (event) => event.target.select();

function ShippingV2 (props) {
    const { t } = props;
    const [invoices, setInvoices] = useState([]);
    const [poNumber, setPoNumber] = useState("")
    const [errors, setErrors] = useState([])
    const [itemsCount, setItemsCount] = useState(0)
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [packageSlip, setPackageSlip] = useState("")
    const [customerName, setCustomerName] = useState("")
    const [soldToAddress, setSoldToAddress] = useState("")
    const [shipToAddress, setShipToAddress] = useState("")
    const [noInvoice, setNoInvoice] = useState("")
    const [scanner, setScanner] = useState("")
    
      const columns = React.useMemo(
        () => [
          {
            Header: 'Serial Number',
            accessor: 'serialNumber',
          },
          {
            Header: 'Masterpack Barcode',
            accessor: 'masterpack',
          },
          {
            Header: 'Model',
            accessor: 'cameraModel',
          },
          {
            Header: 'Delete Item',
            accessor: "delete",
            Cell: ({ row }) => (
              <div style={{ textAlign: "center" }}>
                <ButtonFactory icon='delete'
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals
                    let confirmMessage = confirm(t('message.confirmDeleteCam'));
                    if (confirmMessage) {
                      shippingApi.deleteCamShipment(row.values.serialNumber)
                      .then(res => {
                        getAllCamera(packageSlip);
                      })
                      .catch(err => {
                        setErrors({ scanner: t('error removing items') });
                      });
                    }              
                  }} 
                />
              </div>
            ),
          },
        ],
        [getAllCamera]
      );

      useEffect(()=>{
        setItemsCount(items.length)
      },[items])

      const handleKeyPress = e => {
        setErrors([])
        e.target.value = e.target.value.toUpperCase()

        if (e.key === 'Enter' && e.target.value !== "") {
            if (e.target.value === "GG-ENTER") {
              setScanner("")
            } else if (e.target.value === "GG-CLEAR") {
              setScanner("")
              setItems([])
            }
            else if (isValidSerialNumber(e.target.value.split(/,|;/)[0])) {
                if (!packageSlip) {
                    setErrors({ scanner: t('inventory.shipping.enter_IF_first')} )
                } else {
                    if (items.find(i => i.serialNumber === e.target.value.split(/,|;/)[0])) {
                        setErrors({ scanner: t('inventory.shipping.serialNumberExists', {myVar: `${e.target.value.split(/,|;/)[0]}`} )});
                        setScanner("")
                      } else {
                        getCameraInfoSN(e.target.value.split(/,|;/)[0]);
                      }
                }
            } else if (isValidMasterpackBarcode(e.target.value)) {
                if (!packageSlip) {
                  setErrors({ scanner: t('inventory.shipping.enter_IF_first')} )
                } else {
                    if (items.find(i => i.masterpack === e.target.value)) {
                        setErrors({ scanner: t('inventory.shipping.masterpackExists', {myVar: `${e.target.value}`} )});
                        setScanner("")
                      } else {
                        getCameraInfoMP(e.target.value);
                      }
                }
            } else {
              setIsLoading(true)
              getAllCamera(e.target.value);
              shippingApi.shipment("Netsuite", e.target.value)
              .then(res => {
                  setIsLoading(false)
                  setCustomerName(res.data.customerName)
                  setPackageSlip(res.data.packingSlipNumber)
                  setShipToAddress(res.data.shipToAddress)
                  setSoldToAddress(res.data.soldToAddress)
                  setScanner("")
              })
              .catch(err => {
                setErrors({ scanner: t('packingSlipNotFind') });
                setIsLoading(false)
              });
              e.preventDefault(); // No submit with enter key
            }
          }
       }

       function putCameraShippingInfo(cameraId) {
            const data = {
                customerName: customerName,
                shipToAddress: shipToAddress,
                soldToAddress: soldToAddress,
                shippingDate: new Date(),
                packageSlip: packageSlip,
                items: [{ id: cameraId }]
            };
            shippingApi.buildShipmentV2(data)
            .then(res => {
              setScanner("")
              getAllCamera(packageSlip);
              if (res.status === 200 && res.data === 'OK') {
                  NotificationManager.success(t('message.saveConfirmed'), t('message.successMessage'), 2000);
              }
            })
            .catch(err => {
            const error = err;
            console.info(error);
            // this.setState({ errors: errors })
            NotificationManager.error(t('message.saveNotConfirmed'), t('message.errorMessage'), 5000);
            });
       }

       function getAllCamera (invoiceNumber) {
        shippingApi.cameraByPackingSlip(invoiceNumber).then(response => {
          let cameras = response.data.cams
          let packCamera = [];
    
          cameras.forEach(camera => {
            if (camera.masterPacks.length > 0) {
              packCamera.push({serialNumber: camera.serialNumber, masterpack: camera.masterPacks[0].barcode, cameraModel: camera.cameraModel, id: camera.id });
            } else {
              packCamera.push({serialNumber: camera.serialNumber, masterpack: camera.masterpack, cameraModel: camera.cameraModel, id: camera.id });
            }
          })

          setItems(packCamera)
        }).catch(err => {
          setErrors({ scanner: t('dashboard_main.scanNotRecognized') });
          setIsLoading(false)
        });
      }
       function getCameraInfoSN (sn) {
        putCameraShippingInfo(sn)
      }

      function getCameraInfoMP(mp) {
        putCameraShippingInfo(mp)
      }

      const quitPage = e => {
        //eslint-disable-next-line no-restricted-globals
        if ((items.length !== 0 || packageSlip) && !confirm(t('message.confirmExit'))) {
            e.preventDefault();
            e.returnValue = '';
        }
      }

      function onChange(e) {
        setScanner(e.target.value);
      }

      const handleInputChange = (e) => {       
        if (e.target.checked) {
            setNoInvoice(true)
            setPackageSlip("No_invoice")
        } else {
            setNoInvoice(false)
            setPackageSlip("")
        }
        setCustomerName("")
        setShipToAddress("")
        setSoldToAddress("")
      }

    return (
        <div>
        <div style={{ marginTop: "30px" }} className="container">
          <div className={isLoading ? 'parentDisable' : ''} width="100%">
            <div className={isLoading ? 'loader' : ''}></div>
          </div>
          <div className="row valign-wrapper">
            <div className="col s12 center-align">
                <Row>
                  <Col s={0}>
                    <Link to="/" className="btn-flat waves-effect" onClick={quitPage}>
                      <i className="material-icons left" >keyboard_backspace</i> {t('userprofile.back_to_home')}
                    </Link>
                  </Col>
                  <Col s={12} m={6} >
                      <h4>{t('dashboard_main.shipment')}</h4> 
                      <h6>{t('dashboard_main.shipment_desc_autosave')}</h6>
                  </Col>
                </Row>
                <Row>
          </Row>
                <Row>
                <Col s={12} m={7} >
                    <div className="input-field">
                      <input onChange={onChange} onFocus={handleFocus} onKeyPress={handleKeyPress} value={scanner}  error={errors.scanner} id="scanner" disabled={isLoading} type="text" className={classnames("", { invalid: errors.scanner })}/>
                      <label htmlFor="scanner">{t('dashboard_main.scanner')}</label>
                      <span id="scannerMessage" className="red-text">
                          {errors.scanner}
                      </span>
                    </div>
                  </Col>
                  <Col s={12} m={1}>
                    <label className="active" htmlFor="isActiveCheck"  style={{ width: "90%", display: "inline-block" }} >{t('dashboard_main.noInvoice')}</label>
                    <input type="checkbox" id="isActiveCheck" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible"}} checked={noInvoice} onClick={handleInputChange}/>
                  </Col>
                  </Row>
                  <Row>
                  <Col s={12} m={4} >
                    <label className='left' style={ invoices.length === 0 ? { visibility: 'hidden' } : { display: 'block' } }>{ t('inventory.shipping.invoices') }</label>
                    <div className="input-field" >
                      <select type='select'
                        style={ invoices.length === 0 ? { visibility: 'hidden' } : { display: 'block' } }
                        onChange={onChange}
                        value={poNumber}
                        error={errors.invoices}
                        id="invoices"
                        className={classnames("", {
                        invalid: errors.invoices
                        })} >
                        { invoices.length !== 0 ?
                            // eslint-disable-next-line array-callback-return
                            invoices.map((item, index) => {
                                if (index === 0) {
                                return <option key={index} value={item.poNumber} disabled>{item.customerName}</option>
                                } else {
                                return <option key={index} value={item.poNumber}>{item.customerName}&nbsp;/&nbsp;{item.poNumber}&nbsp;/&nbsp;{item.invoiceDate.slice(0,10)}</option>
                                }
                            })
                          : null
                        }
                      </select>
                      <span id="invoiceMessage" className="red-text">{errors.invoices}</span>
                    </div>
                    <label className='left'>{ t('inventory.shipping.packageSlip') }</label>
                    <label  className="left" style={{ fontWeight: "bold", fontSize: 46, color: "black" }}>{ packageSlip }</label>
                  </Col>
                  <Col s={12} m={4} >
                    <div className="infoCenterShipping" id='billInformation'>
                      {noInvoice ?
                      <div>
                        <label htmlFor="billToName">{ t('inventory.shipping.billToCustomer') }</label>
                        <input type="text" id="customerName" name="billToName" onChange={e => setCustomerName(e.target.value)} value={customerName} />
                        <label htmlFor="billToAddress">{ t('inventory.shipping.billToAddress') }</label>
                        <input type="text" id="soldToAddress" name="billToAddress" onChange={e => setSoldToAddress(e.target.value)} value={soldToAddress} />
                      </div>
                      :
                      <div>
                        <span>{ t('inventory.shipping.billTo') }</span>
                        <p>{customerName}</p>
                        <p>{soldToAddress}</p>
                      </div> }
                    </div>
                  </Col>
                  <Col s={12} m={4} >
                    <div className="infoCenterShipping" id='shipInformation'>
                      {noInvoice ?
                      <div>
                        <label htmlFor="shipToName">{ t('inventory.shipping.shipToCustomer') }</label>
                        <input type="text" id="customerName" name="shipToName" onChange={e => setCustomerName(e.target.value)} value={customerName}/>
                        <label htmlFor="shipToAddress">{ t('inventory.shipping.shipToAddress') }</label>
                        <input type="text" id="shipToAddress" name="shipToAddress" onChange={e => setShipToAddress(e.target.value)} value={shipToAddress}/>
                      </div>
                      :
                      <div>
                        <span>{ t('inventory.shipping.shipTo') }</span>
                        <p>{customerName}</p>
                        <p>{shipToAddress}</p>
                      </div> }
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col s={12} m={1}>
                    <span style={ itemsCount === "" || itemsCount === 0 ? { visibility: 'hidden', fontSize: 50 } : { fontSize: 50 } }>{ itemsCount }</span><br></br>
                    <span style={ itemsCount === "" || itemsCount === 0 ? { visibility: 'hidden', fontSize: 20 } : { fontSize: 20 } }>{ t('inventory.shipping.items') }</span>
                  </Col>
                  <Col s={12} m={11}>
                    <NotificationContainer/>
                  </Col>
                </Row>
            </div>
          </div>
          <Row>
            <ReactTableBuilder columns={columns} data={items} maxRows={24} />
          </Row>
        </div>
      </div>
    )

}

ShippingV2.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });
  
  export default compose(
    withTranslation(),
    connect(
      mapStateToProps
    )
  )(ShippingV2);