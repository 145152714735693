import React, { useEffect, useState } from "react";
import reportingApi from  "../../../api/reportingApi";

function CardInfoReporting  () {
    const [dataArray, setDataArray] = useState([]);
    const arCard = [];

    useEffect(()=>{
      reportingApi.getListReport()
      .then(res => {
        setDataArray(res.data);
      })
      .catch(err=>{
        console.log(err);
      })

    },[])
   
    const test =  dataArray.map((query, index )=>{
       arCard.push( {
        idCard: query.name,
        toLink: query.toLink+'/'+query.id,
        keyCard: 'dbms_3',
        textSpan: query.textSpan,
        textAction: query.textAction,
        text: query.text,
        roles: query.roles,
        sites: query.sites,
        users: query.users
      })
      }
    )
    return arCard;
}
export default CardInfoReporting;