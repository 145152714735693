import React, { Component } from 'react';
import { Row, Col } from "react-materialize";
import Barcode from 'react-barcode';


class MasterPackLabel extends Component {

  render() {
    const { mp } = this.props;
    let formated = mp.cameraModel.barcode;
    if (mp.cameraModel.barcodeFormat === 'ITF14') {
      formated = mp.cameraModel.barcode.replace(/(\d{1})(\d{2})(\d{5})(\d{5})(\d{1})/, '$1 $2 $3 $4 $5');
    } else if (mp.cameraModel.barcodeFormat === 'UPC') {
      formated = mp.cameraModel.barcode.replace(/(\d{1})(\d{5})(\d{5})(\d{1})/, '$1 $2 $3 $4');
    }

    return (
      <div className="productLabel">
        <Row className="labelRowDesc">
          <Col m={12} s={12}>
            VENDAR STK NO.
          </Col>
        </Row>
        <Row className="labelRow">
          <Col m={12} s={12}>
            <span style={{ padding: "20px, 0;", textAlign: 'right' }} className="bigInfo">{mp.cameraModel.commonName}</span>
            <span style={{ fontSize: "16.5px", textAlign: 'left' }} className="smallLabel">{mp.cameraModel.nameSufix}</span>
          </Col>
        </Row>
        <Row className="labelRowDesc">
          <Col m={12} s={12}>
            DESCRIPTION
          </Col>
        </Row>
        <Row className="labelRow">
          <Col m={12} s={12}>
            <span className="mediumInfo">{mp.cameraModel.masterPackDescription}</span>
          </Col>
        </Row>
        <Row className="labelRowBC">
          <Col m={12} s={12} className="center">
            <div>
              <Barcode displayValue={false} fontSize={12} width={3} height={40} margin={0} value={mp.barcode} />
            </div>
            <div className="bcITF14Text">
              {mp.barcode}
            </div>
          </Col>
          <Col m={10} s={10} className="dcITF14Margin"  >
            <div className="bcITF14" >
              <Barcode displayValue={false} format={mp.cameraModel.barcodeFormat} fontSize={12} width={3} height={60} margin={0} value={mp.cameraModel.barcode} />
            </div>
            <div className="bcITF14Text">
              {formated}
            </div>
          </Col>
          <Col m={2} s={2} >
            PACK / UNITS
              <Row>
              <div style={{ marginTop: "-18px" }}>
                <span className="bigInfo"  >{mp.quantityInPack}</span>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
};

export default MasterPackLabel;
