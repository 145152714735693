import { prodapiAxiosClient } from "./prodApiClient";

const getMasterpack = (masterPackID) => {
    return prodapiAxiosClient
    .get(`/api/v1/masterpack/getinfo/` + masterPackID) 
}

const getMasterpackSN = (serialNumber) => {
    return prodapiAxiosClient
    .get(`/api/v1/masterpack/` + serialNumber) 
}

const saveNotesMasterpack = data => {
  return prodapiAxiosClient
  .post("/api/v1/masterpack/saveNotes", data)
}

const newMasterpack = (data) => {
  return prodapiAxiosClient
  .post("/api/v1/masterpack/addItem", data)
}

const bulkInsertMasterpack = (cameraModel, quantity, quantityInPack, barcodeCameraModel) => {
    return prodapiAxiosClient
    .put(`/api/v1/masterpack/bulkinsert/${cameraModel}/${quantity}/${quantityInPack}/${barcodeCameraModel}`)
}

const removeItemFromMasterPack= (masterPackBarcode, cameraSN) => {
    return prodapiAxiosClient
    .post(`/api/v1/masterpack/removeItem`, {serialNumber: cameraSN, barcode: masterPackBarcode})
}
const masterpackApi = {removeItemFromMasterPack, getMasterpack, newMasterpack, bulkInsertMasterpack, getMasterpackSN, saveNotesMasterpack}
export default masterpackApi;