import React, { useState } from "react";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import { Col } from "react-materialize";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
import simSupplierApi from "../../../api/simSupplierApi";

function EditSimSupplier(props)  {
  const { t } = props;
  const [isActive, setIsActive] = useState(props.simSupplier.isActive);

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      name: props.simSupplier.name || "",
      letter: props.simSupplier.letter || "",
      prefix: props.simSupplier.prefix || ""
    }
  });

  function cancelButton(e) {
    props.onClose(e);
  }

  function handleInputChange() {
    setIsActive(!isActive)
  }

  function onSubmit(data) {
    const simSupplierData = {
      name: data.name,
      letter: data.letter,
      prefix: data.prefix,
      isActive: isActive
    }
    simSupplierApi.editSimSupplier(simSupplierData, props.simSupplierId)
      .then(() => {
        props.onClose();
    }).catch((error) => {
        console.log(error)
        alert("Error! ")
    });
  };

  return(
    <div id="editSimSupplier" style={{ maxHeight: "95%", position:"fixed", width:"550px", backgroundColor:"#e6e6e6",borderRadius:"7px", zIndex:"999",overflowY:"scroll" }} className="container center-align"   >
     <Col s={12} >
      <h3 className="center-align defaultTheme white-text" >
        {t('setting.sim_supplier_edit')}
      </h3>
     </Col>
     <form onSubmit={handleSubmit(onSubmit)} name="userForm" id="userForm">
     <Col m={4} s={12}>
        <label className="active" htmlFor="name" style={{ width: "300px", display: "inline-block" }} >{t('input.name')}</label>
        <input type="text" name="name" {...register("name", { required:  t('input.name_required') })} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
        {errors.name && <p className="red-text" >{errors.name.message}</p>}
      </Col>
      <Col m={4} s={12}>
        <label className="active" htmlFor="letter" style={{ width: "300px", display: "inline-block" }} >{t('input.letter')}</label>
        <input type="text" name="letter" {...register('letter', { required: t('input.letter_required') })} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
        {errors.letter && <p className="red-text" >{errors.letter.message}</p>}
      </Col>
      <Col m={4} s={12}>
        <label className="active" htmlFor="prefix" style={{ width: "300px", display: "inline-block" }} >{t('input.prefix')}</label>
        <input type="text" name="prefix" {...register('prefix', {required: t('input.prefix_required'), pattern: {value: /^.{2,30}$/, message: t('input.company_invalid')}})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
        {errors.prefix && <p className="red-text" >{errors.prefix.message}</p>}
      </Col>
      <Col m={4} s={12}>
      <label className="active" htmlFor="isActive"  style={{ width: "300px", display: "inline-block" }} >{t('setting.active')}</label>
        <input type="checkbox" id="isActive" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible"}}  defaultChecked={isActive}  onClick={handleInputChange} />
      </Col>
       <hr/>
       <div className="row " style={{float:"right"}}>
        <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
        <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{marginRight:"15px"}}/>
      </div>
     </form>
    </div>
  );
};

EditSimSupplier.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(EditSimSupplier);
