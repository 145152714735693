import React, { Component } from 'react';
import { Row, Col } from "react-materialize";
import { Link } from "react-router-dom";
import Conditional from "../../../utils/conditional";

class Masterpack  extends Component {

  render() {
    // "assembledDate": "2019-02-19T12:00:00.000Z",
    // "masterPackId": "5c827e3360543b0012295e14",
    // "packagedBy": "5c818d0f9ab3ea0012e97445",
    // "packagedDate": "2019-03-20T04:45:19.256Z",
    // "shipping": null,

    const {masterpack} = this.props;

    return (
      <div>
        <input type="hidden" value={masterpack.id} />
        <Row>
          <Col m={12} s={12}>
            <div>
              <Link to={`/inventory/viewMasterpack/${masterpack.id}`}>
                <h5>Master pack barcode : {masterpack.barcode} </h5>
              </Link>
              <cite style={{color:"black"}}>Camera Model : {masterpack.cameraModel.name}</cite>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
};

export default Masterpack;
