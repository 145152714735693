import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";
import { cameraValidation, regXp, isValidSim } from '../../utils/validation';

import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import cameraApi from "../../api/cameraApi";
import cameraModelApi from "../../api/cameraModelApi";
import ReactTableBuilder from "../../utils/reactTableBuilder";

const handleFocus = (event) => event.target.select();

class NewCamera extends Component {
  constructor() {
    super();
    this.state = {
      cameraModel: "-- Select a camera model --",
      serialNumber: "",
      sim: "",
      assembledDate: "",
      cameraModels: [],
      cameraModelsCount: [],
      scanner: "",
      errors: {},
      cameras: [],
      cameraCount: 0
    };

    if (localStorage.cameraModels) {
      try {
        this.state.cameraModels = JSON.parse(localStorage.cameraModels);
      } catch (e) {
      }

    }

    this.onSubmit = this.onSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  };

  componentDidMount() {

    const { t } = this.props;
    this.setState({
      cameraModel: t("global.selectAModel")
    })

    this.loadCamerasModels();
    this.loadLast10Cameras();

    let cameraModelsCount = [];
    let cameraCountTotal = 0;
    cameraApi.getAllCountCameraModel().then(resp => {
      for (let i = 0; i < resp.data.cams.length; i++) {
        if (!resp.data.cams[i]._id) {
          resp.data.cams[i]._id = "null";
        }
        cameraCountTotal = cameraCountTotal + resp.data.cams[i].count;
        cameraModelsCount.push({ name: resp.data.cams[i]._id, count: resp.data.cams[i].count })
      }
      cameraModelsCount.sort((a, b) => (a.count > b.count) ? -1 : 1);
      this.setState({ cameraModelsCount: cameraModelsCount, cameraCount: cameraCountTotal });
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    this.loadCamerasModels();

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    //const { errors } = this.state;
    const { t } = this.props;
    const newCam = {
      sim: this.state.sim,
      serialNumber: this.state.serialNumber,
      cameraModel: this.state.cameraModel,
      assembledDate: new Date().toJSON()
    };

    const validation = cameraValidation(newCam);

    this.setState({ errors: validation });

    e.preventDefault();

    this.onChange(e);

    // envoyer à l'api seulement si envoyer est true
    if (!validation || validation.valid) {
      cameraApi.newCamera(newCam)
        .then(res => {
          this.setState({ sim: "", serialNumber: "", assembledDate: "", scanner: "" })
          this.loadLast10Cameras();
        })
        .catch(err => {
          const errors = err.response.data

          if (err.response.status === 409) {
            errors.valid = false;
            errors.serialNumber = t(errors.message);
          }

          this.setState({ errors: errors })
        })
    }
  };

  handleKeyPress = e => {
    this.setState({ errors: {} });
    if (e.key === 'Enter' && e.target.value !== "") {
      if (e.target.value === "GG-ENTER") {
        this.setState({ scanner: "" });
        this.onSubmit(e);

      } else if (e.target.value === "GG-CLEAR") {
        this.setState({
          sim: "",
          serialNumber: "",
          scanner: ""
        });
      } else if (/^GG-L.+$/.test(e.target.value)) {
        this.setState({
          cameraModel: e.target.value.replace("GG-", ""),
          scanner: ""
        });
      } else if (isValidSim(e.target.value)) {
        this.setState({
          sim: e.target.value,
          scanner: ""
        });
      } else if (e.target.value.split(' ').length > 1 &&
        isValidSim(e.target.value.split(' ')[0]) &&
        regXp.cameraSerialNumber.test(e.target.value.split(' ')[1])) {
        this.setState({
          sim: e.target.value.split(' ')[0],
          serialNumber: e.target.value.split(' ')[1],
          scanner: ""
        });
      } else if (isValidSim("89445" + e.target.value)) {
        this.setState({
          sim: "89445" + e.target.value,
          scanner: ""
        });
      } else if (isValidSim("89444" + e.target.value)) {
        this.setState({
          sim: "89444" + e.target.value,
          scanner: ""
        });
      } else if (isValidSim("89148" + e.target.value)) {
        this.setState({
          sim: "89148" + e.target.value,
          scanner: ""
        });
      } else if (isValidSim("89122" + e.target.value)) {
        this.setState({
          sim: "89122" + e.target.value,
          scanner: ""
        });
      } else if (isValidSim("89010" + e.target.value)) {
          this.setState({
            sim: "89010" + e.target.value,
            scanner: ""
          });
      } else if (isValidSim("89882" + e.target.value)) {
        this.setState({
          sim: "89882" + e.target.value,
          scanner: ""
        });
      } else if (isValidSim("89302" + e.target.value)) {
        this.setState({
          sim: "89302" + e.target.value,
          scanner: ""
        });
      }else if (isValidSim("89464" + e.target.value)) {
        this.setState({
          sim: "89464" + e.target.value,
          scanner: ""
        });
      }else if (regXp.cameraSerialNumber.test(e.target.value.split(/,|;/)[0])) {
        this.setState({
          serialNumber: e.target.value.split(/,|;/)[0],
          scanner: ""
        });
      } else {
        this.setState({ errors: { scanner: "Scan not recognized" } })
        e.target.select();
      }
    }
  };

  loadLast10Cameras = e => {
    cameraApi.getLastXCamera(10)
      .then(res => {
        this.setState({ cameras: res.data.cameras })
      })
      .catch(err =>
        localStorage.setItem("cameras", JSON.stringify([]))
      );
  };

  createAllCount = () => {
    let dashboard = [];
    var { cameraModelsCount } = this.state;
    for (let y = 0; y < cameraModelsCount.length; y++) {
      if (cameraModelsCount[y].count > 0) {
        dashboard.push(
          <Col md="auto" >
            <span style={{ fontSize: 50 }}>{cameraModelsCount[y].count}</span><br></br>
            <span style={{ fontSize: 20 }}> {cameraModelsCount[y].name} </span>
          </Col>
        )
      }
    }
    return dashboard
  }

  loadCamerasModels = e => {
    const { t } = this.props;
    cameraModelApi.getCameraModels()
      .then(res => {
        let models = res.data;
        localStorage.setItem("cameraModels", JSON.stringify(models));
        models = models.filter(cm => {return !cm.masterPackOnly && cm.active})
        models = [{ "name": t("global.selectAModel") }, ...models]
        models.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        this.setState({ cameraModels: models })
      })
      .catch(err =>
        localStorage.setItem("cameraModels", JSON.stringify([]))
      );
  }

  render() {
    const { errors, cameraModels, cameras, cameraCount } = this.state;
    const { t } = this.props;

    const columns = [
      {
        Header: t('dashboard_main.camera_model'),
        accessor: 'cameraModel',
      },
      {
        Header: t('dashboard_main.serialNumber'),
        accessor: 'serialNumber',
      },
      {
        Header: t('dashboard_main.simCardNumber'),
        accessor: d => d.sims && d.sims.join("\r\n"),
      },
      {
        Header: t('inventory.newproduct.lastStep'),
        accessor: d => {
          if (d.footprints) {
            d.footprints.date = d.footprints.date.substring(0, d.footprints.date.length - 1);
            return `${t(d.footprints.step)}: ${new Date(d.footprints.date).toLocaleString(navigator.language, { hour12: false }).replace(/,/g, '')}`
          } else {
            return ""
          }
        },
      },
    ]
    

    return (
      <div>
        <div style={{ marginTop: "30px" }} className="container">
          <div className="row valign-wrapper">
            <div className="col s12 center-align">
              <form noValidate onSubmit={this.onSubmit}>
                <Row>
                  <div className="input-field col s4">
                    <input
                      onChange={this.onChange}
                      onFocus={handleFocus}
                      onKeyPress={this.handleKeyPress}
                      value={this.state.scanner}
                      error={errors.scanner}
                      id="scanner"
                      type="text"
                      className={classnames("", {
                        invalid: errors.scanner
                      })}
                    />
                    <label htmlFor="scanner">{t('dashboard_main.scanner')}</label>
                    <span id="scannerMessage" className="red-text">
                      {errors.scanner}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div className="input-field col s4">
                    <select type='select'
                      label="Camera models"
                      onChange={this.onChange}
                      value={this.state.cameraModel}
                      error={errors.cameraModel}
                      id="cameraModel"
                      className={classnames("", {
                        invalid: errors.cameraModel
                      })} >
                      {// eslint-disable-next-line array-callback-return
                       cameraModels.map((item, index) => {
                        if (item.active !== false) {
                          if (index === 0) {
                            return <option key={index} value={item.name} disabled>{item.name}</option>
                          } else {
                            return <option key={index} value={item.name} >{item.name}</option>
                          }
                        }
                      })}
                    </select>
                    <span id="cameraModelMessage" className="red-text">
                      {errors.cameraModel}
                    </span>
                  </div>
                  <div className="input-field col s4">
                    <input
                      onChange={this.onChange}
                      onFocus={handleFocus}
                      value={this.state.serialNumber}
                      error={errors.serialNumber}
                      id="serialNumber"
                      type="text"
                      placeholder=""
                      className={classnames("", {
                        invalid: errors.serialNumber
                      })}
                    />
                    <label className="active" htmlFor="serialNumber">{t('dashboard_main.serialNumber')}</label>
                    <span id="serialNumberMessage" className="red-text">
                      {errors.serialNumber}
                    </span>
                  </div>
                  <div className="input-field col s4">
                    <input
                      onChange={this.onChange}
                      onFocus={handleFocus}
                      value={this.state.sim}
                      error={errors.sim}
                      id="sim"
                      type="text"
                      placeholder=""
                      className={classnames("", {
                        invalid: errors.sim
                      })}
                    />
                    <label className="active" htmlFor="sim">{t('dashboard_main.simCardNumber')}</label>
                    <span id="simMessage" className="red-text">
                      {errors.sim}
                    </span>
                  </div>

                </Row>
                <Row>
                  <Col md="auto">
                    <span style={{ fontSize: 50 }}>{cameraCount}</span><br></br>
                    <span style={{ fontSize: 20 }}> {t('dashboard_main.count')}</span>
                  </Col>
                  {this.createAllCount()}
                </Row>
                <div className="col s11" style={{ paddingLeft: "11.250px" }}>
                  <button
                    style={{
                      width: "250px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem"
                    }}
                    type="submit"
                    disabled

                    className="hide btn btn-large waves-effect waves-light hoverable blue accent-3"
                  >
                    {t('dashboard_main.save_camera')}
                  </button>
                </div>
              </form>
            </div>

          </div>
          <Row>
            <ReactTableBuilder columns={columns} data={cameras} maxRows={10} />
          </Row>
        </div>
      </div>
    );
  }
}
NewCamera.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(NewCamera);
