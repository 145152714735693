import React, { useState, useEffect } from "react";
import { Col } from "react-materialize";
import { useForm } from "react-hook-form";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
import userApi from "../../../api/userApi";
import plantApi from "../../../api/plantApi";
import { ROLES_AVAILABLE } from "../../../utils/roleHelper";

function CreateUser(props) {
  const { t } = props;
  const roles = ROLES_AVAILABLE
  const [plants, setPlants] = useState([]);
  const { register, handleSubmit, formState: { errors }, watch } = useForm()

  useEffect(() => {
    plantApi.getPlants()
    .then(res => {
      setPlants(res.data);
    })
    .catch(
    );
  }, []);

  function cancelButton(e) {
    props.onClose(e);
  }

  function onSubmit(data) {
    const userData = {
          name : data.name,
          email: data.email,
          password: data.password,
          company: data.company,
          role: data.userRole,
          isAdmin: data.userRole === "Admin",
          isActive: true,
          plant: data.plant
    };

    userApi.createUser(userData)
      .then( res => {
        props.onClose();
      })
      .catch(err => {
        throw err
    });
  }

    return(
      <div id="addUser" style={{  maxHeight: "95%", position:"fixed", width:"550px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"-95px", marginLeft:" calc(50% - 730px)", zIndex:"999",overflowY:"scroll" }} className="container center-align"   >
       <Col s={12} >
        <h3 className="center-align defaultTheme white-text" >
          {t('setting.user_add')}
        </h3>
       </Col>
       <form onSubmit={handleSubmit(onSubmit)} name="userForm" id="userForm">
       <Col m={4} s={12}>
          <label className="active" htmlFor="name" style={{ width: "300px", display: "inline-block" }} >{t('input.name')}</label>
          <input type="text" name="name" {...register("name", { required:  t('input.name_required'), pattern: {value: /^.{2,30}$/, message: t('input.name_invalid')} })} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.name && <p className="red-text" >{errors.name.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="email" style={{ width: "300px", display: "inline-block" }} >{t('input.email')}</label>
          <input type="text" name="email" {...register('email', {required: t('input.email_required'), pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: t('input.email_invalid')}})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.email && <p className="red-text" >{errors.email.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="password" style={{ width: "300px", display: "inline-block" }} >{t('input.password')}</label>
          <input type="password" name="password"  {...register('password', {required: t('input.password_required'), pattern: {value:  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}/, message: t('input.password_invalid')}})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.password && <p className="red-text" >{errors.password.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="password2" style={{ width: "300px", display: "inline-block" }} >{t('input.password_confirm')}</label>
          <input type="password" name="password2"  {...register('password2', {required: t('input.password_required'), pattern: {value:  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}/, message: t('input.password_invalid')},
                validate: value => value === watch('password') || t('input.password_different')} )} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.password2 && <p className="red-text" >{errors.password2.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="company" style={{ width: "300px", display: "inline-block" }} >{t('input.company')}</label>
          <input type="text" name="company" {...register('company', {required: t('input.company_required'), pattern: {value: /^.{2,30}$/, message: t('input.company_invalid')}})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.company && <p className="red-text" >{errors.company.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="userRole" style={{ width: "300px", display: "inline-block" }} >{t('setting.user_role')}</label>
          <select name="userRole" {...register('userRole')} className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "12.5%", marginRight: "12.5%", marginBottom: "5px", width: "75%" }}>
            {roles.map(role => (
                <option key={role} value={role}>{role}</option>
            ))}
          </select>
          {errors.userRole && <p className="red-text" >{errors.userRole.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="plant" style={{ width: "300px", display: "inline-block" }} >{t('plant.plant')}</label>
          <select name="plant" defaultValue="" {...register('plant', {validate: value => value !== '' || t('plant.plant_required')})} className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "12.5%", marginRight: "12.5%", marginBottom: "5px", width: "75%" }}>
            <option value="" disabled hidden>{t('input.plant_select')}</option>
            {plants.map(plant => (
                <option key={plant.id} value={plant.id}>{plant.name}</option>
            ))}
          </select>
          {errors.plant && <p className="red-text" >{errors.plant.message}</p>}
        </Col>
        
         <hr/>
         <div className="row " style={{float:"right"}}>
          <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
          <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{marginRight:"15px"}}/>
        </div>
       </form>
      </div>
    );
}

CreateUser.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(CreateUser);
