import validator from 'validator/lib/isEmail';
const luhn = require('luhn')


export const regXp = {
    pwd: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/,
    name: /^[a-zA-Z\- ]{2,30}$/,
    sim: /^89\d{17,18}$/,
    imei: /^\d{15}$/,
    cameraSerialNumber: /^(\d{1}[A-Z]{1}\d{2,3}[A-Z]{1}\d{4}|\d{1}[A-Z]{1}\d{7,8})$/,
    masterPackBarcode: /^\d{14}$/
}

export const isValidSim = (sim) => {
    return regXp.sim.test(sim) && luhn.validate(sim)
}

export const isValidSerialNumber = (serialNumber) =>{
    return regXp.cameraSerialNumber.test(serialNumber)
}

export const isValidModemInformation = (scanner) => {
    if (scanner && scanner.split(/,|;/).length > 0) {
        var imei = scanner.split(/,|;/)[0]
        return regXp.imei.test(imei) && luhn.validate(imei)
    }
    return false
}

export const isValidProductionTesting = (scanner) => {
    if (scanner && scanner.split(/,|;/).length > 0) {
        var imei = scanner.split(/,|;/)[1]
        var serialNumber = scanner.split(/,|;/)[2]
        return (regXp.imei.test(imei) && luhn.validate(imei) && isValidSerialNumber(serialNumber) )
    }
    return false
}

export const isValidMasterpackBarcode = (masterPackBarcode) => {
    return regXp.masterPackBarcode.test(masterPackBarcode) && luhn.validate(masterPackBarcode)
}

// Fonction pour faire la validation des données du formulaire de la page register.
export const registerValidation = (newUser) => {
    const invalidField = {
        envoyer: true,
        email: "",
        password: "",
        password2: "",
        firstName: "",
        lastName: ""
    };
    const user = {
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        email: newUser.email,
        password: newUser.password,
        password2: newUser.password2
    };

    if (!regXp.name.test(user.firstName)) {
        invalidField.envoyer = false;
        if (user.firstName === "") {
            invalidField.firstName = "First name is required";
        }
        else {
            invalidField.firstName = "First name is not valid";
        }
    };

    if (!regXp.name.test(user.lastName)) {
        invalidField.envoyer = false;
        if (user.lastName === "") {
            invalidField.lastName = "Last name is required";
        }
        else {
            invalidField.lastName = "Last name is not valid";
        }
    };

    if (!validator(user.email)) {
        invalidField.envoyer = false;
        if (user.email === "") {
            invalidField.email = "Email is required";
        }
        else {
            invalidField.email = "Email is invalid";
        }
    };

    if (!regXp.pwd.test(user.password)) {
        invalidField.envoyer = false;
        if (user.password === "") {
            invalidField.password = "Password is required";
        }
        else {
            invalidField.password = "Password is invalid";
        }

    };

    if (user.password !== user.password2) {
        invalidField.envoyer = false;
        invalidField.password2 = "Both passwords must be identical"
    };

    return invalidField;
}

export const cameraValidation = (newCam) => {
  const invalidField = {
    valid: true,
    sims: "",
    serialNumber: "",
    cameraModel: "",
    imei: ""
  };

  const camera = {
    sims: newCam.sims ? newCam.sims : [ newCam.sim ],
    serialNumber: newCam.serialNumber,
    cameraModel: newCam.cameraModel,
    imei: newCam.imei
  };

  if (camera.cameraModel === "-- Select a camera model --") {
    invalidField.valid = false;
    invalidField.cameraModel = "Camera Model is required"
  }

  var camLength = camera.sims.length
  if (camLength >= 1) {
    for (var index = 0; index < camLength; index++) {
      if (!regXp.sim.test(camera.sims[index]) || !luhn.validate(camera.sims[index])) {
        invalidField.valid = false;
        invalidField.sim = "Sim number is not valid";
      };
    }
    if (camLength > 1) {
      if (camera.sims[0] === camera.sims[1]) {
        invalidField.valid = false;
        invalidField.sim = "Sim number duplicated";
      }
    }
  }

  if (!regXp.cameraSerialNumber.test(camera.serialNumber)) {
    invalidField.valid = false;
    invalidField.serialNumber = "Serial number is not valid";
  };

  if (camera.imei && !regXp.imei.test(camera.imei)) {
    invalidField.valid = false;
    invalidField.imei = "IMEI is not valid";
  };
  return invalidField;
}

export const imeiValidation = (imei) => {
    return regXp.imei.test(imei)
}

export const productImeiValidation = (newProduct, t) => {
    const invalidField = {
        valid: true,
        imei: "",
        cameraModel: ""
    };

    if (newProduct.cameraModel === t("global.selectAModel")) {
        invalidField.valid = false;
        invalidField.cameraModel = t("inventory.newproduct.error.modelExists")
    }

    if (!imeiValidation(newProduct.imei)) {
        invalidField.valid = false;
        if (newProduct.imei === "") {
            invalidField.scanner = t("inventory.newproduct.error.imeiRequired")
        }
        else {
            invalidField.scanner = t("inventory.newproduct.error.imeiNotValid")
        }
    };

    return invalidField;
}
