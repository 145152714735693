import React from "react";
import { Card } from "react-materialize";
import { Link } from "react-router-dom";

const CardComponent = ({idCard, keyCard, textSpan, text, toLink,textAction}) => (
  <Card className='defaultTheme' id={idCard}
    actions={[<Link key={keyCard} to={toLink} className="card-link">{textAction}</Link>]}>
    <span key={keyCard} className="card-title">{textSpan}</span>
    {text}
  </Card>
);
export default CardComponent;