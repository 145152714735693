import React, { useEffect, useState } from "react";
import { Row, Col } from "react-materialize";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import EditTest from "./form/editTest";
import CreateTest from "./form/createTest";
import DeleteTest from "./form/deleteTest";
import LinkCameraModel from "./form/linkCameraModel"
import ButtonFactory from "../../components/material/ButtonFactory";
import productionTestingApi from "../../api/productTestingApi";

function ManageTestQC (props) {
  const { t } = props;

  const [testQc, setTestQc] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [showLink, setShowLink] = useState(false)
  const [idClicked, setIdClicked] = useState("");

   useEffect(() => {
    productionTestingApi.getAllProdutionTesting()
    .then(res => {
      setTestQc(res.data);
    })
    .catch(err => {
        console.log(err)
    });
  }, [showAdd, showEdit, showDel]);

  function showEditWindow (e) {
    setIdClicked(e.target.id);
    setShowEdit(!showEdit);
    setShowDel(false);
    setShowAdd(false);
    setShowLink(false)
  };

  function showAddWindow() {
    setShowAdd(!showAdd);
    setShowDel(false);
    setShowEdit(false);
    setShowLink(false)
  }

  function showDeleteWindow(e) {
    setIdClicked(e.target.id);
    setShowDel(!showDel);
    setShowEdit(false);
    setShowAdd(false);
    setShowLink(false)
  }

  function showLinkCameraModel(e) {
    setIdClicked(e.target.id)
    setShowLink(!showLink)
    setShowAdd(false)
    setShowDel(false)
    setShowEdit(false)
  }

  function handleAddClose() {
    setShowAdd(false);
  }
  function handleEditClose() {
    setShowEdit(false);
  }
  function handleDeleteClose() {
    setShowDel(false);
  }
  function handleLinkClose() {
    setShowLink(false)
  }

  function makeTable() {
    return testQc.map((test, key) =>
    <tr key={test.id}>
      <td>{test.version}</td>
      <td>{test.name}</td>
      <td>{test.regex.length}</td>
      <td>{test.cameraModels.length}</td>
      <td><ButtonFactory name={test.id} icon='edit' onClick={showEditWindow}/></td>
      <td><ButtonFactory name={test.id} icon='delete' onClick={showDeleteWindow}/></td>
      <td><ButtonFactory name={test.id} icon='camera' onClick={showLinkCameraModel}/></td>
    </tr>)
  }
    return (
      <div>
        <div>
          <div style={{ marginTop: "30px"}} className="container">
            {showEdit &&
              <EditTest
                testId={idClicked}
                tests={testQc}
                onClose={handleEditClose}
              />
            }
            {showAdd &&
              <CreateTest
                onClose={handleAddClose}
              />
            }
            {showDel &&
              <DeleteTest
                testId={idClicked}
                tests={testQc}
                onClose={handleDeleteClose}
              />
            }
            {showLink &&
              <LinkCameraModel
                testId={idClicked}
                tests={testQc}
                onClose={handleLinkClose}
              />
            }
            <Row>
              <Col s={1}>
                <div className="row">
                  	<ButtonFactory href="#/settings" icon='arrow_back' />
                </div>
              </Col>
              <Col s={12} m={2} >
              </Col>
              <Col s={12} m={6} >
                <h4> {t('dashboard_main.manage_testQC_title')}</h4>
              </Col>
            </Row>
            <div>
              <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={showAddWindow}/>
            </div>
            <table style={{ marginTop: "25px" }} >
              <thead>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td>{t('setting.version')}</td>
                  <td>{t('setting.testQC_name')}</td>
                  <td>{t('setting.regex_count')}</td>
                  <td>{t('setting.camera_linked_count')}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {makeTable()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );       
}

ManageTestQC.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(ManageTestQC);
