import React from "react";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import { Col } from "react-materialize";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
import userApi from "../../../api/userApi";

function EditPassword(props)  {
  const { t } = props;
  const user = props.users.find(user => user.id === props.userId);
  const { register, handleSubmit, formState: { errors }, watch } = useForm()

  function cancelButton(e) {
    props.onClose(e);
  }

  function onSubmit(data) {
    const userData = {
      password: data.password
    }
    userApi.editUser(userData, props.userId)
      .then(() => {
        props.onClose();
    }).catch((error) => {
        console.log(error)
        alert("Error! ")
    });
  };

  return(
    <div id="addUser" style={{  maxHeight: "95%", position:"fixed", width:"600px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"0px", marginLeft:" calc(10%)", zIndex:"999",overflowY:"scroll" }} className="container center-align"   >
     <Col s={12} >
      <h3 className="center-align defaultTheme white-text" >
        {t('input.password_change')}<br />{user.email}
      </h3>
     </Col>
     <form onSubmit={handleSubmit(onSubmit)} name="userForm" id="userForm">
      <Col m={4} s={12}>
        <label className="active" htmlFor="password" style={{ width: "300px", display: "inline-block" }} >{t('input.password')}</label>
        <input type="password" name="password"  {...register('password', {required: t('input.password_required'), pattern: {value:  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}/, message: t('input.password_invalid')}})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
        {errors.password && <p className="red-text" >{errors.password.message}</p>}
      </Col>
      <Col m={4} s={12}>
        <label className="active" htmlFor="password2" style={{ width: "300px", display: "inline-block" }} >{t('input.password_confirm')}</label>
        <input type="password" name="password2"  {...register('password2', {required: t('input.password_required'), pattern: {value:  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}/, message: t('input.password_invalid')},
              validate: value => value === watch('password') || t('input.password_different')} )} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
        {errors.password2 && <p className="red-text" >{errors.password2.message}</p>}
      </Col>
       <hr/>
       <div className="row " style={{float:"right"}}>
        <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
        <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{marginRight:"15px"}}/>
      </div>
     </form>
    </div>
  );
};

EditPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(EditPassword);
