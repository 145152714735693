import { prodapiAxiosClient } from "./prodApiClient";

const shipment = (depot, invoiceNumber) => {
    return prodapiAxiosClient
    .get(`/api/v1/shipping/packageslip/` + depot + `/` + invoiceNumber)
}

const buildShipment = (depot, invoiceNumber, data) => {
    return prodapiAxiosClient
    .post(`/api/v1/shipping/packageslip/`+ depot + `/` + invoiceNumber, data )
}

const buildShipmentV2 = ( data) => {
    return prodapiAxiosClient
    .post(`/api/v1/shipping`, data )
}

const cameraByPackingSlip = (invoiceNumber) => {
    return prodapiAxiosClient
    .get(`api/v1/shipping/camera/packingSlip/` + invoiceNumber )
}

const deleteCamShipment = (serialNumber) => {
    return prodapiAxiosClient
    .delete(`api/v1/shipping/` + serialNumber )
}

const shippingApi = {shipment, buildShipment, cameraByPackingSlip, buildShipmentV2, deleteCamShipment}
export default shippingApi;
