import React, { Component, useEffect, useState, useRef } from "react";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";


var MenuEnum = {
  NONE:{}, // optionally you can give the object properties and methods
  USERPROFILE:{},
  MOBILE:{}
};
const NONE = 0
const USERPROFILE = 1
const MOBILE = 2

function MyNavbar (props) {
  const { user } = props.auth;
  const { t } = props;

  const [menu, setMenu] = useState(NONE);


  const handleMenuClick = e => {
    e.preventDefault()
    document.addEventListener('click', eventListener);
  }

  const eventListener = e => {
    if (e.target.id == "userProfile") {
      setMenu(USERPROFILE)
    } else if ( e.target.id == "menuMobile") {
      setMenu(MOBILE)
    } else {
      setMenu(NONE)
      document.removeEventListener('click', eventListener);
    }
  }

  /* foncitonne
  
    if (menu === MOBILE && e.target.id == "menuMobile") {
      setMenu(NONE)
      document.removeEventListener('click', eventListener);
    } else if (menu === NONE && e.target.id == "userProfile") {
      setMenu(USERPROFILE)
    } else if (menu === NONE && e.target.id == "menuMobile") {
      setMenu(MOBILE)
    } else if (menu === NONE && e.target.id != "menuMobile") {
      setMenu(NONE)
      document.removeEventListener('click', eventListener);
    }
  */

  const onLogoutClick = e => {
    e.preventDefault();
    props.logoutUser();
  };


    return (
      <div>
        <nav>
          <div className="nav-wrapper ggBackgroundColor ggColor">
            <Link to={`/dashboard`} className="brand-logo"></Link>
            <ul className="hide-on-large-only">
              <li className="dropdown right" id="Mobile"><div id="mobile-link" onClick={handleMenuClick}><i id="menuMobile" className="material-icons md-dark">menu</i></div>
              {menu===MOBILE && (
                <ul>
                  <li><Link to={`/search`}><i className="material-icons md-dark">search</i>{t('search')}</Link></li>
                  <li><Link to={`/dashboard`}><i className="material-icons md-dark">view_module</i>{t('dashboard')}</Link></li>
                  <li><Link to={`#`}><i className="material-icons md-dark">refresh</i></Link></li>
                </ul>
              )}
              </li>
            </ul>
            <ul className="right hide-on-med-and-down">
              <li className="dropdown" id="UserProfile"><Link id="userprofile-link" to='nothing' onClick={handleMenuClick}><i  id="userProfile" className="material-icons md-dark">face</i></Link>
              {menu===USERPROFILE && (
                <ul>
                  <li className="active">{(user && user.firstName)}</li>
                  <li><Link to={`/userprofile`}>{t('editprofile')}</Link></li>
                  <li><div onClick={onLogoutClick}>{t('logout_action')}</div></li>
                </ul>
              )}
              </li>
              <li><Link to={`/search`}><i className="material-icons md-dark">search</i></Link></li>
              <li><Link to={`/dashboard`}><i className="material-icons md-dark">view_module</i></Link></li>
              <li><Link to={`#`}><i className="material-icons md-dark">refresh</i></Link></li>
            </ul>

          </div>

        </nav>

      </div>
    )
}

MyNavbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    { logoutUser }
  )
)(MyNavbar);
