import { prodapiAxiosClient } from "./prodApiClient";

const postProductTesting = (data) => {
    return prodapiAxiosClient
    .post("/api/v1/productionTesting", data)
}

const getAllProdutionTesting = () => {
    return prodapiAxiosClient
    .get("/api/v1/productionTesting/testQC")
}

const createProductionTesting = data => {
    return prodapiAxiosClient
    .post("/api/v1/productionTesting/testQC", data)
}

const deleteProductionTesting = id => {
    return prodapiAxiosClient
    .delete("/api/v1/productionTesting/testQC/" + id)
}

const editProductionTesting = (id, data) => {
    return prodapiAxiosClient
    .put("/api/v1/productionTesting/testQC/" + id, data)
}

const editRegexFromTest = (id, data) => {
    return prodapiAxiosClient
    .put("/api/v1/productionTesting/testQC/regex/" + id, data)
}


const productionTestingApi = { 
    postProductTesting, 
    getAllProdutionTesting, 
    createProductionTesting, 
    deleteProductionTesting, 
    editProductionTesting,
    editRegexFromTest
}
export default productionTestingApi;