import React from "react";
import { Row, Col } from "react-materialize";

function ElementTable(props) {
    return (
        <Row className="center-align">
            <Col s={2} offset="s1" className="right-align">
                <p style={{fontWeight: "bold"}}>{props.text1}</p>
            </Col>
            <Col s={3} className="left-align">
            {props.data && <p>{props.data[props.accessor1]} </p> }
            </Col>
            <Col s={2} className="right-align">
                <p  style={{fontWeight: "bold"}}>{props.text2} </p>
            </Col>
            <Col s={3} className="left-align">
                {props.data && <p>{props.data[props.accessor2]}</p> }
            </Col>
        </Row>
    );
}
export default ElementTable;