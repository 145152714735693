import React, { useState } from "react";
import { Col, Row } from "react-materialize";
import { useForm } from "react-hook-form";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
import productionTestingApi from "../../../api/productTestingApi";

function EditRegex(props) {
  const { t } = props;
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      name: props.regex.name || '',
      description: props.regex.description || '',
      regex: props.regex.regex || '',
      errorMessage: props.regex.errorMessage || '',
      successMessage: props.regex.successMessage || '',
      userDefine1: props.regex.userDefine1 ? props.regex.userDefine1.data || "" : "",
      userDefine2: props.regex.userDefine2 ? props.regex.userDefine2.data || "" : ""
    }
  })
  const [severe, setSevere] = useState(props.regex.severe)
  const [isRegex1, setIsRegex1] = useState(props.regex.userDefine1 ? props.regex.userDefine1.isRegex : false)
  const [isRegex2, setIsRegex2] = useState(props.regex.userDefine2 ? props.regex.userDefine2.isRegex : false)

  function cancelButton() {
    props.onClose();
  }

  function handleSevereChange(e) {
    setSevere(!severe)
  }
  function handleIsRegex1Change(e) {
    setIsRegex1(!isRegex1)
  }
  function handleIsRegex2Change(e) {
    setIsRegex2(!isRegex2)
  }

  function onSubmit(data) {
    const regexData = {
      type: "put",
      index: props.index,
      name : data.name,
      description: data.description,
      regex: data.regex,
      errorMessage: data.errorMessage,
      successMessage: data.successMessage,
      userDefine1: {
        data: data.userDefine1,
        isRegex: isRegex1
      },
      userDefine2: {
        data: data.userDefine2,
        isRegex: isRegex2
      },
      severe: severe
    };

    productionTestingApi.editRegexFromTest(props.test.id, regexData)
      .then( res => {
        const newRegex = {
          name : data.name,
          description: data.description,
          regex: data.regex,
          errorMessage: data.errorMessage,
          successMessage: data.successMessage,
          userDefine1: {
            data: data.userDefine1,
            isRegex: isRegex1
          },
          userDefine2: {
            data: data.userDefine2,
            isRegex: isRegex2
          },
          severe: severe
        }
        props.onClose(props.index, newRegex);
      })
      .catch(err => {
        throw err
    });
  }

    return(
      <div id="addTest" style={{  maxHeight: "95%", position:"fixed", width:"550px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"0px", marginLeft:"10px", zIndex:"999",overflowY:"scroll" }} className="container center-align"   >
       <Col s={12} >
        <h3 className="center-align defaultTheme white-text" >
          {t('setting.regex_edit')}
        </h3>
       </Col>
       <form onSubmit={handleSubmit(onSubmit)} name="userForm" id="userForm">
       <Col m={4} s={12}>
          <label className="active" htmlFor="name" style={{ width: "300px", display: "inline-block" }} >{t('input.name')}</label>
          <input type="text" name="name" {...register("name", { required:  t('input.name_required'), pattern: {value: /^.{2,30}$/, message: t('input.name_invalid')} })} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.name && <p className="red-text" >{errors.name.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="description" style={{ width: "300px", display: "inline-block" }} >{t('setting.description')}</label>
          <input type="text" name="description" {...register('description', {required: t('setting.description_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.description && <p className="red-text" >{errors.description.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="regex" style={{ width: "300px", display: "inline-block" }} >{t('setting.regex')}</label>
          <input type="text" name="regex" {...register('regex', {required: t('setting.regex_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.regex && <p className="red-text" >{errors.regex.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="errorMessage" style={{ width: "300px", display: "inline-block" }} >{t('input.error_message')}</label>
          <input type="text" name="errorMessage" {...register('errorMessage', {required: t('input.error_message_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.errorMessage && <p className="red-text" >{errors.errorMessage.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="successMessage" style={{ width: "300px", display: "inline-block" }} >{t('input.success_message')}</label>
          <input type="text" name="successMessage" {...register('successMessage', {required: t('input.success_message_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.successMessage && <p className="red-text" >{errors.successMessage.message}</p>}
        </Col>
        <Row>
          <Col m={1} s={12} style={{ marginLeft: "40px"}}>
            <label className="active" htmlFor="isRegex1"  style={{ marginLeft: "-5px", display: "inline-block" }} >IsRegex</label>
            <input type="checkbox" id="isRegex1" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible", width: "20px", height: "20px", marginTop: "10px"}}  defaultChecked={isRegex1}  onClick={handleIsRegex1Change} />
          </Col>
          <Col m={8} s={12}>
            <label className="active" htmlFor="userDefine1" style={{ marginLeft: "0px", display: "inline-block" }} >User define 1</label>
            <input type="text" name="userDefine1" {...register('userDefine1')} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "0px", marginRight: "0%", width: "100%" }} />
          </Col>
          <Col m={2} s={12}>
            <label className="active" htmlFor="token1" style={{ marginLeft: "-40px", display: "inline-block" }} >Token</label>
            <div className="left-align" style={{ marginTop: "10px" }}>&#60;*&#62;</div>
          </Col>
        </Row>
        <Row>
          <Col m={1} s={12} style={{ marginLeft: "40px"}}>
            <label className="active" htmlFor="isRegex2"  style={{ marginLeft: "-5px", display: "inline-block" }} >IsRegex</label>
            <input type="checkbox" id="isRegex2" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible", width: "20px", height: "20px", marginTop: "10px"}}  defaultChecked={isRegex2}  onClick={handleIsRegex2Change} />
          </Col>
          <Col m={8} s={12}>
            <label className="active" htmlFor="userDefine2" style={{ marginLeft: "0px", display: "inline-block" }} >User define 2</label>
            <input type="text" name="userDefine2" {...register('userDefine2')} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "0px", marginRight: "0%", width: "100%" }} />
          </Col>
          <Col m={2} s={12}>
            <label className="active" htmlFor="token2" style={{ marginLeft: "-40px", display: "inline-block" }} >Token</label>
            <div className="left-align" style={{ marginTop: "10px" }}>&#60;&&#62;</div>
          </Col>
        </Row>
        <Col m={4} s={12} style={{ marginTop: "-20px"}}>
        <label className="active" htmlFor="severe"  style={{ width: "300px", display: "inline-block" }} >{t('setting.regex_severe')}</label>
          <input type="checkbox" id="severe" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible", width: "20px", height: "20px", marginTop: "10px"}}  defaultChecked={severe}  onClick={handleSevereChange} />
        </Col>
         <hr/>
         <div className="row " style={{float:"right"}}>
          <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
          <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{marginRight:"15px"}}/>
        </div>
       </form>
      </div>
    );
}

EditRegex.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(EditRegex);
