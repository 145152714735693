import React from "react";

const rolesByPass = ["Manager", "Admin"]



const acceptSims = (newSim, acceptedSims, userRole, callback) => {
  var goodSim = false
  acceptedSims.forEach(acceptedSim => {
    if (newSim.startsWith(acceptedSim)) {
      goodSim = true
    }
  })
  if (!goodSim) {
    if (rolesByPass.indexOf(userRole) > -1) {
      return callback()
    } else {
      return false
    }
  }
  return true
};
export default acceptSims;