import React, { useState } from "react";
import { Col } from "react-materialize";
import { useForm } from "react-hook-form";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
import productionTestingApi from "../../../api/productTestingApi";
import DeleteRegex from "./deleteRegex";
import AddRegex from "./addRegex";
import EditRegex from "./editRegex";


function CreateUser(props) {
  const { t } = props;
  let test = props.tests.find(test => test.id === props.testId);
  const tokens = [
    ["<!>", "Model"],
    ["<@>", "Serial Number"],
    ["<#>", "SIM"],
    ["<%>", "IMEI"],
    ["<^>", "Firmware version"]
  ]
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
        name: test.name || '',
        version: test.version || ''
    }
  })
  
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [keyClicked, setKeyClicked] = useState("");

  function cancelButton(e) {
    props.onClose(e);
  }

  function showEditWindow (e) {
    setKeyClicked(e.target.id);
    setShowEdit(!showEdit);
    setShowDel(false);
    setShowAdd(false);
  };

  function showAddWindow() {
    setShowAdd(!showAdd);
    setShowDel(false);
    setShowEdit(false);
  }

  function showDeleteWindow(e) {
    setKeyClicked(e.target.id);
    setShowDel(!showDel);
    setShowEdit(false);
    setShowAdd(false);
  }

  function handleAddClose(regex) {
    if (regex) {
      test.regex.push(regex)
    }
    setShowAdd(false);
  }

  function handleEditClose(index, regex) {
    if (regex) {
      test.regex.splice(index, 1, regex)
    }
    setShowEdit(false);
  }

  function handleDeleteClose(index) {
    if (index) {
      test.regex.splice(index, 1)
    }
    setShowDel(false);
  }

  function onSubmit(data) {
    const testData = {
      name : data.name,
      version: data.version,
      regex: test.regex
    };
    productionTestingApi.editProductionTesting(test.id, testData)
      .then( res => {
        props.onClose();
      })
      .catch(err => {
        throw err
    });
  }
    function regexTokenMessage() {
      return tokens.map((token, key) => 
        <tr key={key}>
          <td>{token[0]}</td>
          <td>{token[1]}</td>
        </tr>
      )
    }

    function showHelpMessage() {
      const helpMessage = document.getElementById("tokenMessage")
      if (helpMessage.style.display === "block") {
        helpMessage.style.display = "none"
      } else {
        helpMessage.style.display = "block"
      }
    }

    function makeTable() {
        return test.regex.map((regex, key) =>
        <tr key={key}>
          <td><i id={key} className="material-icons">{regex.severe ? "radio_button_checked" : "radio_button_unchecked"}</i></td>
          <td>{regex.name}</td>
          <td>{regex.description}</td>
          <td>{regex.errorMessage}</td>
          <td>{regex.successMessage}</td>
          <td>{regex.regex}</td>
          <td><ButtonFactory name={key} icon='edit' onClick={showEditWindow}/></td>
          <td><ButtonFactory name={key} icon='delete' onClick={showDeleteWindow}/></td>
        </tr>)
    }

    return(
      <div id="editTest" style={{  maxHeight: "95%", position:"fixed", width:"1200px", backgroundColor:"#e6e6e6", borderRadius:"7px", paddingTop:"0px", marginTop:"-95px", marginLeft:"0px", zIndex:"100",overflowY:"scroll" }} className="container center-align">
            {showEdit &&
              <EditRegex
                test={test}
                index={keyClicked}
                regex={test.regex[keyClicked]}
                onClose={handleEditClose}
              />
            }
            {showAdd &&
              <AddRegex
                test={test}
                onClose={handleAddClose}
              />
            }
            {showDel &&
              <DeleteRegex
                index={keyClicked}
                test={test}
                onClose={handleDeleteClose}
              />
            }
        <div id="tokenMessage" style={{ maxHeight: "95%", position:"fixed", width:"350px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"320px", marginLeft:"600px", zIndex:"999", border: "2px solid red", padding: "10px" }} className="container center-align">
          <table style={{ paddingLeft: "20px" }}>
            <thead>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td>Token</td>
                <td>Database Value</td>
              </tr>
            </thead>
            <tbody>
              {regexTokenMessage()}
            </tbody>
          </table>
        </div>
        <Col s={12} >
        <h3 className="center-align defaultTheme white-text" >
          {t('setting.testQC_edit')}
        </h3>
       </Col>
       <form onSubmit={handleSubmit(onSubmit)} name="userForm" id="userForm">
       <Col m={4} s={12}>
          <label className="active" htmlFor="name" style={{ width: "300px", display: "inline-block" }} >{t('input.name')}</label>
          <input type="text" name="name" {...register("name", { required:  t('input.name_required'), pattern: {value: /^.{2,30}$/, message: t('input.name_invalid')} })} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.name && <p className="red-text" >{errors.name.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="version" style={{ width: "300px", display: "inline-block" }} >{t('setting.version')}</label>
          <input type="text" name="version" {...register('version', {required: t('setting.version_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.version && <p className="red-text" >{errors.version.message}</p>}
        </Col>
        <table style={{ marginTop: "25px" }} >
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2" }}>
              <td>{t('setting.regex_severe')}</td>
              <td>{t('setting.regex_name')}</td>
              <td>{t('setting.regex_description')}</td>
              <td>{t('input.error_message')}</td>
              <td>{t('input.success_message')}</td>
              <td>{t('setting.regex')}<i id="tokenIcon" className="material-icons left" onMouseOver={showHelpMessage} onMouseOut={showHelpMessage}>help</i></td>
              <td></td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {makeTable()}
          </tbody>
        </table>
        <div>
            <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={showAddWindow}/>
        </div>
        <hr/>
         <div className="row " style={{float:"right"}}>
          <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
          <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{marginRight:"15px"}}/>
        </div>
       </form>
      </div>
    );
}

CreateUser.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(CreateUser);
