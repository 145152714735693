import React from "react";


export default function DatePickerFactory(props) {
  return (
    <div>
        <label htmlFor="date">{props.label}</label>
        <input type="date" id="date" value={props.value.format('YYYY-MM-DD')} onChange={props.onChange} />
    </div>
  );
}