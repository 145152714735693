import React from "react";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import plantApi from "../../../api/plantApi";
import ButtonFactory from "../../../components/material/ButtonFactory";

function DeletePlant (props) {
  const { t } = props

  function cancelButton (e){
    props.onClose(e);
  }

  function onSubmit() {
    const data = {
      "name":  props.plant.name,
      "country": props.plant.country,
      "city": props.plant.city,
      "serialNumber": props.plant.serialNumber,
      "isActive": false
    }

    plantApi.softDeletePlant(props.Plant_id, data)
    .then(res => {
      props.onClose();
    }) 
    .catch(err => {
      const errors = err.response ? err.response.data : err;
      console.log(errors)
    });
  }

  return(
      <div >
        <div id="editPlant" style={{  position:"fixed", width:"450px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"calc(25% - 300px)", marginLeft:" calc(50% - 630px)" }} className="container center-align">
            <h3 className="center-align defaultTheme white-text" id="is a test "  style={{maringTop:"50px"}} >
                {t('input.delete_confirmation')}
            </h3>
            <p>
                {t("message.sure_delete_plant") +" " +  props.plant.name}
            </p>
              <div className="row " style={{float:"right"}}>
                    <ButtonFactory name='Submit' text={t('ok')} onClick={onSubmit} style={{marginRight:"35px"}}/>
                    <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{marginRight:"15px"}}/>
              </div>
          </div>
      </div>
    );
}

DeletePlant.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });
  
  export default compose(
    withTranslation(),
    connect(
      mapStateToProps,
    )
  )(DeletePlant);