import React from "react";
import ConfirmationModal from "./modalWindow/confirmationModal";

function ModalFactory(props) {
    if (props.modalMode === "modalYesNo") {
      return (
        <ConfirmationModal
          open={props.show}
          title={props.title}
          children={props.paragraph}
          button1Title={props.buttonSaveText}
          onConfirm={props.onClickSave}
          button2Title={props.buttonCancelText}
          onCancel={props.onClickCancel}
        />
      );
    }
    if (props.modalMode === "modalOk") {
      return (
        <ConfirmationModal
          open={props.show}
          title={props.title}
          children={props.paragraph}
          button1Title={props.buttonSaveText}
          onConfirm={props.onClickSave}
        />
      );
    }
}
export default ModalFactory;