import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";
import { imeiValidation, isValidSerialNumber, isValidSim } from '../../utils/validation';

import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import cameraApi from "../../api/cameraApi";
import { t } from "i18next";
import ReactTableBuilder from "../../utils/reactTableBuilder";


const handleFocus = (event) => event.target.select();

class productStage extends Component {
  constructor() {
    super();

    this.state = {
      camera: productStage.getEmptyCamera(),
      cameraStage: "",
      camTable: [],
      manufacturer: "",
      notes: "",
      repairOperation: [],
      repairStages: [],
      scanner: "",
      // sims: [],
      stages: [],
      errors: {},
      sortingOperation: [],
      sortingOperationStages: [],
      subMenuReturn: "none",
      subMenuReturnList: [],
      placeHolder: ""
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    if (window.performance) {
      if (performance.navigation.type === 1) {
        this.refresh = true;
      } else {
        this.refresh = false;
      }
    }

  };

  static getEmptyCamera() {
    return {
      cameraModel : "",
      imei : "",
      serialNumber : "",
      sims: [],
      firmwareVersion : { name : ""}
    }
  }

  componentDidMount() {
    const { t } = this.props;
    this.setState({
      cameraStage: 'none',
      repairOperation: t('global.selectRepair')
    })

    this.loadProductStage()
    this.loadRepairEndReport()
    this.loadSortingOperationStages()
    this.loadSubMenuReturn()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChangeSubListReturn = e => {
    const { t } = this.props;
    var states = {
      [e.target.id]: e.target.value
    }
    if (e.target.value === "returnFormProd")  {
      states.placeHolder = t('pleaseDescribeReturn') //Please describe the cause for return
    } else if (e.target.value === "rma") {
      states.placeHolder = "rma01234567"
      states.notes = "rma"
    } else if (e.target.value === "credit") {
      states.placeHolder = "rma0123-cr01234567"
    } else if (e.target.value === "returnFromExodusQC") {
      states.placeHolder = "Exodus QC - "
      states.notes = "Exodus QC - "
    }
    else {
      states.placeHolder = "012-0123456-0123456"
    }
    this.setState(states);
  };

  onChange = e => {
    var states = {
      [e.target.id]: e.target.value
    }
    // if (e.target.id === 'something') {
    //   var modelInformation = 'something'
    //   states = {...states, modelInformation: modelInformation}
    // }
    this.setState(states);
  };

  onChangeSpecialNotes = e => {
    const { t } = this.props;
    var states = {
      [e.target.id]: e.target.value
    }
    if (this.state.subMenuReturn === "rma") {
      states.notes = "rma" + e.target.value.substring(3, e.target.value.length)
    }
    if (this.state.subMenuReturn === "amazonReturn") {
      var newNotes = e.target.value.split('');
      if (newNotes.length === 3 && this.state.notes.length !== 4 ) { // Look if is not tryig to erase the -
        newNotes.splice(3,1,"-")
      }
      if (newNotes.length === 11 && this.state.notes.length !== 12) { // Look if is not tryig to erase the -
        newNotes.splice(11,1,"-")
      }
      e.target.value = newNotes.join('')
      states.notes = e.target.value
    }

    if (this.disabledSendButton(e.target.value)) {
      states.errors = {notes: t("invalidNotes") + " Ex: " + this.state.placeHolder}
    } else {
      states.errors = {notes: ""}
    }

    this.setState(states);
  }

  onSubmit = e => {
    const { t } = this.props;

    if (this.state.camera.id) {

      const validation = {
        valid: true
      }

      if (this.state.cameraStage === "none") {
        validation.valid = false
        validation.cameraStage = t('inventory.productStage.stageNotSelected')

        this.setState({ errors: validation })

      } else {
        e.preventDefault();

        this.onChange(e);
        // envoyer à l'api seulement si envoyer est true
        if (!validation || validation.valid) {
          const newStage = {
            "step": this.state.cameraStage,
            "notes": this.state.notes
          }
          cameraApi.putCameraFootprint(this.state.camera.id, newStage)
            .then(res => {
              this.setState({ camera: productStage.getEmptyCamera(), notes: "", scanner: "", manufacturer: "" , camTable: [], cameraStage: 'none', repairOperation: t('global.selectRepair') })
            })
            .catch(err => {
              const errors = err.response.data
              if (err.response.status === 404) {
                errors.valid = false;
                errors.scanner = t(err.response.message)
              }

              this.setState({ errors: errors })
            });
        }
      }
    }
  };

  handleKeyPress = e => {
    const { t } = this.props;
    this.setState({ errors: {} });

    if (e.key === 'Enter' && e.target.value !== "") {
      let scannerImei, scannerSerialNumber = null
      if (e.target.value.indexOf(';') >= 0 || e.target.value.indexOf(',') >= 0) {
        if (e.target.value.split(/,|;/).length === 4) {
          scannerImei = e.target.value.split(/,|;/)[1]
          scannerSerialNumber = e.target.value.split(/,|;/)[2]
        } else {
          scannerSerialNumber = e.target.value.split(/,|;/)[0]
        }
        if (scannerImei && imeiValidation(scannerImei)) {
          this.getCameraInfo(e, scannerImei)
          this.setState({ scanner: "" });
        } else if (scannerSerialNumber && isValidSerialNumber(scannerSerialNumber)) {
          this.getCameraInfo(e, scannerSerialNumber)
          this.setState({ scanner: "" });
        }
        e.preventDefault(); // No submit with enter key
      } else if (e.target.value === "GG-ENTER") {
        this.setState({ scanner: "", cameraStage: 'none', repairOperation: t('global.selectRepair') });
        this.onSubmit(e);
      } else if (e.target.value === "GG-CLEAR") {
        this.setState({
          camera: productStage.getEmptyCamera(),
          camTable: [],
          cameraStage: 'none',
          manufacturer: "",
          notes: "",
          repairOperation: t('global.selectRepair'),
          scanner: "",
          step: ""
        });
      } else if (imeiValidation(e.target.value)) {
        this.getCameraInfo(e, e.target.value)
        this.setState({ scanner: "", notes: "", cameraStage: 'none', repairOperation: t('global.selectRepair') });
        e.preventDefault(); // No submit with enter key
      } else if (isValidSerialNumber(e.target.value)) {
        this.getCameraInfo(e, e.target.value)
        this.setState({ scanner: "", notes: "", cameraStage: 'none', repairOperation: t('global.selectRepair')  });
        e.preventDefault(); // No submit with enter key
      } else if (isValidSim(e.target.value)) {
        this.getCameraInfo(e, e.target.value)
        this.setState({ scanner: "", notes: "", cameraStage: 'none', repairOperation: t('global.selectRepair') });
        e.preventDefault(); // No submit with enter key
      } else if (e.target.value.replace(/-/g, "").match(/^[A-Z2-7]{11}$/)) {
        this.getCameraInfo(e, e.target.value.replace(/-/g, ""))
        this.setState({ scanner: "", notes: "", cameraStage: 'none', repairOperation: t('global.selectRepair') });
        e.preventDefault(); // No submit with enter key
      } else {
        this.setState({ errors: { scanner: "Scan not recognized" } })
        e.target.select();
        e.preventDefault(); // No submit with enter key
      }
    }
  };

  getCameraInfo = (e, scan) => {
    cameraApi.getCamera(scan)
      .then(res => {
        this.setState({ camera: res.data })
        // this.setState({ sims: res.data.sims })
        this.setState({camTable: res.data.footprints})
        let camera = res.data.footprints;
        let manufacturer = camera.find(i => i.step === 'creation')
        manufacturer = manufacturer.instigator.name
        this.setState({ manufacturer: manufacturer })
      })
      .catch(err => { }
      );
  };

  loadSubMenuReturn = e => {
    const { t } = this.props
    const { user } = this.props.auth;

    const subMenuReturnList = [
      {
        'id': 'none',
        'name': t('returnSubMenu'),
        'auth': ["Admin", "Production", "Return", "Manager"]
      },
      {
        'id': 'returnFormProd',
        'name': t('returnFromProduction'),
        'auth': ["Admin", "Production", "Return", "Manager"]
      },
      {
        'id': 'rma',
        'name': t('rma'),
        'auth': ["Admin", "Production", "Return", "Manager"]
      },
      {
        'id': 'credit',
        'name': t('credit'),
        'auth': ["Admin", "Production", "Return", "Manager"]
      },
      {
        'id': 'amazonReturn',
        'name': t('amazonReturn'),
        'auth': ["Admin", "Production", "Return", "Manager"]
      },
      {
        'id': 'returnFromExodusQC',
        'name': t('returnFromExodusQC'),
        'auth': ["Admin", "Production", "Return", "Manager"]
      },
    ]

    const newSubMenuList = subMenuReturnList.filter(i => i.auth.indexOf(user.role) !== -1)
    this.setState({ subMenuReturnList: newSubMenuList });
  }

  loadProductStage = e => {
    const { t } = this.props
    const { user } = this.props.auth;
    const stages = [{
        'id': 'none',
        'name': t('global.selectAStage'),
        'auth': ["Student", "Admin", "Production", "Return", "Manager"]
      },
      {
        'id': 'endLine1',
        'name': t('product_stage_endLine1'),
        'auth': ["Admin", "Production", "Manager"]
      },
      {
        'id': 'return',
        'name': t('product_stage_return'),
        'auth': ["Admin", "Production", "Return", "Manager"]
      },
      {
        'id': 'repairStart',
        'name': t('product_stage_repairStart'),
        'auth': ["Admin", "Production", "Manager"]
      },
      {
        'id': 'repairEnd',
        'name': t('product_stage_repairEnd'),
        'auth': ["Admin", "Production", "Manager"]
      },
      {
        'id': 'aesthetics',
        'name': t('product_stage_aesthetics'),
        'auth': ["Admin", "Production", "Manager"]
      },
      {
        'id': 'refurbished',
        'name': t('product_stage_refurbished'),
        'auth': ["Student","Admin", "Production", "Return", "Manager"]
      },
      {
        'id': 'sorting',
        'name': t('product_stage_sorting'),
        'auth': ["Admin", "Production", "Return", "Manager"]
      },
      {
        'id': 'deleted',
        'name': t('product_stage_deleted'),
        'auth': ["Admin", "Production", "Return", "Manager"]
      }
      ];
     
    const newStages = stages.filter(i => i.auth.indexOf(user.role) !== -1)

    this.setState({ stages: newStages });

  };

  loadSortingOperationStages = e => {
    const { t } = this.props
    const operation = [{
      'id': 'none',
      'type': '0',
      'name': t('global.selectSorting')
    },
    {
      'id': 'rep',
      'type': '1',
      'name': t('global.repair')
    },
    {
      'id': 'aes',
      'type': '1',
      'name': t('global.aesthetic')
    },
    {
      'id': 'pro',
      'type': '1',
      'name': t('global.production')
    },
    {
      'id': 'ref',
      'type': '1',
      'name': t('global.refurbished')
    }
  ]
    this.setState({ sortingOperationStages: operation });
  }

  loadRepairEndReport = e => {
    const { t } = this.props
    const operation = [{
        'id': 'none',
        'type': '0',
        'name': t('global.selectRepair')
      },
      {
        'id': 'dsp',
        'type': '1',
        'name': t('product_stage_dspRepair')
      },
      {
        'id': 'mcu',
        'type': '1',
        'name': t('product_stage_mcuRepair')
      },
      {
        'id': 'sdr',
        'type': '1',
        'name': t('product_stage_sdrRepair')
      },
      {
        'id': 'sim',
        'type': '1',
        'name': t('product_stage_simRepair')
      },
      {
        'id': 'repr',
        'type': '1',
        'name': t('product_stage_reprRepair')
      },
      {
        'id': 'tran',
        'type': '1',
        'name': t('product_stage_transRepair')
      },
      {
        'id': 'mosf',
        'type': '1',
        'name': t('product_stage_mosfRepair')
      },
      {
        'id': 'regu',
        'type': '1',
        'name': t('product_stage_regulRepair')
      },
      {
        'id': 'irfl',
        'type': '1',
        'name': t('product_stage_irflRepair')
      },
      {
        'id': 'ante',
        'type': '1',
        'name': t('product_stage_anteRepair')
      },
      {
        'id': 'modem',
        'type': '1',
        'name': t('product_stage_modemRepair')
      },
      {
        'id': 'screen',
        'type': '1',
        'name': t('product_stage_screenRepair')
      },
      {
        'id': 'cmos',
        'type': '1',
        'name': t('product_stage_cmosRepair')
      },
      {
        'id': 'modRep',
        'type': '1',
        'name': t('product_stage_modemReplaced')
      },
      {
        'id': 'supCon',
        'type': '1',
        'name': t('product_stage_supplyConnex')
      },
      {
        'id': 'updCard',
        'type': '1',
        'name': t('product_stage_updateSDCard')
      },
      {
        'id': 'simRep',
        'type': '1',
        'name': t('product_stage_simReplaced')
      },
      {
        'id': 'brdRep',
        'type': '1',
        'name': t('product_stage_mainBoardReplaced')
      }, 
      {
        'id': 'refurbished',
        'type': '0',
        'name': t('product_stage_repairEnd_refurbished')
      }, 
    ];
    this.setState({ repairStages: operation });
  }

  getCurrentStage = (footprints) => {
    if (footprints) {
      return footprints.sort(function (a, b) { return new Date(b.date) - new Date(a.date) })[0].step
    }

    return ""
  }

  handleChange = e => {
    let value = Array.from(e.target.selectedOptions, option => option.value);
    this.setState({ notes: this.state.notes + ' ' + value + ' ' });
  }

  showList = () => {
    if (this.state.cameraStage === "repairEnd" || this.state.cameraStage === "aesthetics") {
      return true
    }
    return false
  }

  showListSorting = () => {
    if (this.state.cameraStage === "sorting") {
      return true
    }
    return false
  }

  getType = () => {
    var type = null
    if (this.state.cameraStage === "repairEnd") {
      type = 1
    }
    if (this.state.cameraStage === "aesthetics") {
      type = 2
    }
    return type
  }

  disabledSendButton = (data) => {
    var returnValue = false;
    if (!data) {
      data = this.state.notes
    }
    if (this.state.cameraStage === "return") {
      if (this.state.subMenuReturn === "returnFormProd" && data === "") {
        returnValue = true
      }
      if (this.state.subMenuReturn === "rma" && new RegExp(/\brma\d{8}\b/).exec(data) == null) {
        returnValue = true
      }
      if (this.state.subMenuReturn === "credit" && new RegExp(/\brma\d{4}-cr\d{8}\b/).exec(data) == null) {
        //Special Big 5
        if (new RegExp(/\bW5555\b|\b5555W\b/).exec(data)) return false //WalMart
        if (new RegExp(/\bDSG1111\b|\b1111DSG\b/).exec(data)) return false //Dick
        if (new RegExp(/\bBP9999\b|\b9999BP\b/).exec(data)) return false //BassPro
        if (new RegExp(/\bSG4444\b|\b4444SG\b/).exec(data)) return false //TSG
        if (new RegExp(/\bA1128\b|\b1128A\b/).exec(data)) return false //Academy
        returnValue = true
      }
      if (this.state.subMenuReturn === "amazonReturn" && new RegExp(/\b\d{3}-\d{7}-\d{7}\b/).exec(data) == null) {
        returnValue = true
      }
    }
    return returnValue
  }

  render() {
    const { errors, camera, camTable, scanner, stages, cameraStage, repairStages, notes, manufacturer, sortingOperationStages, subMenuReturn, subMenuReturnList, placeHolder } = this.state;
    const { t } = this.props;
    const currentStage = this.getCurrentStage(camera && camera.footprints)

    const columns = [
        {
          Header: t('inventory.viewCamera.footprintStep'),
          accessor: 'step',
        },
        {
          Header: t('inventory.viewCamera.footprintDate'),
          accessor: row => row.date ? new Date(row.date).toLocaleString(navigator.language, { hour12: false }).replace(/,/g, '') : ""
        },
        {
          Header: t('inventory.viewCamera.footprintUser'),
          accessor: 'instigator.name',
        },
        {
          Header: t('inventory.viewCamera.footprintNotes'),
          accessor: 'notes',
        },
      ]
    

    return (
      <div style={{ marginTop: "30px" }} className="container">
        <div className="row valign-wrapper">
          <div className="col s12 center-align">
            <form noValidate onSubmit={this.onSubmit}>
              <Row>
                <h4>{t('dashboard_main.product_stage')}</h4>
                <h6>{t('inventory.productStage.desc')}</h6>
              </Row>
              <Row>
                <Col s={12} m={4} >
                  <div className="input-field">
                    <input
                      onChange={this.onChange}
                      onFocus={handleFocus}
                      onKeyPress={this.handleKeyPress}
                      value={scanner}
                      error={errors.scanner}
                      id="scanner"
                      type="text"
                      autoFocus
                      className={classnames("", {
                        invalid: errors.scanner
                      })}
                    />
                    <label htmlFor="scanner" className="active">{t('dashboard_main.scanner')}</label>
                    <span id="scannerMessage" className="red-text">
                      {errors.scanner}
                    </span>
                  </div>
                </Col>
                <Col s={12} m={3} className="leftSpanAlignCenter">
                  <span >
                    {t('inventory.productStage.changeStageTo')}
                  </span>

                </Col>
                <Col s={12} m={1} className="leftSpanAlignCenter">
                  <i className="material-icons">label_important</i>
                </Col>
                <Col s={12} m={4} >
                  <div className="input-field">
                    <select type='select'
                      label="Available stage"
                      onChange={this.onChange}
                      value={cameraStage}
                      error={errors.cameraStage}
                      id="cameraStage"
                      className={classnames("", {
                        invalid: errors.cameraStage
                      })}
                      disabled={ !camera.serialNumber || !camera.cameraModel } >
                      {stages.map((item, index) => {
                        if (index === 0) {
                          return <option key={index} value={item.id} disabled>{item.name}</option>
                        } else {
                          return <option key={index} value={item.id} >{item.name}</option>
                        }
                        }).sort(function(a, b) {
                          if (a.props.value !== 'none' || b.props.value !== 'none') {
                            return a.props.children.toString().localeCompare(b.props.children);
                          } else {
                            return 'none';
                          }
                        })
                      }
                    </select>
                    <span id="stagesMessage" className="red-text">
                      {errors.cameraStage}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col s={12} m={4}>
                  <label className="left">{t('dashboard_main.camera_model')}</label>
                  <div className="input-field">
                    <input
                      onChange={this.onChange}
                      value={camera.cameraModel}
                      id="cameraModel"
                      style={{color: "rgb(80,80,80)"}}
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
                <Col s={12} m={4}>
                  <label className="left">IMEI</label>
                  <div className="input-field">
                    <input
                      onChange={this.onChange}
                      value={(camera.imei) || ""}
                      style={{color: "rgb(80,80,80)"}}
                      id="imei"
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
                <Col s={12} m={4}>
                  <label className='left'>{t('input.currentStage')}</label>
                  <div className="input-field">
                    <input
                      onChange={this.onChange}
                      value={t(currentStage).toUpperCase()}
                      style={{color: "white", backgroundColor: currentStage ? "rgb(16, 192, 0)" : "rgb(255, 255, 255)", fontWeight: "900", textAlign: "center"}}
                      id="currentStage"
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
                <Col s={12} m={4}>
                  <label className='left'>{t('dashboard_main.serialNumber')}</label>
                  <div className="input-field">
                    <input
                      onChange={this.onChange}
                      value={(camera.serialNumber) || ""}
                      style={{color: "rgb(80,80,80)"}}
                      id="serialNumber"
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
                <Col s={12} m={4}>
                  <label className='left'>{t('input.firmwareVersion')}</label>
                  <div className="input-field">
                    <input
                      onChange={this.onChange}
                      value={(camera.firmwareVersion && camera.firmwareVersion.name) || ""}
                      style={{color: "rgb(80,80,80)"}}
                      id="firmwareVersion"
                      type="text"
                      disabled
                    />
                  </div>
                </Col>                
                <Col s={12} m={4}>
                  <label className='left'>{t('input.manufacturer')}</label>
                  <div className="input-field">
                    <input
                      onChange={this.onChange}
                      value={manufacturer || ""}
                      style={{color: "rgb(80,80,80)"}}
                      id="manufacturer"
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
                <Col s={12} m={4}>
                  <label className='left'>{t('dashboard_main.simCardNumber')} 1</label>
                  <div className="input-field">
                    <input                    
                      onChange={this.onChange}
                      value={camera.sims[0] || ""}
                      style={{color: "rgb(80,80,80)"}}
                      id="camera.sims[0]"
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
                <Col s={12} m={4}>
                  <label className='left'>{t('dashboard_main.simCardNumber')} 2</label>
                  <div className="input-field">
                    <input                      
                      onChange={this.onChange}
                      value={camera.sims[1] || ""}
                      style={{color: "rgb(80,80,80)"}}
                      id="camera.sims[1]"
                      type="text"
                      disabled
                    />
                  </div>
                </Col>
                <Col s={12} m={4}>
                 {this.showList() && 
                 <div style={{ marginTop: "-1.2rem", }}>
                  <label className='left'>{t('input.repairOptions')}</label>
                    <div className="input-field">
                      <select type='select'
                        name="repairOperation"
                        onChange={this.handleChange}
                        value={this.state.repairOperation}
                        error={errors.repairOperation}
                        id="repairOperation"
                        className={classnames("", {
                          invalid: errors.repairOperation
                        })}
                        >
                        {
                        repairStages.map((item, index) => {
                          if (index === 0 && (item.type == this.getType() || item.type == 0)) {
                            return <option key={index} value={item.name} disabled>{item.name}</option>
                          } else if (item.type == this.getType() || item.type == 0) {
                            return <option key={index} value={item.name}>{item.name}</option>
                          }
                          }).sort(function(a, b) {
                            if (a.props.value !== 'none' || b.props.value !== 'none') {
                              return a.props.children.toString().localeCompare(b.props.children);
                            } else {
                              return 'none';
                            }
                          })
                        }
                      </select>
                      <span id="operationsMessage" className="red-text">
                        {errors.repairOperation}
                      </span>
                    </div>
                  </div>}
                </Col>
                <Col s={12} m={4}>
                 {this.showListSorting() && 
                 <div style={{ marginTop: "-1.2rem", }}>
                  <label className='left'>{t('input.sortingOperation')}</label>
                    <div className="input-field">
                      <select type='select'
                        name="sortingOperation"
                        onChange={this.handleChange}
                        value={this.state.sortingOperation}
                        error={errors.sortingOperation}
                        id="sortingOperation"
                        className={classnames("", {
                          invalid: errors.sortingOperation
                        })}
                        >
                        {
                        sortingOperationStages.map((item, index) => {
                            return <option key={index} value={item.name} >{item.name}</option>
                          }).sort(function(a, b) {
                            if (a.props.value !== 'none' || b.props.value !== 'none') {
                              return a.props.children.toString().localeCompare(b.props.children);
                            } else {
                              return 'none';
                            }
                          })
                        }
                      </select>
                      <span id="operationsMessage" className="red-text">
                        {errors.repairOperation}
                      </span>
                    </div>
                  </div>}
                </Col>
                {
                cameraStage === "return" &&
                <>
                <Col s={12} m={5}>
                <div className="input-field">
                    <select type='select'
                      label="Sub Menu Return"
                      onChange={this.onChangeSubListReturn}
                      value={subMenuReturn}
                      error={errors.subMenuReturn}
                      id="subMenuReturn"
                      className={classnames("", {
                        invalid: errors.subMenuReturn
                      })}
                       >
                      {subMenuReturnList.map((item, index) => {
                        if (index === 0) {
                          return <option key={index} value={item.id} disabled>{item.name}</option>
                        } else {
                          return <option key={index} value={item.id} >{item.name}</option>
                        }
                        }).sort(function(a, b) {
                         /*if (a.props.value !== 'none' || b.props.value !== 'none') {
                            return a.props.children.toString().localeCompare(b.props.children);
                          } else {
                            return 'none';
                          }*/
                        })
                      }
                    </select>
                    <span id="subMenuReturn" className="red-text">
                      {errors.subMenuReturn}
                    </span>
                  </div>
                </Col>
                <Col s={12} m={7}>
                  <label className='left'>{t('input.notes')}</label>
                  <div className="input-field">
                    <input
                      onChange={this.onChangeSpecialNotes}
                      onFocus={handleFocus}
                      onKeyPress={this.handleKeyPress}
                      value={(notes) || ""}
                      error={errors.notes}
                      placeholder={placeHolder}
                      id="notes"
                      type="text"
                      className={classnames("", {
                        invalid: errors.notes
                      })}
                    />
                    <span id="notesError" className="red-text">
                      {errors.notes}
                    </span>
                  </div>
                </Col>
                </>
              }
              {cameraStage !== "return" &&
                <Col s={12} m={12}>
                  <label className='left'>{t('input.notes')}</label>
                  <div className="input-field">
                    <input
                      onChange={this.onChange}
                      onFocus={handleFocus}
                      onKeyPress={this.handleKeyPress}
                      value={(notes) || ""}
                      error={errors.notes}
                      id="notes"
                      type="text"
                      className={classnames("", {
                        invalid: errors.notes
                      })}
                    />
                  </div>
                </Col>
                }
                <Col s={12} m={12}>
                  <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                    <button
                      style={{
                        width: "300px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        marginTop: "1.5rem",
                        marginBotton: "0.3rem"
                      }}
                      type="submit"
                      id="saveCamera"
                      className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                      disabled={ !camera.serialNumber || !camera.cameraModel || this.disabledSendButton()}
                    >{t('dashboard_main.save_camera')}
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </div>
        <div>
            <Row>
              <Col m={12} s={12}>
                <div>
                  <br></br>
                  <Row>
                    <ReactTableBuilder columns={columns} data={camTable} maxRows={20} />
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
      </div>
    );
  }
}
productStage.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(productStage);
