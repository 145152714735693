import { prodapiAxiosClient } from "./prodApiClient";

const updateUserPassword = (userData) => {
    return prodapiAxiosClient
    .put("/api/v1/user/password", userData)
}

const getUsers = () => {
    return prodapiAxiosClient
    .get("/api/v1/user/all")
}

const getUser = userId => {
    return prodapiAxiosClient
    .get("/api/v1/user/" + userId)
}

const deleteUser = userId => {
    return prodapiAxiosClient
    .delete("/api/v1/user/" + userId)
}

const createUser = userData => {
    return prodapiAxiosClient
    .post("/api/v1/user", userData)
}

const editUser = (userData, userId) => {;
    return prodapiAxiosClient
    .put("/api/v1/user/" + userId, userData)
}

const userApi = { getUsers, getUser, deleteUser, createUser, editUser, updateUserPassword }
export default userApi;