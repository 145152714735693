import { prodapiAxiosClient } from "./prodApiClient";

const getListReport = () => {
    return prodapiAxiosClient
    .get("/api/v1/reporting/getListReport/" )
  }

  const getReportById = (queryId) => {
    return prodapiAxiosClient
    .get("/api/v1/reporting/" + queryId )
  }

  const executeQuery = (queryId, body) => {
    return prodapiAxiosClient
    .post("/api/v1/reporting/executeQuery/" + queryId +"/", body )
  }
  
  const deleteReport = reportId => {
    return prodapiAxiosClient
    .delete("/api/v1/reporting/" + reportId)
  }

  const createReport = data => {
    return prodapiAxiosClient
    .post("/api/v1/reporting", data)
  }

  const editReport = (data, reportId) => {
    return prodapiAxiosClient
    .put("/api/v1/reporting/" + reportId, data)
  }

  const reportingApi = { 
    getListReport, 
    getReportById, 
    executeQuery, 
    deleteReport,
    createReport,
    editReport
  }
  export default reportingApi;
