import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useForm } from 'react-hook-form';
import Button from '../../components/material/Button';
import { withTranslation } from 'react-i18next';
import { compose } from "redux";

function UserProfile (props) {
  const { user } = props.auth;
  const { t } = props;
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    }
  });

  const onSubmit = formData => {
    const userData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
    };
    console.log(userData)
    //props.updateUser(userData, props.history);
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col s8 offset-s2">
          <Link to="/" className="btn-flat waves-effect">
            <i className="material-icons left">keyboard_backspace</i> {t('userprofile.back_to_home')}
          </Link>
          {props.success.success  && <p className="green-text" >{props.success.success}</p>}
          <div className="col s12" style={{ paddingLeft: "11.250px" }}>
            <h4>
              <b>{t('userprofile.userprofile_title')}</b>
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-field col s12">
              <input
                {...register('firstName', { required: t('input.first_name_required'),
                  pattern: {
                    value: /^[a-zA-Z\- ]{2,30}$/,
                    message: t('input.first_name_invalid')
                }})}
                id="firstName"
                name="firstName"
                type="text"
              />
              <label htmlFor="firstName">{t('userprofile.first_name')}</label>
              {errors.firstName  && <p className="red-text" >{errors.firstName.message}</p>}
            </div>
            <div className="input-field col s12">
              <input
                {...register('lastName', { required: t('input.last_name_required'),
                  pattern: {
                    value: /^[a-zA-Z\- ]{2,30}$/,
                    message: t('input.last_name_invalid')
                }})}
                id="lastName"
                name="lastName"
                type="text"
              />
            <label htmlFor="lastName">{t('userprofile.last_name')}</label>
            {errors.lastName  && <p className="red-text" >{errors.lastName.message}</p>}
            </div>
            <div className="input-field col s12">
              <input
                {...register('email', { required: t('input.email_required'),
                  pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: t('input.email_invalid')
                  }})}
                id="email"
                name="email"
                type="email"
              />
              <label htmlFor="email">{t('userprofile.email')}</label>
              {errors.email  && <p className="red-text" >{errors.email.message}</p>}
            </div>
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <Button text={t('userprofile.save_button')}></Button>
            </div>
            <div className="col s12" style={{ paddingLeft: "11.250px", paddingTop: "10px" }}>
              <Link to="/setting/changePassword" className="btn btn-large waves-effect waves-light hoverable blue accent-3">
                {t('userprofile.password_change')}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

UserProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  success: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  success: state.success
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(UserProfile);
