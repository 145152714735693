import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import { useTable, useSortBy, usePagination } from 'react-table'
import Pagination from './ReactTablePagination'; // import the Pagination component

/**
 * This component is a reusable table component built with react-table.
 * It has the possibility of sorting on all columns and pagination.
 *
 * @param {Array} columns - The columns of the table.
 * @param {Array} data - The data to be displayed in the table.
 * @param {number} [maxRows=10] - The maximum number of rows to be displayed per page.
 *
 * @returns A react-table table component to show data with sorting and pagination features.
 * 
 * @usage <ReactTableBuilder columns={columns} data={data} maxRows={20} />
 */
function ReactTableBuilder ({ columns, data, maxRows = 10 }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: maxRows },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <table {...getTableProps()} style={{ lineHeight: '32px', width: '100%', margin: '0 auto', borderCollapse: 'collapse' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} style={{ borderBottom: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ padding: '10px', borderBottom: '2px solid #ddd', fontWeight: 'bold' }}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            const rowStyle = row.original.status === "error" ? { color: 'red' } : row.original.status === "warning" ? { color: "orange" } : {}
            return (
              <tr {...row.getRowProps()} style={{ borderBottom: '1px solid #ddd', ...rowStyle }}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()} style={{ padding: '10px' }}>{cell.render('Cell')}</td>
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length > maxRows &&
        <Pagination
        pages={pageCount}
        page={pageIndex}
        onPageChange={gotoPage}
        previousText='Previous'
        nextText='Next'
        />
      }
    </>
  );
}

ReactTableBuilder.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(ReactTableBuilder);
