import React from 'react';
import { getProductPageLink, getSite} from "../../../src/utils/site"

const Footer = () => (
  <footer className="footer site-footer">
    <div className="container-fluid">
      <p className="copyright pull-right">
        © {new Date().getFullYear()} <a  target="_blank" href={getProductPageLink()}>{getSite().toUpperCase()}</a>
      </p>
    </div>
  </footer>
  
);

export default Footer;
