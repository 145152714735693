import { prodapiAxiosClient } from "../api/prodApiClient";

const newPlant = (newPlant) => {
    return prodapiAxiosClient
    .put("/api/v1/plants/newplant/", newPlant )
}
const getPlant = (plant_id) => {
    return prodapiAxiosClient
    .get(`/api/v1/plants/` + plant_id)    
}

const softDeletePlant = (plant_id, data) => {
    return prodapiAxiosClient
    .post("/api/v1/plants/"+ plant_id +"/", data)
}

const putPlant = (plant_id, data) => {
    return prodapiAxiosClient
    .post("/api/v1/plants/" + plant_id + "/", data)
}

const getPlants = () => {
    return prodapiAxiosClient
    .get("/api/v1/plants/all")
}

const plantApi = { newPlant, getPlant, softDeletePlant, putPlant, getPlants}
export default plantApi