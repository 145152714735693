import React, { useState, useEffect } from "react";
import { Col } from "react-materialize";
import { useForm } from "react-hook-form";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
import productionTestingApi from "../../../api/productTestingApi";

function AddDisplayColumns(props) {
  const { t } = props;
  const { handleSubmit, formState: { errors } } = useForm()
  const [displayColumns, setDisplayColumns] = useState(props.displayColumns)
  const [htmlTable, setHtmlTable] = useState([])
  const [render, setRender] = useState(false)

    function onSubmit() {
        props.onClose(displayColumns)
    }

    function addElement() {
        const input = document.getElementById("displayColumns")
        console.log(input.value)
        displayColumns.push(input.value)
        setDisplayColumns(displayColumns)
        setRender(!render)
        input.value = ""
        input.select()
    }

    function deleteElement(e) {
        const index = displayColumns.indexOf(e.target.id)
        displayColumns.splice(index, 1)
        setDisplayColumns(displayColumns)
        setRender(!render)
    }

    useEffect(() => {
        let html = []
        displayColumns.forEach((displayColumn, key) => {
            html.push(
                <tr key={key}>
                    <td className="center-align">{displayColumn}</td>
                    <td><ButtonFactory name={displayColumn} icon='delete' onClick={deleteElement}/></td>
                </tr>
            )
        });
        setHtmlTable(html)
    }, [displayColumns, render]);

    return(
      <div id="addDisplayColumn" style={{  maxHeight: "95%", position:"fixed", width:"550px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"90px", marginLeft:"200px", zIndex:"999",overflowY:"scroll" }} className="container center-align"   >
       <Col s={12} >
        <h3 className="center-align defaultTheme white-text" >
          {t('setting.reporting_add_display_columns')}
        </h3>
       </Col>
       <form onSubmit={handleSubmit(onSubmit)} name="userForm" id="userForm">
        <table style={{ marginTop: "25px" }} >
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2" }}>
              <td className="center-align">{t('input.display_columns')}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {htmlTable}
            <tr>
                <td>
                    <input id="displayColumns" type="text" name="displayColumns" className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
                    {errors.displayColumns && <p className="red-text" >{errors.displayColumns.message}</p>}
                </td>
                <td>
                    <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={addElement} />
                </td>
            </tr>
          </tbody>
        </table>
         <hr/>
         <div className="row " style={{float:"right"}}>
          <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
        </div>
       </form>
      </div>
    );
}

AddDisplayColumns.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(AddDisplayColumns);
