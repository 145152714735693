import React from "react";
import ButtonIcon from './Button/ButtonIcon';
import ButtonText from './Button/ButtonText';
import ButtonRef from './Button/ButtonRef';
import ButtonIconLarge from './Button/ButtonIconLarge';

function ButtonFactory(props) {
    if (props.href) {
        return (
            <ButtonRef href={props.href} icon={props.icon} style={props.style} className={props.className} />
        );
    } else if (props.text) {
        return (
            <ButtonText name={props.name} text={props.text} onClick={props.onClick} disabled={props.disabled} style={props.style} className={props.className}/>
        );
    } else if(props.icon) {
        if(props.isLargeIcon) {
          return (
            <ButtonIconLarge name={props.name} icon={props.icon} onClick={props.onClick} style={props.style} className={props.className}/>
          );
        } else {
            return (
                <ButtonIcon onClick={props.onClick} name={props.name} icon={props.icon} style={props.style} className={props.className}/>
            );
        }  
    }
}
export default ButtonFactory;