import React, { useState, useEffect } from "react";
import { Col } from "react-materialize";
import { useForm } from "react-hook-form";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
import userApi from "../../../api/userApi";
import reportingApi from "../../../api/reportingApi";


function LinkUserReport(props) {
  const { t } = props;
  const report = props.reports.find(report => report.id === props.reportId);
  const { handleSubmit, formState: { errors } } = useForm()
  const [users, setUsers] = useState([])
  const [usersLinked, setUsersLinked] = useState(report.users || [])

  useEffect(() => {
    userApi.getUsers()
    .then(res => {
        let userArray = []
        res.data.forEach(user => {
            if(!usersLinked.includes(user.email)) {
                userArray.push(user.email)
            }
        });
        setUsers(userArray);
    })
    .catch(err => {
        console.log(err)
    });
  }, []);

  function addUser() {
    const dropDown = document.getElementById("users")
    const index = users.indexOf(dropDown.value)
    users.splice(index, 1)
    setUsers([...users])
    usersLinked.push(dropDown.value)
    setUsersLinked(usersLinked)
  }

  function deleteUser(e) {
    users.push(e.target.id)
    setUsers([...users])
    const index = usersLinked.indexOf(e.target.id)
    usersLinked.splice(index, 1)
    setUsersLinked(usersLinked)
  }

  function onSubmit() {
    report.users = usersLinked
    reportingApi.editReport(report, report.id)
      .then( res => {
        props.onClose();
      })
      .catch(err => {
        throw err
    });
  }

    function makeTable() {
        return usersLinked.map((user, key) =>
        <tr key={key}>
          <td className="center-align">{user}</td>
          <td><ButtonFactory name={user} icon='delete' onClick={deleteUser}/></td>
        </tr>)
    }

    return(
      <div id="linkCameraModel" style={{  maxHeight: "95%", position:"fixed", width:"900px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"-95px", marginLeft:" calc(45% - 730px)", zIndex:"100",overflowY:"scroll" }} className="container center-align">
       <Col s={12} >
        <h3 className="center-align defaultTheme white-text" >
          {t('setting.reporting_link')}
        </h3>
       </Col>
       <form onSubmit={handleSubmit(onSubmit)} name="userForm" id="userForm">
       <Col m={4} s={12}>
          <label className="active" htmlFor="name" style={{ width: "300px", display: "inline-block" }} >{t('input.name')}</label>
          <input type="text" name="name" readOnly value={report.name} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.name && <p className="red-text" >{errors.name.message}</p>}
        </Col>
        <table style={{ marginTop: "25px" }} >
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2" }}>
              <td className="center-align">{t('setting.users')}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {makeTable()}
            <tr>
                <td>
                    <select id="users" name="users" className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "12.5%", marginRight: "12.5%", marginBottom: "5px", width: "75%" }}>
                        {users.map((user, index) => (
                            <option key={index} value={user}>{user}</option>
                        ))}
                    </select>
                    {errors.users && <p className="red-text" >{errors.users.message}</p>}
                </td>
                <td>
                    <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={addUser}/>
                </td>
            </tr>
          </tbody>
        </table>
        <div>
            
        </div>
        <hr/>
         <div className="row " style={{float:"right"}}>
          <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
        </div>
       </form>
      </div>
    );
}

LinkUserReport.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(LinkUserReport);
