import React, { useState } from "react";
import { Col, Row } from "react-materialize";
import { useForm } from "react-hook-form";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
import reportingApi from "../../../api/reportingApi";
import AddParams from "./addParams";
import AddDisplayColumns from "./addDisplayColumns";
import AddRoles from "./addRoles";

function CreateReport(props) {
  const { t } = props;
  const models = ["CameraModel", "CameraModelModel", "MasterPackModel", "PackageSlipModel", "PlantModel", "UserModel"]
  const { register, handleSubmit, formState: { errors } } = useForm()
  const [isActive, setIsActive] = useState(true)
  const [showParams, setShowParams] = useState(false)
  const [showDisplayColumns, setShowDisplayColumns] = useState(false)
  const [showRoles, setShowRoles] = useState(false)
  const [displayColumns, setDisplayColumns] = useState([])
  const [params, setParams] = useState([])
  const [roles, setRoles] = useState([])
  const aggregate = document.getElementById("aggregate")
  const find = document.getElementById("find")

  function cancelButton(e) {
    props.onClose(e);
  }

  function onSubmit(data) {
    const reportData = {
      name : data.name,
      query: data.query,
      model: data.model,
      search: isActive ? "aggregate" : "find",
      projection: data.projection,
      params: params,
      displayColumns: displayColumns,
      roles: roles,
      sites: ["spypoint", "vosker"],
      toLink: "/reporting/reporting",
      textSpan: data.textSpan,
      textAction: data.textAction,
      text: data.text,
      users: []
    };

    reportingApi.createReport(reportData)
      .then( res => {
        props.onClose();
      })
      .catch(err => {
        throw err
    });
  }

  function showAddParams() {
    setShowDisplayColumns(false)
    setShowParams(!showParams)
    setShowRoles(false)
  }

  function showAddDisplayColumns() {
    setShowDisplayColumns(!showDisplayColumns)
    setShowParams(false)
    setShowRoles(false)
  }

  function showAddRoles() {
    setShowRoles(!showRoles)
    setShowParams(false)
    setShowDisplayColumns(false)
  }

  function handleParamClose(params) {
    setParams(params)
    setShowParams(false)
  }

  function handleDisplayColumnsClose(displayColumns) {
    setDisplayColumns(displayColumns)
    setShowDisplayColumns(false)
  }

  function handleRoleClose(roles) {
    setRoles(roles)
    setShowRoles(false)
  }

  function handleInputChange(e) {
    aggregate.checked = !isActive
    find.checked = isActive
    setIsActive(!isActive)
  }

    return(
      <div id="addTest" style={{  maxHeight: "95%", position:"fixed", width:"550px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"-95px", marginLeft:" calc(50% - 730px)", zIndex:"999",overflowY:"scroll" }} className="container center-align"   >
        {showParams &&
          <AddParams
            onClose={handleParamClose}
            params={params}
          />
        }
        {showDisplayColumns &&
          <AddDisplayColumns
            onClose={handleDisplayColumnsClose}
            displayColumns={displayColumns}
          />
        }
        {showRoles &&
          <AddRoles
            onClose={handleRoleClose}
            roles={roles}
          />
        }
       <Col s={12} >
        <h3 className="center-align defaultTheme white-text" >
          {t('setting.reporting_add')}
        </h3>
       </Col>
       <form onSubmit={handleSubmit(onSubmit)} name="userForm" id="userForm">
       <Col m={4} s={12}>
          <label className="active" htmlFor="name" style={{ width: "300px", display: "inline-block" }} >{t('input.name')}</label>
          <input type="text" name="name" {...register("name", { required:  t('input.name_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.name && <p className="red-text" >{errors.name.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="query" style={{ width: "300px", display: "inline-block" }} >{t('setting.query')}</label>
          <textarea type="text" name="query" {...register('query', {required: t('input.query_required')})} className=" left-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%", height: "200px" }}></textarea>
          {errors.query && <p className="red-text" >{errors.query.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="projection" style={{ width: "300px", display: "inline-block" }} >{t('input.projection')}</label>
          <input type="text" name="projection" {...register("projection", { required:  t('input.projection_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.projection && <p className="red-text" >{errors.projection.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="textSpan" style={{ width: "300px", display: "inline-block" }} >{t('input.text_span')}</label>
          <input type="text" name="textSpan" {...register("textSpan", { required:  t('input.text_span_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.textSpan && <p className="red-text" >{errors.textSpan.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="text" style={{ width: "300px", display: "inline-block" }} >{t('input.text')}</label>
          <input type="text" name="text" {...register("text", { required:  t('input.text_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.text && <p className="red-text" >{errors.text.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="textAction" style={{ width: "300px", display: "inline-block" }} >{t('input.text_action')}</label>
          <input type="text" name="textAction" {...register("textAction", { required:  t('input.text_action_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.textAction && <p className="red-text" >{errors.textAction.message}</p>}
        </Col>
        <Row>
            <Col m={6}>
                <h6 className="right-align">{t('input.params')}</h6>
            </Col>
            <Col m={6}>
                <div className="left-align"><ButtonFactory name="params" icon='edit' onClick={showAddParams}/></div>
            </Col>
        </Row>
        <Row>
            <Col m={6}>
                <h6 className="right-align">{t('input.display_columns')}</h6>
            </Col>
            <Col m={6}>
                <div className="left-align"><ButtonFactory name="params" icon='edit' onClick={showAddDisplayColumns} /></div>
            </Col>
        </Row>
        <Row>
            <Col m={6}>
                <h6 className="right-align">{t('input.roles')}</h6>
            </Col>
            <Col m={6}>
                <div className="left-align"><ButtonFactory name="params" icon='edit' onClick={showAddRoles}/></div>
            </Col>
        </Row>
        <Row>
        <Col m={6} s={12} className="right-align">
            <label className="active" htmlFor="aggregate" style={{ marginRight: "10px", display: "inline-block", fontSize: "30px" }} >Aggregate</label>
            <input type="checkbox" id="aggregate" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible", height: "25px", width: "25px"}} defaultChecked={isActive}  onClick={handleInputChange} />
        </Col>
        <Col m={6} s={12} className="left-align">
            <label className="active" htmlFor="find" style={{ marginRight: "10px", display: "inline-block", fontSize: "30px" }} >Find</label>
            <input type="checkbox" id="find" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible", height: "25px", width: "25px"}} defaultChecked={!isActive}  onClick={handleInputChange} />
        </Col>
        </Row>
        <Col m={4} s={12}>
          <label className="active" htmlFor="model" style={{ width: "300px", display: "inline-block" }} >{t('setting.database_model')}</label>
          <select name="model" {...register('model')} className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "12.5%", marginRight: "12.5%", marginBottom: "5px", width: "75%" }}>
            {models.map(model => (
                <option key={model} value={model}>{model}</option>
            ))}
          </select>
          {errors.model && <p className="red-text" >{errors.model.message}</p>}
        </Col>
         <hr/>
         <div className="row " style={{float:"right"}}>
          <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
          <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{marginRight:"15px"}}/>
        </div>
       </form>
      </div>
    );
}

CreateReport.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(CreateReport);
