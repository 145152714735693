import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { Link } from "react-router-dom";
import '../../../src/loader.css';
import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import { isValidSerialNumber, isValidMasterpackBarcode } from '../../utils/validation';
import masterpackApi from "../../api/masterpackApi";
import cameraApi from "../../api/cameraApi";
import shippingApi from "../../api/shippingApi";
import ButtonFactory from "../../components/material/ButtonFactory";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ReactTableBuilder from "../../utils/reactTableBuilder";

const handleFocus = (event) => event.target.select();

class Shipping extends Component {
  constructor() {
    super();

    this.state = {
      customerName: "",
      data: "",
      errors: {},
      invoice: "",
      invoices: [],
      invoiceDate : "",
      invoiceAmount : "",
      isLoading: false,
      items: [],
      itemsCount: "",
      noInvoice: false,
      package: "",
      packageSlip: "",
      poNumber: "",
      resp: "",
      scanner: "",
      shippingDate: "",
      shipToAddress: "",
      soldToAddress: "",
      warehouse: "",
      warehouses: [{name: "Netsuite"}],
    };

    // this.onSubmit = this.onSubmit.bind(this);
    // this.handleKeyPress = this.handleKeyPress.bind(this);

  };

  componentDidMount() {
    const { t } = this.props;
    this.setState({
      warehouse: t("global.selectAWarehouse"),
      poNumber: t("global.selectInvoice")
    })
    this.loadWarehouses();
  }

  getEmptyStates = () => {
    this.setState({
      customerName: "",
      data: "",
      errors: {},
      invoice: "",
      invoices: [],
      invoiceDate : "",
      invoiceAmount : "",
      items: [],
      itemsCount: "",
      noInvoice: false,
      package: "",
      packageSlip: "",
      poNumber: "",
      resp: "",
      scanner: "",
      shippingDate: "",
      shipToAddress: "",
      soldToAddress: "",
      warehouse: ""
    });
  }

  loadWarehouses = () => { 
    const { t } = this.props;
    let storage = [{"name": t("global.selectAWarehouse") }, ...this.state.warehouses]
    this.setState({ warehouses: storage })

    if (localStorage.warehouse) {
      this.setState({warehouse: JSON.parse(localStorage.warehouse)});
    }
  }

  getAllCamera = invoiceNumber => {
    const { t } = this.props;
    shippingApi.cameraByPackingSlip(invoiceNumber).then(response => {
      let cameras = response.data.cams
      let items = this.state.items;
      let packCamera = [];

      cameras.forEach(camera => {
        if (!(items.some(item => item.serialNumber === camera.serialNumber))) {
          if (camera.masterPacks.length > 0) {
            packCamera.push({serialNumber: camera.serialNumber, masterpack: camera.masterPacks[0].barcode, cameraModel: camera.cameraModel, id: camera.id });
          } else {
            packCamera.push({serialNumber: camera.serialNumber, masterpack: camera.masterpack, cameraModel: camera.cameraModel, id: camera.id });
          }
        }
      })

      let product = items.concat(packCamera);
      this.setState({ items: product})
    }).catch(err => {
      this.setState({ errors: { scanner: t('dashboard_main.scanNotRecognized') } });
      this.setState({ isLoading: false });
    });
  }



  getCameraInfoSN = sn => {
    cameraApi.getCamera(sn)
    .then(res => {
      this.setState({ camera: res.data })
      let camera = res.data;
      let items = this.state.items;
      let packCamera = [];
      packCamera = {serialNumber: camera.serialNumber, masterpack: "", cameraModel: camera.cameraModel, id: camera.id };

      let product = items.concat(packCamera);
      this.setState({ items: product})
      this.counters();
    })
    .catch(err => {
      const errors = err
      this.setState({ errors: errors })
    })
  }

  getCameraInfoMP = mp => {
    const { t } = this.props;
 
    masterpackApi.getMasterpackSN(mp)
    .then(res => {
      this.setState({ data: res.data.items })
      let masterpack = res.data.items;
      let items = this.state.items;

      let arr1 = []; //serialNumbers already on table
      for (let y = 0; y < items.length; y++) {
        arr1[y] = items[y].serialNumber;
      }

      let arr2 = []; //serialNumbers from masterpack
      for (let j = 0; j < masterpack.length; j++) {
        arr2[j] = masterpack[j].serialNumber;
      }

      if ( arr1.some(o => arr2.includes(o) )) {
        this.setState({ errors: { scanner: t('inventory.shipping.serialAssociated') } });
      } else if (res.data.length !== 0) {
        let pack = [];

        for (let i = 0; i < masterpack.length; i++) {
          pack[i] = {serialNumber: masterpack[i].serialNumber, masterpack: mp, cameraModel: masterpack[i].cameraModel, id: masterpack[i].id };
        }

        let products = items.concat(pack);
        this.setState({ items: products, scanner: ""})
        this.counters();
      } else {
        this.setState({ errors: { scanner: t('inventory.shipping.noProducts', {myVar: `${mp}`} )} });
      }
    })
    .catch(err => {
      const errors = err
      this.setState({ errors: errors })
    });
  }




  handleKeyPress = e => {
    const { t } = this.props;
    this.setState({ errors: {} });
    e.target.value = e.target.value.toUpperCase()

    if (e.key === 'Enter' && e.target.value !== "") {
      if (e.target.value === "GG-ENTER") {
        this.setState({ scanner: "" });
        this.onSubmit(e);
      } else if (e.target.value === "GG-CLEAR") {
        this.setState({
          scanner: "",
          items: []
        });
      } else if (isValidSerialNumber(e.target.value.split(/,|;/)[0])) {
        if (this.state.items.find(i => i.serialNumber === e.target.value.split(/,|;/)[0])) {
          this.setState({ errors: { scanner: t('inventory.shipping.serialNumberExists', {myVar: `${e.target.value.split(/,|;/)[0]}`} )} });
          this.setState({ scanner: ""})
        } else {
          let serialNumber = e.target.value.split(/,|;/)[0];
          this.setState({ scanner: ""})

          this.getCameraInfoSN(serialNumber);
        }
        e.preventDefault(); // No submit with enter key
      } else if (isValidMasterpackBarcode(e.target.value)) {
        if (this.state.items.find(i => i.masterpack === e.target.value)) {
          this.setState({ errors: { scanner: t('inventory.shipping.masterpackExists', {myVar: `${e.target.value}`} )} });
          this.setState({ scanner: ""})
        } else {
          let masterpackNumber = e.target.value;
          this.setState({ scanner: ""})

          this.getCameraInfoMP(masterpackNumber);
        }
        e.preventDefault(); // No submit with enter key
      } else {
        let warehouse = this.state.warehouse;
        this.setState({ isLoading: true })

        this.getAllCamera(e.target.value);

        shippingApi.shipment(warehouse, e.target.value)
        .then(res => {
            this.setState({ package: res.data })
            this.setState({ isLoading: false })

            if (res.data.length === 1) {
              this.setState({
                customerName: this.state.package[0].customerName,
                packageSlip: this.state.package[0].packingSlipNumber,
                shipToAddress: this.state.package[0].shipToAddress,
                soldToAddress: this.state.package[0].soldToAddress,
                invoices: [],
                scanner: ""
              })
            } else if (res.data.length > 1) {
              this.emptyPackage()
              let invoicesVar = res.data
              let invoiceList = []
              for (let k = 0; k < invoicesVar.length; k++){
                invoiceList[k] = {
                  poNumber : invoicesVar[k].poNumber,
                  invoiceDate : invoicesVar[k].invoiceDate,
                  invoiceAmount : invoicesVar[k].invoiceAmount,
                  customerName: invoicesVar[k].customerName,
			            packageSlip: invoicesVar[k].packingSlipNumber,
                  shipToAddress: invoicesVar[k].shipToAddress,
                  soldToAddress: invoicesVar[k].soldToAddress,
                }
              }
              let invoiceIdx = [{"customerName": t("global.selectInvoice") }, ...invoiceList]
              this.setState({ invoices: invoiceIdx })
            } else if (warehouse == "Netsuite"){
              this.setState({
                customerName: res.data.customerName,
                packageSlip: res.data.packingSlipNumber,
                shipToAddress: res.data.shipToAddress,
                soldToAddress: res.data.soldToAddress,
                invoices: [],
                scanner: ""
              })
            }
        })
        .catch(err => {
          //const errors = err;
          this.setState({ errors: { scanner: t('packingSlipNotFind') } });
          this.setState({ isLoading: false });
        });
        e.preventDefault(); // No submit with enter key
      }
    }

    if (e.key === 'Enter' && e.target.value === "") {
      e.preventDefault(); //No submit
    }
  };

  counters = () => {
    let itemsCount = this.state.items.length;
    
    this.setState({ itemsCount: itemsCount })
  }

  emptyPackage = () => {
    this.setState({
      customerName: "",
      invoice: "",
      invoices: [],
      invoiceDate : "",
      invoiceAmount : "",
      noInvoice: false,
      package: "",
      packageSlip: "",
      shippingDate: "",
      shipToAddress: "",
      soldToAddress: ""
    });
  }

  onChange = e => {
    const { t } = this.props;
    var states = {
      [e.target.id]: e.target.value
    }

    if(e.target.id === "warehouse" && this.state.packageSlip !== "") {
      // eslint-disable-next-line no-restricted-globals
      let message = confirm(t('message.changingWarehouse'));
      if (message) {
        this.setState({
          packageSlip: "" ,
          customerName: "",
          shipToAddress: "",
          soldToAddress: "",
          invoice: "",
          invoices: [],
          warehouse: e.target.value
        });
      } else {
        this.setState({warehouse: this.state.warehouse});
      }
    } else if (e.target.id === "invoices") {
      let poNumberInvoice = this.state.invoices.find(l => l.poNumber === e.target.value);
      this.setState({
        customerName: poNumberInvoice.customerName,
        poNumber: poNumberInvoice.poNumber,
        packageSlip: poNumberInvoice.packageSlip,
        shipToAddress: poNumberInvoice.shipToAddress,
        soldToAddress: poNumberInvoice.soldToAddress,
        scanner: ""
      });
    } else {
      this.setState(states);

      if (e.target.id === "warehouse") {
        localStorage.setItem("warehouse", JSON.stringify(e.target.value))
      }
    }
  };

  onSubmit = e => {
    const { t } = this.props;
    const dataId = this.state.items //this.state.items.map(o => o.id);

    var filterDataId = dataId.filter(function (el) {
      return el.id != null;
    });
    const depot = this.state.warehouse;
    const date = new Date();
    const invoiceNumber = this.state.packageSlip;
    const data = {
      customerName: this.state.customerName,
      shipToAddress: this.state.shipToAddress,
      soldToAddress: this.state.soldToAddress,
      shippingDate: date,
      items: filterDataId
    };

    //this.onChange(e);

    shippingApi.buildShipment(depot, invoiceNumber, data)
    .then(res => {
      this.getEmptyStates();
      this.loadWarehouses();
      if (res.status === 200 && res.data === 'OK') {
        NotificationManager.success(t('message.saveConfirmed'), t('message.successMessage'), 2000);
      }
    })
    .catch(err => {
      const error = err;
      console.info(error);
      // this.setState({ errors: errors })
      NotificationManager.error(t('message.saveNotConfirmed'), t('message.errorMessage'), 5000);
    });
    
  };

  quitPage = e => {
    const { t } = this.props;
    if (this.state.items.length !== 0 || this.state.packageSlip ) {
      //eslint-disable-next-line no-restricted-globals
      let confirmMsg = confirm(t('message.confirmExit'));
      if (confirmMsg) {
        //Quit page
      } else {
        //Show message
        e.preventDefault();
        e.returnValue = '';
      }
    }
  }

  handleInputChange = e => {
    if (e.target.checked) {
      this.setState({ noInvoice: true, packageSlip: "No_invoice", customerName: "", shipToAddress: "", soldToAddress: "" })
    } else {
      this.setState({ noInvoice: false, packageSlip: "", customerName: "", shipToAddress: "", soldToAddress: "" })
    }
  }

  render() {
    const { errors, noInvoice, poNumber, invoices, warehouse, warehouses, items, itemsCount } = this.state;
    const { t } = this.props;

    const columns = [
      {
        Header: t('dashboard_main.serialNumber'),
        accessor: 'serialNumber',
      },
      {
        Header: t('dashboard_main.masterpack_barcode'),
        accessor: 'masterpack',
      },
      {
        Header: t('inventory.shipping.model'),
        accessor: 'cameraModel',
      },
      {
        Header: t('inventory.shipping.deleteItem'),
        id: 'delete',
        accessor: 'delete',
        Cell: ({ row }) => (
          <div style={{ textAlign: "center" }}>
            <ButtonFactory icon='delete'
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals
                let confirmMessage = confirm(t('message.confirmDeleteCam'));
                if (confirmMessage) {
                  items.splice(row.index, 1)
                  this.setState({items})
                  this.counters();
                }              
              }} 
            />
          </div>
        ),
      },
    ]

    return (
      <div>
        <div style={{ marginTop: "30px" }} className="container">
          <div className={this.state.isLoading ? 'parentDisable' : ''} width="100%">
            <div className={this.state.isLoading ? 'loader' : ''}></div>
          </div>
          <div className="row valign-wrapper">
            <div className="col s12 center-align">
              <form noValidate onSubmit={this.onSubmit} >
                <Row>
                  <Col s={0}>
                    <Link to="/" className="btn-flat waves-effect" onClick={this.quitPage}>
                      <i className="material-icons left" >keyboard_backspace</i> {t('userprofile.back_to_home')}
                    </Link>
                  </Col>
                  <Col s={12} m={6} >
                      <h4>{t('dashboard_main.shipment_deprecated')}</h4>
                      <h6>{t('dashboard_main.shipment_desc_deprecated')}</h6>
                  </Col>
                </Row>
                <Row>
                  <Col s={12} m={4}>
                    <div className="input-field" >
                      <select type='select'
                          label="Warehouses"
                          onChange={this.onChange}
                          value={warehouse}
                          error={errors.warehouse}
                          id="warehouse"
                          className={classnames("", {
                          invalid: errors.warehouse
                          })} >                          
                          {/* eslint-disable-next-line array-callback-return */}
                          {warehouses.map((item, index) => {
                            if (!item.active) {
                              if (index === 0) {
                              return <option key={index} value={item.name} disabled>{item.name}</option>
                              } else {
                              return <option key={index} value={item.name} >{item.name}</option>
                              }
                            }
                          })}                        
                      </select>
                      <span id="warehouseMessage" className="red-text">
                        {errors.warehouse}
                      </span>
                    </div>
                  </Col>
                  <Col s={12} m={7} >
                    <div className="input-field">
                      <input
                        onChange={this.onChange}
                        onFocus={handleFocus}
                        onKeyPress={this.handleKeyPress}
                        value={this.state.scanner}
                        error={errors.scanner}
                        id="scanner"
                        type="text"
                        disabled={ this.state.warehouse === t("global.selectAWarehouse") }
                        className={classnames("", {
                        invalid: errors.scanner
                        })}
                      />
                      <label htmlFor="scanner">{t('dashboard_main.scanner')}</label>
                      <span id="scannerMessage" className="red-text">
                          {errors.scanner}
                      </span>
                    </div>
                  </Col>
                  <Col s={12} m={1}>
                    <label className="active" htmlFor="isActiveCheck"  style={{ width: "90%", display: "inline-block" }} >{t('dashboard_main.noInvoice')}</label>
                    <input type="checkbox" id="isActiveCheck" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible"}} checked={noInvoice} onChange={this.handleInputChange} disabled={ this.state.warehouse === t("global.selectAWarehouse") } />
                  </Col>
                </Row>
                <Row>
                  <Col s={12} m={4} >
                    <label className='left' style={ invoices.length === 0 ? { visibility: 'hidden' } : { display: 'block' } }>{ t('inventory.shipping.invoices') }</label>
                    <div className="input-field" >
                      <select type='select'
                        style={ invoices.length === 0 ? { visibility: 'hidden' } : { display: 'block' } }
                        onChange={this.onChange}
                        value={poNumber}
                        error={errors.invoices}
                        id="invoices"
                        className={classnames("", {
                        invalid: errors.invoices
                        })} >
                        { invoices.length !== 0 ?
                            // eslint-disable-next-line array-callback-return
                            invoices.map((item, index) => {
                                if (index === 0) {
                                return <option key={index} value={item.poNumber} disabled>{item.customerName}</option>
                                } else {
                                return <option key={index} value={item.poNumber}>{item.customerName}&nbsp;/&nbsp;{item.poNumber}&nbsp;/&nbsp;{item.invoiceDate.slice(0,10)}</option>
                                }
                            })
                          : null
                        }
                      </select>
                      <span id="invoiceMessage" className="red-text">{errors.invoices}</span>
                    </div>
                    <label className='left'>{ t('inventory.shipping.packageSlip') }</label>
                    <div className="input-field">
                      <input
                        value={this.state.packageSlip}
                        error={errors.packageSlip}
                        id="packageSlip"
                        disabled
                        type="text"
                        className={classnames("", {
                          invalid: errors.packageSlip
                        })}
                      />
                    </div>
                  </Col>
                  <Col s={12} m={4} >
                    <div className="infoCenterShipping" id='billInformation'>
                      {this.state.noInvoice ?
                      <div>
                        <label htmlFor="billToName">{ t('inventory.shipping.billToCustomer') }</label>
                        <input type="text" id="customerName" name="billToName" onChange={this.onChange} value={this.state.customerName} />
                        <label htmlFor="billToAddress">{ t('inventory.shipping.billToAddress') }</label>
                        <input type="text" id="soldToAddress" name="billToAddress" onChange={this.onChange} value={this.state.soldToAddress} />
                      </div>
                      :
                      <div>
                        <span>{ t('inventory.shipping.billTo') }</span>
                        <p>{this.state.customerName}</p>
                        <p>{this.state.soldToAddress}</p>
                      </div> }
                    </div>
                  </Col>
                  <Col s={12} m={4} >
                    <div className="infoCenterShipping" id='shipInformation'>
                      {this.state.noInvoice ?
                      <div>
                        <label htmlFor="shipToName">{ t('inventory.shipping.shipToCustomer') }</label>
                        <input type="text" id="customerName" name="shipToName" onChange={this.onChange} value={this.state.customerName}/>
                        <label htmlFor="shipToAddress">{ t('inventory.shipping.shipToAddress') }</label>
                        <input type="text" id="shipToAddress" name="shipToAddress" onChange={this.onChange} value={this.state.shipToAddress}/>
                      </div>
                      :
                      <div>
                        <span>{ t('inventory.shipping.shipTo') }</span>
                        <p>{this.state.customerName}</p>
                        <p>{this.state.shipToAddress}</p>
                      </div> }
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col s={12} m={1}>
                    <span style={ itemsCount === "" || itemsCount === 0 ? { visibility: 'hidden', fontSize: 50 } : { fontSize: 50 } }>{ itemsCount }</span><br></br>
                    <span style={ itemsCount === "" || itemsCount === 0 ? { visibility: 'hidden', fontSize: 20 } : { fontSize: 20 } }>{ t('inventory.shipping.items') }</span>
                  </Col>
                  <Col s={12} m={11}>
                    <button
                      style={{
                        width: "300px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        top: "25px",
                        float: "right"
                      }}
                      disabled={ items.length === 0 || !this.state.packageSlip || !this.state.customerName || !this.state.soldToAddress }
                      type="submit"
                      id="btnSubmit"
                      className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                    >{t('dashboard_main.save')}
                    </button>
                    <NotificationContainer/>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
          <Row>
            <ReactTableBuilder columns={columns} data={items} maxRows={24}/>
          </Row>
        </div>
      </div>
    );
  }
}

Shipping.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(Shipping);
