import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import { Link } from "react-router-dom";
import Button from '../../components/material/Button';
import { verifyPassword } from "../../actions/userAction";


function ChangePassword (props) {
  const { user } = props.auth;
  const { t } = props;
  const { register, handleSubmit, formState: { errors }, watch } = useForm();

  const onSubmit = (formData, event) => {
    const userData = {
        new: formData.newPassword,
        current: formData.oldPassword,
        userId: user._id
    };
    props.verifyPassword(userData, props);
    event.preventDefault();
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col s8 offset-s2">
          <Link to="/" className="btn-flat waves-effect">
            <i className="material-icons left">keyboard_backspace</i> {t('userprofile.back_to_home')}
          </Link>
          <div className="col s12" style={{ paddingLeft: "11.250px" }}>
            <h4>
              <b>{t('userprofile.password_change')}</b>
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-field col s12">
              <input
                id="oldPassword"
                type="password"
                name="oldPassword"
                {...register('oldPassword', { required: t('input.password_required')})}
              />
              <label htmlFor="oldPassword">{t('input.old_password')}</label>
              {props.errors.errors  && <p className="red-text" >{t('input.old_password_invalid')}</p>}
              {errors.oldPassword  && <p className="red-text" >{errors.oldPassword.message}</p>}
            </div>
            <div className="input-field col s12">
              <input
                id="newPassword"
                type="password"
                name="newPassword"
                {...register('newPassword', { required: t('input.password_required'),
                  pattern: {
                    value:  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}/,
                    message: t('input.password_invalid')
                  }
                })}
              />
              <label htmlFor="newPassword">{t('input.new_password')}</label>
              {errors.newPassword  && <p className="red-text" >{errors.newPassword.message}</p>}
            </div>
            <div className="input-field col s12">
              <input
                id="newPassword2"
                type="password"
                name="newPassword2"
                {...register('newPassword2', { required: t('input.password_required'),
                  pattern: {
                    value:  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}/,
                    message: t('input.password_invalid')
                  },
                  validate: value => value === watch('newPassword') || t('input.password_different')
                })}
              />
              <label htmlFor="newPassword2">{t('input.password_confirm')}</label>
              {errors.newPassword2  && <p className="red-text" >{errors.newPassword2.message}</p>}
            </div>
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <Button text={t('userprofile.save_button')}></Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

ChangePassword.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });
  
  export default compose(
    withTranslation(),
    connect(
      mapStateToProps,
      { verifyPassword }
    )
  )(ChangePassword);