import { GET_ERRORS, SHOW_SUCCESS_MESSAGE } from "./types";
import userApi from "../api/userApi";

export const verifyPassword = (userData, props) => dispatch => {
    return userApi.updateUserPassword(userData)
      .then(res => {
        dispatch({
          type: GET_ERRORS,
          payload: ''
        })
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: props.t('userprofile.password_change_successful')
        });
        props.history.push("/userProfile") // re-direct to the user profile page on successful password update
        return res;
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      return err;
      }
      );
  };