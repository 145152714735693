import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)

  .init({
    fallbackLng: 'en',
    debug: true,
    whitelist: ['fr', 'en', 'zh'], // the only two languages supported by the plateform.
    load: 'languageOnly', // we do not manage the regions for now (eg. 'fr_CA', etc.)

    interpolation: {
      escapeValue: false // not needed for React as it escapes by default
    }

});

export default i18n;
