import React from "react";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";

function DeleteSimAssociation(props)  {
  const { t } = props;

  function close(e) {
    props.onClose(e);
  }

  return(
  <div id="deleteSimCamera" style={{  position:"fixed", width:"450px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"calc(25% - 300px)", marginLeft:" calc(50% - 630px)",zIndex:"999" }} className="container center-align">
  <h3 className="center-align blue-grey darken-1 white-text">
    {t('input.delete_confirmation')}
  </h3>
    <p>
      {t("message.sure_delete_sim") + " " +  props.delSim}
    </p>
    <div className="row " style={{float:"right"}}>
      <button className="btn waves-effect waves-light" type="button" id="ok" onClick={close} style={{marginRight:"35px"}}>{t('ok')}</button>
      <button className="btn waves-effect waves-light" type="button" id="cancel" onClick={close} style={{marginRight:"15px"}}>{t('cancel')}</button>
    </div>
  </div>
  );
};

DeleteSimAssociation.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(DeleteSimAssociation);