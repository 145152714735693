import { prodapiAxiosClient } from "./prodApiClient";

const getRmaInfo = (caseNumber, cb) => {
    return prodapiAxiosClient
        .get('/api/v1/salesForce/rmaInfo/' + caseNumber ).then(res => {
            cb(res.data)
        })
  }

  const getRmaItemsNetsuite = (caseNumber, cb) => {
    return prodapiAxiosClient
        .get('/api/v1/netsuite/rmaInfo/' + caseNumber ).then(res => {
            cb(res.data)
        })
  }

  const putRMA = (data, cb) => {
    return prodapiAxiosClient
        .put('/api/v1/netsuite/rmaInfo/', data).then(res => {
            cb(res.data)
        })
  } 


  const rmaInfoApi = {getRmaInfo, getRmaItemsNetsuite, putRMA}
  export default rmaInfoApi;
