import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";
import { connect } from "react-redux";
import classnames from "classnames";
import ReactToPrint from "react-to-print";
import MasterPackLabel from "../../components/label/masterpack"

import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import cameraModelApi from "../../api/cameraModelApi";
import masterpackApi from "../../api/masterpackApi";

class NewMasterPack extends Component {
  constructor() {
    super();
    this.state = {
      cameraModel: "",
      quantity: 20,
      quantityInPack: "",
      items: [],
      cameraModels: [],
      errors: {},
      barcodeByMasterpack: []
    };

    if (localStorage.cameraModels) {
      try {
        this.state.cameraModels = JSON.parse(localStorage.cameraModels);
      } catch (e) {
      }

    }

    this.onSubmit = this.onSubmit.bind(this);
  };

  loadCamerasModels = e => {
    const { t } = this.props;
    cameraModelApi.getCameraModels()
      .then(res => {
        let models = res.data;
        localStorage.setItem("cameraModels", JSON.stringify(models));
        models = [{ "name": t("global.selectAModel") }, ...models]
        models.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        this.setState({ cameraModels: models })
      })
      .catch(err =>
        localStorage.setItem("cameraModels", JSON.stringify([]))
      );
  }

  componentDidMount() {
    this.loadCamerasModels();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.loadCamerasModels();
  }
  handleAfterPrint = e => {
    this.setState({ items: [] });
  }

  onChange = e => {
    if (e.target.id === "cameraModel") {
      var modelSelected = this.state.cameraModels.find(model => model.name === e.target.value)
      this.setState({ barcodeByMasterpack: modelSelected.barcodeByMasterpack });
    }
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    const { cameraModel, quantity, quantityInPack } = this.state;
    let valid = true;

    let errors = {}

    if (!cameraModel || cameraModel === "") {
      errors.cameraModel = "Camera model is required"
      valid = false;
    }

    if (!quantity || quantity < 0 || quantity > 200) {
      errors.quantity = "Quantity is invalid"
      valid = false;
    }

    if (!quantityInPack || quantityInPack < 0 || quantityInPack > 100) {
      errors.quantityInPack = "Quantity is invalid"
      valid = false;
    }

    this.setState({ errors: errors })

    e.preventDefault();

    this.onChange(e);
    // envoyer à l'api seulement si envoyer est true
    if (valid) {
      masterpackApi.bulkInsertMasterpack(cameraModel, quantity, quantityInPack, this.state.barcodeByMasterpack.find(barcode => barcode.masterpackQuantity == quantityInPack).barcode)
        .then(res => {
          try {
            this.setState({ errors: {}, items: res.data.masterpack })
          } catch (e) {
            console.log(e)
          }
        }) // re-direct to login on successful register
        .catch(err => {
          const errors = err.response ? err.response.data : err;
          this.setState({ errors: errors })
          console.log(err)
        });
    }
  };

  render() {
    const { errors, cameraModel, quantity, quantityInPack,
      items, cameraModels } = this.state;
    const itemsCount = items.length;
    const { t } = this.props;

    //let listCameras = "";
    let listToPrint = items.map((item, index) =>
      <tr key={index}>
        <td>{index + 1} </td>
        <td>{item.barcode}</td>
        <td>{item.cameraModel.name}</td>
        <td>{item.quantityInPack}</td>
      </tr>
    );

    return (
      <div>
        <div style={{ minHeight: "75vh", marginTop: "30px" }} className="container">
          <div className="row valign-wrapper">
            <div className="col s12 center-align">
              <Row>
                <Col s={12} m={3} >
                </Col>
                <Col s={12} m={6} >
                  <h4>{t('dashboard_main.print_masterpack_title')}</h4>
                  <h6>{t('dashboard_main.print_masterpack')}</h6>
                </Col>
              </Row>
              <form noValidate onSubmit={this.onSubmit}>

                <Row>
                  <div className="input-field col s4">
                    <select type='select'
                      label="Camera models"
                      onChange={this.onChange}
                      value={cameraModel}
                      error={errors.cameraModel}
                      id="cameraModel"
                      className={classnames("", {
                        invalid: errors.cameraModel
                      })} >
                      {// eslint-disable-next-line array-callback-return
                      cameraModels.map((item, index) => {
                        if (item.active !== false) {
                          return <option key={index} value={item.name}>{item.name}</option>
                        }
                      })}
                    </select>
                    <span id="cameraModelMessage" className="red-text">
                      {errors.cameraModel}
                    </span>
                  </div>
                  <div className="input-field col s4">
                    <input
                      onChange={this.onChange}
                      value={quantity}
                      error={errors.quantity}
                      id="quantity"
                      type="number"
                      min="1" max="1000"
                      placeholder=""
                      className={classnames("", {
                        invalid: errors.quantity
                      })}
                    />
                    <label htmlFor="quantity" className="active">{t('dashboard_main.quantity_print')}</label>
                    <span id="quantityMessage" className="red-text">
                      {errors.quantity}
                    </span>
                  </div>
                  <div className="input-field col s4">
                  <select type='select'
                        onChange={this.onChange}
                        value={quantityInPack}
                        error={errors.quantityInPack}
                        id="quantityInPack"
                        className={classnames("", {
                        invalid: errors.quantityInPack
                        })} >
                        <option key="0" value=""></option>
                        {  this.state.barcodeByMasterpack && this.state.barcodeByMasterpack.length &&
                         this.state.barcodeByMasterpack.map((item, index) => {
                          return <option key={index + 1} value={item.masterpackQuantity}>{item.masterpackQuantity}</option>
                        })}
                      </select>
                      <span id="quantityInPackMessage" className="red-text">
                        {errors.quantityInPack}
                      </span>
                  </div>
                </Row>

                <br />
                <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                  <button className="btn btn-large waves-effect waves-light col m4 hoverable blue accent-3"
                    style={{
                      width: "250px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem",
                      marginRight: "2px"
                    }}
                    disabled={itemsCount > 0}
                    type="submit"
                  >
                    {t('dashboard_main.generate_pack')}
                  </button>
                  <ReactToPrint
                    trigger={() => <button className="btn btn-large waves-effect waves-light hoverable blue accent-3 col m4 offset-m1"
                      style={{
                        width: "250px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        marginTop: "1rem",
                        marginLeft: "2px"
                      }}
                      disabled={itemsCount < 1}
                      type="button"
                    >
                      {t('dashboard_main.print')}
                    </button>}
                    content={() => {
                      return this.componentRef
                    }}
                    onAfterPrint={this.handleAfterPrint}
                  />

                </div>
              </form>
            </div>
          </div>
          <div className="itemsContent">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>{t('dashboard_main.masterpack_barcode')}</th>
                  <th>{t('dashboard_main.camera_model')}</th>
                  <th>{t('dashboard_main.quantity_pack')}</th>
                </tr>
              </thead>
              <tbody>
                {listToPrint}
              </tbody>
            </table>
          </div>
          <div className="center" ref={el => (this.componentRef = el)}>
            {items.map((item, index) =>
              <MasterPackLabel key={index} mp={item} />
            )
            }
          </div>

        </div>
      </div>
    );
  }
}
NewMasterPack.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  items: state.items
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(NewMasterPack);