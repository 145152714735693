import React, { useState, useEffect } from "react";
import { Col } from "react-materialize";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
import simSupplierApi from "../../../api/simSupplierApi";
import cameraModelApi from "../../../api/cameraModelApi";

function EditSimOptions(props) {
  const { t } = props;
  const [simOptions, setSimOptions] = useState([])
  const [simOptionsLinked, setSimOptionsLinked] = useState(props.simOptions || [])

  useEffect(() => {
    simSupplierApi.getSimSuppliers()
    .then(res => {
      let simOptionsArray = []
      res.data.forEach(simSupplier => {
          if(!simOptionsLinked.includes(simSupplier.prefix)) {
            simOptionsArray.push(simSupplier.prefix)
          }
      });
      setSimOptions(simOptionsArray);
    })
  }, []);

  function addElement() {
    const dropDown = document.getElementById("simOptions")
    const index = simOptions.indexOf(dropDown.value)
    simOptions.splice(index, 1)
    setSimOptions([...simOptions])
    simOptionsLinked.push(dropDown.value)
    setSimOptionsLinked(simOptionsLinked)
  }

  function deleteElement(e) {
    simOptions.push(e.target.id)
    setSimOptions([...simOptions])
    const index = simOptionsLinked.indexOf(e.target.id)
    simOptionsLinked.splice(index, 1)
    setSimOptionsLinked(simOptionsLinked)
  }

  function onSubmit() {
    const editCameraModel = {
      name: props.cameraName, 
      optionsSims: simOptionsLinked
    }
    console.log(editCameraModel)
    cameraModelApi.putCameraModel(editCameraModel)
    .then(res => {
      props.onClose(simOptionsLinked);
    })
    .catch(err => {
      const errors = err.response ? err.response.data : err;
      console.log(errors)
    });
  }

  function cancelButton() {
    props.onClose("cancel");
  }

  function makeTable() {
    return simOptionsLinked.map((simOption, key) =>
      <tr key={key}>
        <td className="center-align" style={{ width: "66.67%" }}>{simOption}</td>
        <td style={{ width: "33.33%" }}><ButtonFactory name={simOption} icon='delete' onClick={deleteElement}/></td>
      </tr>)
  }

  return(
    <div id="editSimOptions" style={{  maxHeight: "95%", position:"fixed", width:"700px", backgroundColor:"#e6e6e6",borderRadius:"7px", zIndex:"999",overflowY:"scroll", top: "40%", left: "50%", transform: "translate(-50%, -50%)" }} className="container center-align">
     <Col s={12} >
      <h3 className="center-align defaultTheme white-text" >
        {t('setting.sim_option_edit')}
      </h3>
     </Col>
     <form onSubmit={onSubmit} name="userForm" id="userForm">
      <table style={{ marginTop: "25px" }} >
        <thead>
          <tr style={{ backgroundColor: "#f2f2f2" }}>
            <td className="center-align">{t('input.prefix')}</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {makeTable()}
          <tr>
              <td style={{width: "66.67%" }}>
                  <select id="simOptions" name="simOptions" className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "12.5%", marginRight: "12.5%", marginBottom: "5px", width: "75%" }}>
                      {simOptions.map((simOption, index) => (
                          <option key={index} value={simOption}>{simOption}</option>
                      ))}
                  </select>
              </td>
              <td style={{width: "33.33%" }}>
                  <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={addElement}/>
              </td>
          </tr>
        </tbody>
      </table>
      <div className="row " style={{ float:"right", }}>
        <ButtonFactory name='Submit' text={t('ok')} onClick={onSubmit} style={{marginRight:"35px"}}/>
        <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{marginRight:"15px"}}/>
      </div>
     </form>
    </div>
  );
}

EditSimOptions.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(EditSimOptions);
