import React from "react";


const LabelTest = (props) => {
  let textBox = props.textBox;
  let {user} = props;

  const createTextBox = () => {
    var html = [];
    textBox.forEach(box => {
        html.push(
            <div class="col s2">
                {box.toTest === false &&
                    <p id="imeiMessage" className="red-text" style={{fontSize: "20px", textAlign: "center", marginBlockStart: "0px", marginBlockEnd: "0px"}}>
                        &#10060; {box.false}
                    </p>
                }
                {box.toTest === true &&
                    <p id="imeiMessage" className="green-text" style={{fontSize: "20px", textAlign: "center", marginBlockStart: "0px", marginBlockEnd: "0px"}}>
                        &#10004; {box.true}
                    </p>
                }
                {box.toTest === 0 &&
                    <p id="imeiMessage" className="red-text" style={{fontSize: "20px", textAlign: "center", marginBlockStart: "0px", marginBlockEnd: "0px"}}>
                        &#10060; {box.false}
                    </p>
                }
                {box.toTest === 1 &&
                    <p id="imeiMessage" className="orange-text" style={{fontSize: "20px", textAlign: "center", marginBlockStart: "0px", marginBlockEnd: "0px"}}>
                        &#10004; {box.false}
                    </p>
                }
                {box.toTest === 2 &&
                    <p id="imeiMessage" className="green-text" style={{fontSize: "20px", textAlign: "center", marginBlockStart: "0px", marginBlockEnd: "0px"}}>
                        &#10004; {box.two}
                    </p>
                }
            </div>
        )
    })
    return html
}

  return (
    createTextBox()
  )
}
export default LabelTest