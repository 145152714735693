import React, { useState, useEffect } from "react";
import { Col } from "react-materialize";
import { useForm } from "react-hook-form";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";

function AddParams(props) {
  const { t } = props;
  const { handleSubmit, formState: { errors } } = useForm()
  const [params, setParams] = useState(props.params)
  const [htmlTable, setHtmlTable] = useState([])
  const [render, setRender] = useState(false)
  const [isDate, setIsDate] = useState(false)
  const type = ["text", "date"]
  const dateType = ["dateFrom", "dateTo"]
  
  useEffect(() => {
    const selectElement = document.getElementById('type');
    const handleChange = () => { setIsDate(!isDate) }
    selectElement.addEventListener('change', handleChange)
    return () => {
      selectElement.addEventListener('change', handleChange)
    }
  }, [isDate])

  function addElement() {
    const name = document.getElementById(isDate ? "date" : "name")
    const type = document.getElementById("type")
    params.push({ name: name.value, type: type.value })
    setParams(params)
    setRender(!render)
  }

  function deleteElement(e) {
    const index = params.findIndex(param => param.name === e.target.id)
    params.splice(index, 1)
    setParams(params)
    setRender(!render)
  }

  function onSubmit() {
    props.onClose(params)
  }

    useEffect(() => {
        let html = []
        params.forEach((param, key) => {
            html.push(
                <tr key={key}>
                    <td className="center-align">{param.name}</td>
                    <td className="center-align">{param.type}</td>
                    <td><ButtonFactory name={param.name} icon='delete' onClick={deleteElement}/></td>
                </tr>
            )
        });
        setHtmlTable(html)
    }, [params, render]);

    return(
      <div id="addParam" style={{  maxHeight: "95%", position:"fixed", width:"700px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"90px", marginLeft:"200px", zIndex:"999",overflowY:"scroll" }} className="container center-align"   >
       <Col s={12} >
        <h3 className="center-align defaultTheme white-text" >
          {t('setting.reporting_add_param')}
        </h3>
       </Col>
       <form onSubmit={handleSubmit(onSubmit)} name="userForm" id="userForm">
       <table style={{ marginTop: "25px" }} >
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2" }}>
              <td className="center-align">{t('input.name')}</td>
              <td className="center-align">{t('inventory.shipping.type')}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {htmlTable}
            <tr>
              {!isDate &&
                <td>
                  <input id="name" type="text" name="name" className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
                </td>
              }
              {isDate &&
                <td>    
                  <select id="date" name="date" className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "12.5%", marginRight: "12.5%", marginBottom: "5px", width: "75%" }}>
                  {dateType.map(type => (
                  <option key={type} value={type}>{type}</option>
                  ))}
                  </select>
                </td>
              }
                <td>    
                    <select id="type" name="type" className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "12.5%", marginRight: "12.5%", marginBottom: "5px", width: "75%" }}>
                    {type.map(type => (
                    <option key={type} value={type}>{type}</option>
                    ))}
                    </select>
                </td>
                <td>
                    <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={addElement} />
                </td>
            </tr>
          </tbody>
        </table>
         <hr/>
         <div className="row " style={{float:"right"}}>
          <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
        </div>
       </form>
      </div>
    );
}

AddParams.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(AddParams);
