import React, { useEffect, useState  } from "react";
import { Col, Row } from "react-materialize";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import EditPlant from "./form/editPlant";
import CreatePlant from "./form/createPlant";
import DeletePlant from "./form/deletePlant";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import plantApi from "../../api/plantApi";
import ButtonFactory from "../../components/material/ButtonFactory";

function Plant (props) {
    const { t } = props;

    const [plants, setPLants] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showDel, setShowDel] = useState(false);
    const [idClicked, setIdClicked] = useState("");
    const [editInfo, setEditInfo] = useState();

    useEffect(() => {
        plantApi.getPlants()
        .then(res => {
            setPLants(res.data);
        })
        .catch(err =>
            console.log(err),
        );
    }, [showEdit,showDel,showAdd])

    function showEditWindow (e) {
        setIdClicked(e.target.id);
        plantApi.getPlant(e.target.id)
        .then(res => {
          setEditInfo(res.data);
          setShowEdit(!showEdit);
        })
        .catch(err =>
          console.log(err),
        );
    };
    function showDeleteWindow(e) {
        setIdClicked(e.target.id );
        plantApi.getPlant(e.target.id)
        .then(res => {
          setEditInfo(res.data);
          setShowDel(!showDel);
        })
        .catch(err =>
          console.log(err),
        );
    }
    function showAddWindow() {
        setShowAdd(!showAdd);
    }

    function handleDelClose() {
        setShowDel(false);
    }
    function handleAddClose() {
        setShowAdd(false);
    }
    function handleEditClose() {
        setShowEdit(false);
    }
    
    function makeListPlants() {
        return plants.map((item, key) =>
            <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.country}</td>
                <td>{item.city}</td>
                <td>{new Date(item.startActivity).toLocaleString()}</td>
                <td><ButtonFactory name={item.id} icon='edit' onClick={showEditWindow}/></td>
                <td><ButtonFactory name={item.id} icon='delete' onClick={showDeleteWindow}/></td>
            </tr>
        );
    }
        return (

            <div style={{ minHeight: "75vh", marginTop: "30px" }} className="container">
                {showEdit &&
                    <EditPlant
                        plantID={idClicked}
                        onClose={handleEditClose}
                        plant={editInfo}
                    />
                }
                {showAdd &&
                    <CreatePlant 
                        onClose={handleAddClose}
                    />
                }
                {showDel &&
                    <DeletePlant
                        Plant_id={idClicked}
                        onClose={handleDelClose}
                        plant={editInfo}
                    />
                }
                <Row>
                    <Col s={1}>
                        <div className="row">
                            <ButtonFactory  href="#/settings" icon='arrow_back'/>
                        </div>
                    </Col>
                    <Col s={12} m={2} >
                    </Col>
                    <Col s={12} m={6}>
                        <h4>{t('setting.plant_maintenance')}</h4>
                    </Col>
                </Row>
                <div>
                    <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={showAddWindow}/>
                </div>
                <table style={{ marginTop: "25px" }} >
                    <thead>
                        <tr style={{ backgroundColor: "#f2f2f2" }}>
                            <td>{t('plant.plant_name')}</td>
                            <td>{t('plant.plant_country')}</td>
                            <td>{t('plant.plant_city')}</td>
                            <td>{t('plant.plant_start_activity')}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {makeListPlants()}
                    </tbody>
                </table>
            </div>

        );
    };

Plant.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default compose(
    withTranslation(),
    connect(
        mapStateToProps,
    )
)(Plant);