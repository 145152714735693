import React from "react";

function Button(props) {
    return (
        <button
            style={{
                borderRadius: "3px",
                letterSpacing: "1.5px",
                marginTop: "1rem"
            }}
            type="submit"
            className="btn btn-large waves-effect waves-light hoverable blue accent-3"
        >
        {props.text}
    </button>
    );
}
export default Button;