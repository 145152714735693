import {
  GET_ERRORS,
  SET_SEARCH_RETURN
} from "./types";
import searchApi from "../api/searchApi";

export const searchQuery = (searchData, history) => dispatch => {

  searchApi.search(searchData)
    .then(res => {
      const { data } = res;
      dispatch(setSearchQuery(data));
    }) // re-direct to login on successful register
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


// Set logged in user
export const setSearchQuery = result => {
  return {
    type: SET_SEARCH_RETURN,
    payload: result
  };
};
