import { prodlocalAxiosClient } from "../api/prodApiClient";

const axiosConfig = {
  headers: {
    'Authorization': 'QzXxbWMmWi3vdAXFEOMMO7HNQGuHOVsd8epw0ilkFEn8Lj7lyfAUzAynDu5qVsH6yHBEbuEROhSAKe4XHutbs8jyOkM86PKg'
  }
}

export function getLogTxt(sdCardLetter, cb) {
  prodlocalAxiosClient
  .post("http://localhost:8888/api/sd/getLogTxt", {sdCard : sdCardLetter}, axiosConfig)
  .then(res => cb(res))
  .catch(err => {
    cb(err)
  });
}

export function getAllDiskLetter(cb) {
  prodlocalAxiosClient
  .get("http://localhost:8888/api/sd/getLetter", axiosConfig)
  .then(res => cb(res))
  .catch(err => {
    console.log(err);
    cb(null, err)
  });
}
export function getLastPhotoDay(sdCardLetter, cb) {
  prodlocalAxiosClient
  .post("http://localhost:8888/api/sd/getLastPhotoDay", {sdCard : sdCardLetter}, axiosConfig)
  .then(res => cb(res))
  .catch(err => {
    console.log(err);
    cb(err, null)
  });
}

export function getLastPhotoNight(sdCardLetter, cb) {
  prodlocalAxiosClient
  .post("http://localhost:8888/api/sd/getLastPhotoNight", {sdCard : sdCardLetter}, axiosConfig)
  .then(res => cb(res))
  .catch(err => {
    console.log(err);
    cb(err, null)
  });
}

export function sdFormat(data, cb) {
  prodlocalAxiosClient
  .post("http://localhost:8888/api/sd/format", {sdCard : data}, axiosConfig)
  .then(res => cb(res))
  .catch(err => {
    console.log(err);
    cb(err, null)
  });
}

export function isLocalPrinterReady(cb) {
  prodlocalAxiosClient
    .get("http://localhost:8888/api/print/isready")
    .then(res => cb(null, res))
    .catch(err => {
      console.log(err);
      cb(err, null)
    });
};


export function printDatamatrixFull(data, cb) {
  prodlocalAxiosClient
    .post("http://localhost:8888/api/print/datamatrix/full", data, axiosConfig)
    .then(res => cb(null, res))
    .catch(err => {
      cb(err, null)
    });
}

export function printDatamatrixSerialNumber(data, cb) {
  prodlocalAxiosClient
    .post("http://localhost:8888/api/print/datamatrix/serialnumber", data, axiosConfig)
    .then(res => cb(null, res))
    .catch(err => {
      console.log(err);
      cb(err, null)
    });
}

export function printBarCode(data, cb) {
  prodlocalAxiosClient
  .post("http://localhost:8888/api/print/datamatrix/barcode", data, axiosConfig)
  .then(res => cb(null, res))
    .catch(err => {
      console.log(err);
      cb(err, null)
    });
}

export function printDatamatrixSim(data, cb) {
  prodlocalAxiosClient
  .post("http://localhost:8888/api/print/datamatrix/simdatamatrix", data, axiosConfig)
  .then(res => cb(null, res))
    .catch(err => {
      console.log(err);
      cb(err, null)
    });
}

export function printBarCodeBatch(data, cb) {
  prodlocalAxiosClient
  .post("http://localhost:8888/api/print/datamatrix/barcodebatch", data, axiosConfig)
  .then(res => cb(null, res))
    .catch(err => {
      console.log(err);
      cb(err, null)
    });
}

export function getProductionTesting(data, cb) {
  prodlocalAxiosClient
    .get("http://localhost:8888/api/productionTesting", axiosConfig)
      .then(res => { cb(res.data) })
        .catch(err => {
          console.log(err);
          cb(err, null)
    });
};

export function isLocalSDCardReady(cb) {
  prodlocalAxiosClient
  .get("http://localhost:8888/api/productionTesting/isready", axiosConfig)
    .then(res => cb(null, res))
    .catch(err => {
      cb(err, null)
    });
}


export function fwUpdateFlash(cb) {
  prodlocalAxiosClient
  .post("http://localhost:8888/api/fwUpdate/V300/flash", {}, axiosConfig)
    .then(function(res) {
      console.log(res)
      cb(null, res)
    })
    .catch(err => {
      cb(err, null)
    });
}

export function fwUpdateFlashStatus(requestId, cb) {
  prodlocalAxiosClient
  .get(`http://localhost:8888/api/fwUpdate/V300/flashStatus?requestId=${requestId}`, axiosConfig)
    .then(res => cb(null, res))
    .catch(err => {
      cb(err, null)
    });
}

export function startLoopFastBoot(cb) {
  prodlocalAxiosClient
  .post("http://localhost:8888/api/fwUpdate/loopFastboot/start", {}, axiosConfig)
    .then(function(res) {
      console.log(res)
      cb(null, res)
    })
    .catch(err => {
      cb(err, null)
    });
}

export function stopLoopFastBoot(cb) {
  prodlocalAxiosClient
  .post("http://localhost:8888/api/fwUpdate/loopFastboot/stop", {}, axiosConfig)
    .then(function(res) {
      console.log(res)
      cb(null, res)
    })
    .catch(err => {
      cb(err, null)
    });
}

export function getStatusFastBootLoop(cb) {
  prodlocalAxiosClient
  .get("http://localhost:8888/api/fwUpdate/status", {}, axiosConfig)
    .then(function(res) {
      console.log(res)
      cb(null, res)
    })
    .catch(err => {
      cb(err, null)
    });
}