import React, { useEffect, useState } from "react";
import { Row, Col } from "react-materialize";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import EditUser from "./form/editUser";
import CreateUser from "./form/createUser";
import EditPassword from "./form/editPassword";
import ButtonFactory from "../../components/material/ButtonFactory";
import userApi from "../../api/userApi";

function User (props) {
  const { t } = props;

  const [users, setUsers] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [idClicked, setIdClicked] = useState("");
  const [editInfo, setEditInfo] = useState();

   useEffect(() => {
    userApi.getUsers()
    .then(res => {
      setUsers(res.data);
    })
    .catch(
    );
  }, [showAdd, showEdit, showPass]);

  function showEditWindow (e) {
    setIdClicked(e.target.id);
    userApi.getUser(e.target.id)
    .then(res => {
      setEditInfo(res.data);
      setShowEdit(!showEdit);
    })
    .catch(err =>
      console.log("error : " + err)
    );
  };

  function showAddWindow() {
    setShowAdd(!showAdd);
  }

  function showChangePasswordWindow(e) {
    setIdClicked(e.target.id);
    setShowPass(!showPass);
  }

  function handleAddClose() {
    setShowAdd(false);
  }
  function handleEditClose() {
    setShowEdit(false);
  }
  function handleEditPasswordClose() {
    setShowPass(false);
  }

  function makeTable() {
    return users.map((user, key) =>
    <tr key={user.id}>
      <td><i id={user.id} className="material-icons">{user.isActive ? "radio_button_checked" : "radio_button_unchecked"}</i></td>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>{user.role}</td>
      <td><ButtonFactory name={user.id} icon='edit' onClick={showEditWindow}/></td>
      <td><ButtonFactory name={user.id} icon='lock' onClick={showChangePasswordWindow}/></td>
    </tr>)
  }
    return (
      <div>
        <div>
          <div style={{ marginTop: "30px"}} className="container">
            {showEdit &&
              <EditUser
                userId={idClicked}
                user={editInfo}
                onClose={handleEditClose}
              />
            }
            {showAdd &&
              <CreateUser 
                onClose={handleAddClose}
              />
            }
            {showPass &&
              <EditPassword
                userId={idClicked}
                users={users}
                onClose={handleEditPasswordClose}
              />
            }
            <Row>
              <Col s={1}>
                <div className="row">
                  	<ButtonFactory href="#/settings" icon='arrow_back' />
                </div>
              </Col>
              <Col s={12} m={2} >
              </Col>
              <Col s={12} m={6} >
                <h4> {t('setting.user_maintenance')}</h4>
              </Col>
            </Row>
            <div>
              <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={showAddWindow}/>
            </div>
            <table style={{ marginTop: "25px" }} >
              <thead>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td>{t('setting.user_active')}</td>
                  <td>{t('setting.user_name')}</td>
                  <td>{t('setting.user_email')}</td>
                  <td>{t('setting.user_role')}</td>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {makeTable()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );       
}

User.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(User);
