import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
/**
 * HOC that Handles whether or not the user is allowed to see the page.
 * @param {array} allowedRoles - user roles that are allowed to see the page.
 * @returns {Component}
 */
export default function Authorization(allowedRoles) {
  return WrappedComponent => {
    class WithAuthorization extends Component {
        static propTypes = {
          auth: PropTypes.object.isRequired
        };
        
        render() {
          const { user } = this.props.auth;
          if (allowedRoles.includes(user.role)) {
            return <WrappedComponent {...this.props} />;
          } else {
            return <h3>You are not allowed to see this page !</h3>;
          }
        }
      };

      const mapStateToProps = state => ({
        auth: state.auth,
        errors: state.errors
      });
      return connect(
        mapStateToProps
      )(WithAuthorization);
    };
};
