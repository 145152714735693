import { prodapiAxiosClient } from "./prodApiClient";

 const newCamera = (newCam) => {    
    return prodapiAxiosClient
    .post("/api/v1/camera/", newCam);
}

const getCamera = (cameraId) => {
    return prodapiAxiosClient
    .get(`/api/v1/camera/` + cameraId)
}
const getLastXCamera = (numberOfCamera) => {
    if (!numberOfCamera) {
        numberOfCamera = 10;
    }
    return prodapiAxiosClient
    .get("/api/v1/camera/dailyByUser?limit=" + numberOfCamera)
}

const putCameraAssignSim = (camId, data) => {
    console.log(data)
    return prodapiAxiosClient
    .put("api/v1/camera/assignsim/" + camId, data)
}

const putCamera = (camId, camInfo) => {
    return prodapiAxiosClient
    .post("api/v1/camera/" + camId, camInfo)
}

const putCameraFootprint = (camId, newFootprint) => {
    return prodapiAxiosClient
    .put(`/api/v1/camera/footprint/`+ camId, newFootprint)
}

const getAllCountCamera = () => {
    return prodapiAxiosClient
    .get("/api/v1/camera/cameraModels/count");
}

const getAllCountCameraModel = () => {
    return prodapiAxiosClient
    .get("/api/v1/camera/dailyByUser/byModel");
}




const cameraApi = {newCamera, getLastXCamera, getCamera, putCameraFootprint, putCameraAssignSim, getAllCountCamera, getAllCountCameraModel, putCamera}
export default cameraApi;