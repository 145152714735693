import React from "react";
import { Col } from "react-materialize";
import { useForm } from "react-hook-form";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
import productionTestingApi from "../../../api/productTestingApi";

function CreateUser(props) {
  const { t } = props;
  const { register, handleSubmit, formState: { errors } } = useForm()

  function cancelButton(e) {
    props.onClose(e);
  }

  function onSubmit(data) {
    const testData = {
      name : data.name,
      version: data.version,
      regex: [],
      cameraModels: []
    };

    productionTestingApi.createProductionTesting(testData)
      .then( res => {
        props.onClose();
      })
      .catch(err => {
        throw err
    });
  }

    return(
      <div id="addTest" style={{  maxHeight: "95%", position:"fixed", width:"550px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"-95px", marginLeft:" calc(50% - 730px)", zIndex:"999",overflowY:"scroll" }} className="container center-align"   >
       <Col s={12} >
        <h3 className="center-align defaultTheme white-text" >
          {t('setting.testQC_add')}
        </h3>
       </Col>
       <form onSubmit={handleSubmit(onSubmit)} name="userForm" id="userForm">
       <Col m={4} s={12}>
          <label className="active" htmlFor="name" style={{ width: "300px", display: "inline-block" }} >{t('input.name')}</label>
          <input type="text" name="name" {...register("name", { required:  t('input.name_required'), pattern: {value: /^.{2,30}$/, message: t('input.name_invalid')} })} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.name && <p className="red-text" >{errors.name.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="version" style={{ width: "300px", display: "inline-block" }} >{t('setting.version')}</label>
          <input type="text" name="version" {...register('version', {required: t('setting.version_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.version && <p className="red-text" >{errors.version.message}</p>}
        </Col>
         <hr/>
         <div className="row " style={{float:"right"}}>
          <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
          <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{marginRight:"15px"}}/>
        </div>
       </form>
      </div>
    );
}

CreateUser.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(CreateUser);
