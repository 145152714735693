import React, { useEffect, useState } from "react";
import { Row, Col } from "react-materialize";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EditSimSupplier from "./form/editSimSupplier";
import ButtonFactory from "../../components/material/ButtonFactory";
import simSupplierApi from "../../api/simSupplierApi";
import AddSimSupplier from "./form/addSimSupplier";

function ManageSimSuppliers (props) {
  const { t } = props;
  const [simSuppliers, setSimSuppliers] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [render, setRender] = useState(false)
  const [idClicked, setIdClicked] = useState("");
  const [editInfo, setEditInfo] = useState();

  useEffect(() => {
    simSupplierApi.getSimSuppliers()
    .then(res => {
      setSimSuppliers(res.data);
    })
  }, [showAdd, showEdit, render]);

  function changeActive(e) {
    const simSupplier = simSuppliers.find(simSupplier => simSupplier.id === e.target.id)
    simSupplier.isActive = !simSupplier.isActive
    simSupplierApi.editSimSupplier(simSupplier, e.target.id)
    .then(() => {
      setRender(!render)
    }).catch((error) => {
        console.log(error)
    });
  }

  function showEditWindow (e) {
    setIdClicked(e.target.id);
    setShowEdit(!showEdit);
    const simSupplier = simSuppliers.find(simSupplier => simSupplier.id === e.target.id)
    setEditInfo(simSupplier)
  };

  function showAddWindow() {
    setShowAdd(!showAdd);
  }

  function handleAddClose() {
    setShowAdd(false);
  }

  function handleEditClose() {
    setShowEdit(false);
  }

  function makeTable() {
    return simSuppliers.map((simSupplier, key) =>
    <tr key={simSupplier.id}>
      <td><i id={simSupplier.id} className="material-icons">{simSupplier.isActive ? "radio_button_checked" : "radio_button_unchecked"}</i></td>
      <td>{simSupplier.letter}</td>
      <td>{simSupplier.name}</td>
      <td>{simSupplier.prefix}</td>
      <td><ButtonFactory name={simSupplier.id} icon='edit' onClick={showEditWindow}/></td>
      <td><ButtonFactory name={simSupplier.id} icon={simSupplier.isActive ? "radio_button_checked" : "radio_button_unchecked"} onClick={changeActive}/></td>
    </tr>)
  }
    return (
      <div>
        <div>
          <div style={{ marginTop: "30px"}} className="container">
            {showEdit &&
              <EditSimSupplier
                simSupplierId={idClicked}
                simSupplier={editInfo}
                onClose={handleEditClose}
              />
            }
            {showAdd &&
              <AddSimSupplier 
                onClose={handleAddClose}
              />
            }
            <Row>
              <Col s={1}>
                <div className="row">
                  	<ButtonFactory href="#/settings" icon='arrow_back' />
                </div>
              </Col>
              <Col s={12} m={2} >
              </Col>
              <Col s={12} m={6} >
                <h4>{t('setting.sim_supplier_maintenance')}</h4>
              </Col>
            </Row>
            <div>
              <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={showAddWindow}/>
            </div>
            <table style={{ marginTop: "25px" }} >
              <thead>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td>{t('setting.user_active')}</td>
                  <td>{t('input.letter')}</td>
                  <td>{t('input.name')}</td>
                  <td>{t('input.prefix')}</td>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {makeTable()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );       
}

ManageSimSuppliers.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(ManageSimSuppliers);
