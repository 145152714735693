import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";
import { productImeiValidation, isValidModemInformation } from '../../utils/validation';
import { getSite } from '../../utils/site';
import { isLocalPrinterReady, printDatamatrixFull } from '../../utils/prodlocalhelper';
import { formatActivationCode } from '../../utils/formatHelper';

import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import productApi from "../../api/productApi";
import cameraModelApi from "../../api/cameraModelApi";
import cameraApi from "../../api/cameraApi";
import plantApi from "../../api/plantApi"

import jwt_decode from "jwt-decode";
import ReactTableBuilder from "../../utils/reactTableBuilder";

const handleFocus = (event) => event.target.select();

class newProduct extends Component {
  constructor() {
    super();

    this.state = {
      cameraModel: "",
      cameraModels: [],
      cameraModelsCount: [],
      modelInformation: "",
      modemDatamatrix: "",
      scanner: "",
      imei: "",
      printerStatus: "Loading...",
      printerName: "",
      cameras: [],
      cameraCount: 0,
      errors: {},
      numberOfCopies: 2
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    if (window.performance) {
      if (performance.navigation.type === 1) {
        this.refresh = true;
      } else {
        this.refresh = false;
      }
    }

  };

  componentDidMount() {

    const { t } = this.props;
    this.setState({
      cameraModel: t("global.selectAModel")
    })

    this.loadCamerasModels();
    this.loadLast10Cameras();
    this.getNumberOfCopies();

    isLocalPrinterReady((err, res) => {
      if (err) {
        let errors = { printer: err }
        this.setState({
          printerStatus: "ERROR",
          printerName: t("dashboard_main.local_printer_not_ready"),
          errors: errors
        })
      } else {
        this.setState({
          printerStatus: res.data.status,
          printerName: res.data.name
        })
      }
    });
    this.quantityCameraCount();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    var states = {
      [e.target.id]: e.target.value
    }

    if (e.target.id === 'cameraModel') {
      var { cameraModels } = this.state;
      var currentModel = cameraModels.find(i => i.name === e.target.value)
      var currentFW = currentModel.versions.find(i => i.stage === 'default')

      var modelInformation = `${currentModel.masterPackDescription}\nCurrent FW version : ${currentFW.name}\nRelease date : ${new Date(currentFW.date).toLocaleString()}\nGTIN : ${currentModel.barcode}`
      states = { ...states, modelInformation: modelInformation }
    }
    this.setState(states);
  };

  onChangeNumbersCopies = e => {
    var states = {
      [e.target.id]: parseInt(e.target.value)
    }
    this.setState(states);
  };

  onSubmit = e => {
    const { t } = this.props;

    if (this.state.imei) {
      const newProduct = {
        imei: this.state.imei,
        cameraModel: this.state.cameraModel,
        modemDatamatrix: this.state.modemDatamatrix
      };

      const validation = productImeiValidation(newProduct, t);

      this.setState({ errors: validation });

      e.preventDefault();

      this.onChange(e);
      // envoyer à l'api seulement si envoyer est true
      if (!validation || validation.valid) {
        productApi.newProduct(newProduct)
          .then(res => {
            this.setState({ imei: "", modemDatamatrix: "", scanner: "" })
            this.printDatamatrix(res.data);
            this.loadLast10Cameras();
            this.quantityCameraCount();
          })
          .catch(err => {
            const errors = err.response.data
            if (err.response.status === 409) {
              errors.valid = false;
              errors.scanner = t('This product already in inventory')
            }

            this.setState({ errors: errors })
          });
      }
    }
  };

  handleKeyPress = e => {
    this.setState({ errors: {} });
    if (e.key === 'Enter' && e.target.value !== "") {
      if (e.target.value === "GG-ENTER") {
        this.setState({ scanner: "" });
        this.onSubmit(e);

      } else if (e.target.value === "GG-CLEAR") {
        this.setState({
          sim: "",
          scanner: ""
        });
      } else if (/^GG-L.+$/.test(e.target.value)) {
        this.setState({
          cameraModel: e.target.value.replace("GG-", ""),
          scanner: ""
        });
      } else if (isValidModemInformation(e.target.value)) {
        this.setState({
          modemDatamatrix: e.target.value.replace(/;/g, "\n"),
          imei: e.target.value.split(/,|;/)[0],
          scanner: ""
        });
      } else {
        this.setState({ errors: { scanner: "Scan not recognized" } })
        e.target.select();
      }
    }
  };

  loadLast10Cameras = e => {
    cameraApi.getLastXCamera(10)
      .then(res => {
        this.setState({ cameras: res.data.cameras })
      })
      .catch(err =>
        localStorage.setItem("cameras", JSON.stringify([]))
      );
  };

  getNumberOfCopies = () => {
    const decoded = jwt_decode(localStorage.getItem("jwtToken"));
    plantApi.getPlant(decoded.user.plant).then(res => {
      if (res.data) {
        if ( res.data.numberOfCopies > 0 &&  res.data.numberOfCopies < 3) {
          this.setState({ numberOfCopies: res.data.numberOfCopies })
        }
      }
    })
  }

  loadCamerasModels = e => {
    const { t } = this.props;
    cameraModelApi.getCameraModels()
      .then(res => {
        let models = res.data;
        localStorage.setItem("cameraModels", JSON.stringify(models));
        models =  models.filter(cm => {return !cm.masterPackOnly && cm.active})
        models = [{ "name": t("global.selectAModel") }, ...models]
        models.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        this.setState({ cameraModels: models })
      })
      .catch(err =>
        localStorage.setItem("cameraModels", JSON.stringify([]))
      );
  }

  printDatamatrix = async (product) => {
    var model = await cameraModelApi.getCameraModel(product.cameraModel)
    const data = {
      datamatrix: `${product.activationCode},${product.imei},${product.serialNumber},${model.data.commonName}`.padEnd(45, '-'),
      activationCode: formatActivationCode(product.activationCode),
      serialNumber: product.serialNumber,
      numberOfCopies: this.state.numberOfCopies
    }

    printDatamatrixFull(data, (err, res) => {
      if (err) {
        let errors = { printer: err.message }
        this.setState({
          printerStatus: "ERROR",
          errors: errors
        })
      }
    });
  }

  createAllCount = () => {
    let dashboard = [];
    var { cameraModelsCount } = this.state;
    for (let y = 0; y < cameraModelsCount.length; y++) {
      if (cameraModelsCount[y].count > 0) {
        dashboard.push(
          <Col md="auto" >
            <span style={{ fontSize: 50 }}>{cameraModelsCount[y].count}</span><br></br>
            <span style={{ fontSize: 20 }}> {cameraModelsCount[y].name} </span>
          </Col>
        )
      }
    }
    return dashboard
  }

  quantityCameraCount = () => {
    let cameraModelsCount = [];
    let cameraCountTotal = 0;
    cameraApi.getAllCountCameraModel().then(resp => {
      for (let i = 0; i < resp.data.cams.length; i++) {
        if (!resp.data.cams[i]._id) {
          resp.data.cams[i]._id = "null";
        }
        cameraCountTotal = cameraCountTotal + resp.data.cams[i].count;
        cameraModelsCount.push({ name: resp.data.cams[i]._id, count: resp.data.cams[i].count })
      }
      cameraModelsCount.sort((a, b) => (a.count > b.count) ? -1 : 1);
      this.setState({ cameraModelsCount: cameraModelsCount, cameraCount: cameraCountTotal });
    })
  }

  render() {
    const { errors, cameraModels, cameras, cameraCount } = this.state;
    const { printerStatus, printerName } = this.state;
    const { cameraModel, modelInformation, modemDatamatrix } = this.state;
    const { t } = this.props;

    const addLineBreaks = string =>
      string.split('\n').map((text, index) => (
        <React.Fragment key={text + "-" + index}>
          {text}
          <br />
        </React.Fragment>
      ));

      const numberOfCopiesPossible = [{name : 1, value : 1} , { name : 2, value : 2}]

    const columns = [
      {
        Header: t('dashboard_main.camera_model'),
        accessor: 'cameraModel',
      },
      {
        Header: t('inventory.newproduct.activationcode'),
        accessor: row => row.activationCode ? formatActivationCode(row.activationCode) : ""
      },
      {
        Header: 'IMEI',
        accessor: 'imei',
      },
      {
        Header: t('dashboard_main.serialNumber'),
        accessor: 'serialNumber',
      },
      {
        Header: 'FW Version',
        accessor: 'firmwareVersion.name',
      },
      {
        Header: t('dashboard_main.assembledDate'),
        accessor: row => row.footprints && row.footprints.date ? new Date(row.footprints.date).toLocaleString(navigator.language, { hour12: false }).replace(/,/g, '') : ""
      },
    ];
    
    return (
      <div>
        <div style={{ marginTop: "30px" }} className="container">
          <div className="row valign-wrapper">
            <div className="col s12 center-align">
              <form noValidate onSubmit={this.onSubmit}>
                <Row>
                  <Col s={12} m={3} >
                  </Col>
                  <Col s={12} m={6} >
                    <h4>{t('dashboard_main.new_product')}</h4>
                    <h6>{t('dashboard_main.new_product_desc')}</h6>
                  </Col>
                  <Col s={12} m={3} >
                    <div className="input-field  right">
                      <i className={classnames("large material-icons", {
                        "green-text": printerStatus === "ready",
                        "red-text": printerStatus !== "ready"
                      })}>print</i>
                      <div>{printerName}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col s={12} m={4}>
                    <div className="input-field">
                      <select type='select'
                        label="Camera models"
                        onChange={this.onChange}
                        value={cameraModel}
                        error={errors.cameraModel}
                        id="cameraModel"
                        className={classnames("", {
                          invalid: errors.cameraModel
                        })} >
                        {// eslint-disable-next-line array-callback-return
                         cameraModels.map((item, index) => {
                          if (item.active !== false) {
                            if (index === 0) {
                              return <option key={index} value={item.name} disabled>{item.name}</option>
                            } else {
                              return <option key={index} value={item.name} >{item.name}</option>
                            }
                          }
                        })}
                      </select>
                      <span id="cameraModelMessage" className="red-text">
                        {errors.cameraModel}
                      </span>
                    </div>
                  </Col>
                  <Col s={12} m={5} >
                    <div className="input-field">
                      <input
                        onChange={this.onChange}
                        onFocus={handleFocus}
                        onKeyPress={this.handleKeyPress}
                        value={this.state.scanner}
                        error={errors.scanner}
                        id="scanner"
                        type="text"
                        className={classnames("", {
                          invalid: errors.scanner
                        })}
                      />
                      <label htmlFor="scanner">{t('dashboard_main.scanner')}</label>
                      <span id="scannerMessage" className="red-text">
                        {errors.scanner}
                      </span>
                    </div>
                  </Col>
                  <Col s={12} m={3}>
                    <div className="input-field">
                      <select type='select'
                        label={t('numberOfCopies')}
                        onChange={this.onChangeNumbersCopies}
                        value={this.state.numberOfCopies}
                        id="numberOfCopies" >
                        {// eslint-disable-next-line array-callback-return
                         numberOfCopiesPossible.map((item, index) => {
                            return <option key={index} value={item.value} > {t('numberOfCopies')} {item.name}</option>
                        })}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col s={12} m={4} >
                    <div className="infoCenter" id='modelInformation'>
                      <p>{addLineBreaks(modelInformation)}</p>
                    </div>

                  </Col>

                  <Col s={12} m={8} >
                    <div className="infoCenter" id='modemDatamatrix'>
                      <p>{addLineBreaks(modemDatamatrix)}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="auto">
                    <span style={{ fontSize: 50 }}>{cameraCount}</span><br></br>
                    <span style={{ fontSize: 20 }}> {t('dashboard_main.count')}</span>
                  </Col>
                  {this.createAllCount()}
                </Row>
              </form>
            </div>

          </div>
          <Row>
            <ReactTableBuilder columns={columns} data={cameras} maxRows={10} />
          </Row>
        </div>
      </div>
    );
  }
}
newProduct.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(newProduct);
