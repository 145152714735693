import { prodapiAxiosClient } from "../api/prodApiClient";

const setAuthToken = token => {
  if (token) {
    // Apply authorization token to every request if logged in
    prodapiAxiosClient.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    // Delete auth header
    delete prodapiAxiosClient.defaults.headers.common["Authorization"];
  }
};
export default setAuthToken;