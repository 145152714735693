import axios from "axios"

function getLocationOrigin () {
    if (typeof window !== "undefined") {
        return window.location.origin
    }
    return ""
}

let prodapi
let prodlocal

if (getLocationOrigin().indexOf('localhost') > 0) {
  prodapi = axios.create({ baseURL : getLocationOrigin().replace("3006", "3000").replace("2","") })
  prodlocal = axios.create({ baseURL : getLocationOrigin().replace("manufacturing", "prodlocal") })
} else {
  prodapi = axios.create({ baseURL : getLocationOrigin().replace("manufacturing", "prodapi").replace("2","") })
  prodlocal = axios.create({ baseURL : getLocationOrigin().replace("manufacturing", "prodlocal") })
}

export const prodapiAxiosClient = prodapi
export const prodlocalAxiosClient = prodlocal
