import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";
import { isLocalPrinterReady, printDatamatrixSerialNumber, printDatamatrixFull, printBarCode, printDatamatrixSim, printBarCodeBatch } from '../../utils/prodlocalhelper';
import { formatActivationCode } from '../../utils/formatHelper';
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import cameraApi from "../../api/cameraApi";
import cameraModelApi from "../../api/cameraModelApi";
import { isValidSim, isValidSerialNumber } from '../../utils/validation';
import { getSite, getProductPageLink } from "../../utils/site";
import { getSimLetter } from '../../utils/simSupplierUtils';
import simSupplierApi from "../../api/simSupplierApi";

const handleFocus = (event) => event.target.select();

class Reprints extends Component {
  constructor() {
    super();
    this.state = {
      scanner: "",
      printerStatus: "Loading...",
      printerName: "",
      errors: {},
      simSuppliers: [],
      camId: "",
      serialNumber: "",
      datamatrix: "",
      modelInformation: "",
      stages: [],
      sticker: "",
      reprintError: "",
      quantity: 1
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

  };

  getSimLetter = sim => {
    return getSimLetter(sim, this.state.simSuppliers);
  }

  loadSimSuppliers = () => {
    simSupplierApi.getSimSuppliers()
      .then(res => {
        this.setState({ simSuppliers: res.data })
      })
      .catch(err =>
        this.setState({ simSuppliers: [] })
      );
  }

  componentDidMount() {
    this.loadSimSuppliers();
    const { t } = this.props;

    isLocalPrinterReady((err, res) => {
      if (err) {
        let errors = { printer: err }
        this.setState({
          printerStatus: "ERROR",
          printerName: t("dashboard_main.local_printer_not_ready"),
          errors: errors
        })
      } else {
        this.setState({
          printerStatus: res.data.status,
          printerName: res.data.name
        })
      }
    });

    this.loadProductStage()

    this.setState({
      sticker: 'none'
    })
  };

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
  };

  loadProductStage = e => {
    const { t } = this.props
    this.setState({
      stages: [{
        'id': 'none',
        'name': t('global.selectPrint')
      },
      {
        'id': 'Full',
        'name': t('input.reprint_full_dt')
      },
      {
        'id': 'serial_dt',
        'name': t('input.reprint_serial_number')
      }
      ]
    })
  };

  handleKeyPress = e => {
    let activationCode = ""
    let imei = ""
    let currentModel = ""
    let serialNumber = ""
    let currentSims = ""
    this.setState({ errors: {}, reprintError: "", errorPrint: "" });
    if (e.key === 'Enter' && e.target.value !== "") {

      if (e.target.id === "scanner") {
        let indice = ""

        indice = e.target.value.split(/,|;/)[0]
        this.setState({ scanner: "", indice: indice })
        if (this.state.sticker !== "sim_barcode_sim" && this.state.sticker !== "sim_datamatrix_sim" && this.state.sticker !== "sn_batch") {


          cameraApi.getCamera(indice)
            .then(async (res) => {
              this.setState({ camId: res.data.id })
              activationCode = res.data.activationCode
              imei = res.data.imei
              serialNumber = res.data.serialNumber
              currentModel = res.data.cameraModel
              currentSims = res.data.sims

              if (this.state.sticker === "none") {
                this.setState({ reprintError: "Select a print option" })
              } else if (this.state.sticker === "Full") {
                var model = await cameraModelApi.getCameraModel(res.data.cameraModel)
                const data = {
                  datamatrix: `${activationCode},${imei},${serialNumber},${model.data.commonName}`.padEnd(45, '-'),
                  activationCode: formatActivationCode(activationCode),
                  serialNumber: serialNumber,
                  numberOfCopies: 1
                }
                printDatamatrixFull(data, (err, res) => {
                  if (err) {
                    let errors = { printer: err.message }
                    this.setState({
                      printerStatus: "ERROR",
                      errors: errors,
                      errorPrint: "Error - Not able to print this option"
                    })

                  }
                });
              } else if (this.state.sticker === "serial_dt") {

                let sim1, sim2
                let indicator = [];

                if (currentSims.length === 1) {
                  sim1 = currentSims[0]
                  indicator.push(this.getSimLetter(sim1));
                } else if (currentSims.length > 1) {
                  sim1 = currentSims[0]
                  sim2 = currentSims[1]
                  indicator.push(this.getSimLetter(sim1));
                  indicator.push(this.getSimLetter(sim2));
                }

                const data = {
                  serialNumber: serialNumber,
                  datamatrix: `${serialNumber},${getProductPageLink()},`.padEnd(45, '-'),
                  simsIndicator: indicator,
                  numberOfCopies: 1
                }
                printDatamatrixSerialNumber(data, (err, res) => {
                  if (err) {
                    let errors = { printer: err.message }
                    this.setState({
                      printerStatus: "ERROR",
                      errors: errors,
                      errorPrint: "Error - Not able to print this option"
                    })
                  }
                });
              }
              else if (this.state.sticker === "sim_barcode") {
                const data = {
                  sim: currentSims,
                  barCode: currentSims,
                  numberOfCopies: 1
                }
                printBarCode(data, (err, res) => {
                  if (err) {
                    let errors = { printer: err.message }
                    this.setState({
                      printerStatus: "ERROR",
                      errors: errors,
                      errorPrint: "Error - Not able to print this option"
                    })
                  }
                });
              }
              else if (this.state.sticker === "sn_barcode") {
                const data = {
                  serialNumber: serialNumber,
                  barCode: serialNumber,
                  numberOfCopies: 1
                }
                printBarCode(data, (err, res) => {
                  if (err) {
                    let errors = { printer: err.message }
                    this.setState({
                      printerStatus: "ERROR",
                      errors: errors,
                      errorPrint: "Error - Not able to print this option"
                    })
                  }
                });
              }
              cameraModelApi.getCameraModel(currentModel)
              .then(res => {
                var currentFW = res.data.versions.find(i => i.stage === 'default')
                var modelInformations = `${res.data.masterPackDescription}\nCurrent FW version : ${currentFW.name}\nRelease date : ${new Date(currentFW.date).toLocaleString()}\nGTIN : ${res.data.barcode}`
                this.setState({ modelInformation: modelInformations, errors: { scanner: "" } })
              })
              .catch(err => {
                this.setState({ errors: { scanner: "Error - not able to find camera Model" } })
                this.setState({ modelInformation: "" })
              })
            })
            .catch(err => {
              this.setState({ errors: { scanner: "Error - not able to find camera" } })
              this.setState({ modelInformation: "" })
            });
        }
        else {
          if (this.state.sticker === "sim_barcode_sim") {
            if (isValidSim(indice)) {
              const data = {
                barCode: [indice],
                sim: indice,
                numberOfCopies: 1
              }
              printBarCode(data, (err, res) => {
                if (err) {
                  let errors = { printer: err.message }
                  this.setState({
                    printerStatus: "ERROR",
                    errors: errors,
                    errorPrint: "Error - Not able to print this option"
                  })
                }
              });
            }
            else {
              this.setState({
                errors: { scanner: "Error - Invalid sim number" }
              })
            }
          }
          else if (this.state.sticker === "sim_datamatrix_sim") {
            if (isValidSim(indice)) {
              const data = {
                datamatrix: `${indice},${getProductPageLink()}`,
                sim: indice,
                numberOfCopies: 1
              }
              printDatamatrixSim(data, (err, res) => {
                if (err) {
                  let errors = { printer: err.message }
                  this.setState({
                    printerStatus: "ERROR",
                    errors: errors,
                    errorPrint: "Error - Not able to print this option"
                  })
                }
              });
            } else {
              this.setState({
                errors: { scanner: "Error - Invalid sim number" }
              })
            }
          }
          else if (this.state.sticker === "sn_batch") {
            if (isValidSerialNumber(indice)) {
              const data = {
                serialNumber: indice,
                barCode: indice,
                numberOfCopies: this.state.quantity
              }
              console.log("data = ")
              console.log(data)
              printBarCodeBatch(data, (err, res) => {
                if (err) {
                  let errors = { printer: err.message }
                  this.setState({
                    printerStatus: "ERROR",
                    errors: errors,
                    errorPrint: "Error - Not able to print this option"
                  })
                }
              });
            }
            else {
              this.setState({
                errors: { scanner: "Error -Serial number invalid" }
              })
            }
          }
        }
      } else {
        this.setState({ errors: { scanner: "Scan not recognized" } })
        e.target.select();
      }
    }
  };



  render() {
    const { errors, sticker, stages } = this.state;
    const { t } = this.props;

    const addLineBreaks = string =>
      string.split('\n').map((text, index) => (
        <React.Fragment key={text + "-" + index}>
          {text}
          <br />
        </React.Fragment>
      ));

    return (
      <div>
        <div style={{ marginTop: "30px" }} className="container">
          <div className="row valign-wrapper">
            <div className="col s12 center-align">
              <Row>
                <Col s={12} m={3} >
                </Col>
                <Col s={12} m={6} >
                  <h4>{t('dashboard_main.print')}</h4>
                  <h6>{t('dashboard_main.reprint_barcode')}</h6>
                </Col>
                <Col s={12} m={3}>
                  <i className={classnames("large material-icons", {
                    "green-text": this.state.printerStatus === "ready",
                    "red-text": this.state.printerStatus !== "ready"
                  })}>print</i>
                  <div>{this.state.printerName}</div>
                </Col>
              </Row>
              <form noValidate onSubmit={this.onSubmit}>
                <Row>
                  <Col s={12} m={4} >
                    <div className="input-field">
                      <select type='select'
                        label="Reprint option"
                        onChange={this.onChange}
                        value={sticker}
                        error={errors.reprint}
                        id="sticker"
                        className={classnames("", {
                          invalid: errors.reprint
                        })} >
                        {stages.map((item, index) => {
                          if (index === 0) {
                            return <option key={index} value={item.id} disabled>{item.name}</option>
                          } else {
                            return <option key={index} value={item.id} >{item.name}</option>
                          }
                        })}
                      </select>
                      <span id="printMessage" className="red-text">
                        {this.state.reprintError}{this.state.errorPrint}
                      </span>
                    </div>
                  </Col>
                  {sticker === "sn_batch" &&
                    <Col s={12} m={4}>
                      <input
                        onChange={this.onChange}
                        onFocus={handleFocus}
                        onKeyPress={this.handleKeyPress}
                        defaultValue={this.state.quantity}
                        error={errors.scanner}
                        id="quantity"
                        type="text"
                      />
                      <label htmlFor="qty">Quantity</label>
                    </Col>
                  }
                </Row>
                <Row>
                  <div className="input-field col s4">
                    <input
                      onChange={this.onChange}
                      onFocus={handleFocus}
                      onKeyPress={this.handleKeyPress}
                      value={this.state.scanner}
                      error={errors.scanner}
                      id="scanner"
                      type="text"
                      className={classnames("", {
                        invalid: errors.scanner
                      })}
                    />
                    <label htmlFor="scanner">Scanner</label>
                    <span id="scannerMessage" className="red-text">
                      {errors.scanner}
                    </span>
                  </div>
                </Row>
                <Row>
                  <Col s={12} m={4} >
                    <div className="infoCenter" id='modelInformation'>
                      {<p>{addLineBreaks(this.state.modelInformation)}</p>}
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Reprints.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(Reprints);
