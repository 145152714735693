import React from "react"

function Conditional(props) {
  return (
    <div>
        <span>
          <cite style={{color:"black"}}>Serial Number : {props.camera.serialNumber}</cite><br></br>
        </span>
        <span>
          <cite style={{color:"black"}}>Sim1 : {props.camera.sims[0]}</cite><br></br>
        </span>
        <span>
          <cite style={{color:"black"}}>Sim2 : {props.camera.sims[1]}</cite>
      </span>
    </div>
  )
}

export default Conditional
