import React, { useEffect } from "react";
import { withTranslation } from 'react-i18next';

import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useForm } from 'react-hook-form';
import Button from '../../components/material/Button';
import { loginUser } from "../../actions/authActions";

function Login (props) {
  const { t } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = formData => {
    const userData = {
      username: formData.username,
      password: formData.password
    };
    props.loginUser(userData);  // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  }


  useEffect(() => {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (props.auth.isAuthenticated) {
      props.history.push("/dashboard");
    }
  });

  return (
    <div className="container">
      <div style={{ marginTop: "4rem" }} className="row">
        <div className="col s8 offset-s2">
          <div className="col s12" style={{ paddingLeft: "11.250px" }}>
            <h4>
              <b>{t('login_title')}</b>
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-field col s12">
              <input
                id="username"
                name="username"
                type="text"
                {...register('username', { required: t('input.email_required'),
                  pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: t('input.email_invalid')
                }})}
              />
              <label htmlFor="username">{t('input.email')}</label>
              {errors.username  && <p className="red-text" >{errors.username.message}</p>}
            </div>
            <div className="input-field col s12">
              <input
                {...register('password', { required: t('input.password_required')})}
                id="password"
                name="password"
                type="password"
              />
              <label htmlFor="password">{t('input.password')}</label>
              {errors.password  && <p className="red-text" >{errors.password.message}</p>}
            </div>
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <Button text= {t('login_action')}></Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    { loginUser }
  )
)(Login);
