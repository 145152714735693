import React from "react";

function ButtonText(props) {

    let className = (props.className) ? props.className : "waves-effect waves-light btn defaultTheme card-link"

    return (
        <button 
            className={className}
            type="button"
            id={props.name}
            onClick={props.onClick}
            style={props.style}
            disabled={props.disabled}
        >
        {props.text}
        </button>
    );
}
export default ButtonText;
