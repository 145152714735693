import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
// import Button from '@mui/material/Button';
import ButtonFactory from '../../material/ButtonFactory';

const ConfirmationModal = ({ open, title, children, onConfirm, onCancel, button1Title, button2Title }) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <ButtonFactory onClick={onConfirm}  color="primary" text={button1Title} />
        <ButtonFactory onClick={onCancel} color="primary" text={button2Title} />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;