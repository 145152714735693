import React from "react";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import cameraModelApi from "../../../api/cameraModelApi";
import ButtonFactory from "../../../components/material/ButtonFactory";

function DeleteCameraModel(props)  {
  const { t } = props;

  function cancelButton(e) {
    props.onClose(e);
  }

  function onSubmit(e) {
    cameraModelApi.deleteCameraModel(props.delCameraModel)
      .then(() => {
        props.onClose(e);
    }).catch((error) => {
        console.log(error)
        alert("Error! ")
    });
  };


  return(
  <div id="deleteCamera" style={{  position:"fixed", width:"450px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"calc(25% - 300px)", marginLeft:" calc(50% - 630px)",zIndex:"999" }} className="container center-align">
  <h3 className="center-align defaultTheme white-text">
    {t('input.delete_confirmation')}
  </h3>
  <form name="cameraForm" id="cameraForm"onSubmit={onSubmit}>
    <p>
      {t("message.sure_delete_cameraModel") + " " +  props.delCameraModel}
    </p>
    <div className="row " style={{float:"right"}}>
      <ButtonFactory name='Submit' text={t('ok')} onClick={onSubmit} style={{marginRight:"35px"}}/>
      <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{marginRight:"15px"}}/>
    </div>
  </form>
  </div>
  );
};

DeleteCameraModel.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(DeleteCameraModel);
