import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from "redux";
import '../../../src/loader.css';
import statisticsApi from "../../api/statisticsApi";
import { ExportToCsv } from 'export-to-csv';
import ReactTableBuilder from "../../utils/reactTableBuilder";
import DatePickerFactory from "../../components/material/DatePicker";
import dayjs from 'dayjs';

class Statistics extends Component {
  constructor() {
    super();

    this.state = {
      items: [],
      startDate: dayjs(),
      endDate: dayjs(),
      errors: {},
      report: ""
    };
  };

  componentDidMount() {
    this.setDate();
  }

  setDate = () => {
    this.setState({ endDate: dayjs() })
  }

  handleKeyPress = e => {
    //const { t } = this.props;
    this.setState({ errors: {} });

    if (e.key === 'Enter' && e.target.value !== "") {
      this.onSubmit(e);
    }
  }

  onSubmit = e => {
    const { t } = this.props;
    const dtStart = this.state.startDate;
    const dtEnd = this.state.endDate;
    const dtStartValid = dtStart.$d.getTime();
    const dtEndValid = dtEnd.$d.getTime();

    if(dtStartValid <= dtEndValid) {
      statisticsApi.getReport(dtStart.toISOString(), dtEnd.toISOString())
      .then(res => {
        const items = res.data;
        if(res.data.length === 0) {
          alert(t('message.noRepairedCamera'))
        }
        this.validateData(items);
      })
      .catch(err => {
        const errors = err;
        this.setState({ errors: errors })
      });
    } else {
      alert(t('message.invalidDateRange'));
    }
    e.preventDefault();
  }

  validateData = i => {
    let validateArray = i;
    const startDate = this.state.startDate;

    for(let i = 0; i < validateArray.length; i++) {
      if(validateArray[i].imei === 'undefined'){
        validateArray[i].imei = '';
      }
      if (validateArray[i].sims === 'undefined') {
        validateArray[i].sims = '';
      }
      if(validateArray[i].date === 'undefined') {
        validateArray[i].date = ''
      }
    }

    let arFilter = validateArray.filter(filterDate);

    function filterDate(value) {
      return dayjs(value.date) > startDate;
    }

    this.setState({ items: arFilter })
  }

  onClearReport = e => {
    this.setState({ items: [], startDate: dayjs() });
    this.setDate();
    // e.preventDefault();
  }

  onExport = () => {
    let data = this.state.items;
    let newData = [];
    for(var i = 0; i < data.length; i++) {
      newData[i] = { imei: data[i].imei, sims: data[i].sims, date: new Date(data[i].date).toLocaleString("en-CA", { hour12: false }).replace(/,/g, '')};
    }
    let d = new Date();
    const options = {
      fieldSeparator: ';',
      filename: 'repairCams' + d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate(),
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      //title: '',
      useTextFile: false,
      useBom: true,
      //useKeysAsHeaders: true,
      headers: ['IMEI', 'SIM', 'Date']
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(newData);
  }

  setStartDate = e => {
    this.setState({ startDate: dayjs(e.target.value) });
  }

  setEndDate = e => {
    this.setState({ endDate: dayjs(e.target.value) });
  }

  render() {
    const { errors, startDate, endDate, items } = this.state;
    const { t } = this.props;

    const columns = [
      {
        Header: 'IMEI',
        accessor: 'imei',
      },
      {
        Header: 'SIM',
        accessor: 'sims'
      },
      {
        Header: t('setting.date'),
        accessor: row => row.date ? new Date(row.date).toLocaleString(navigator.language, { hour12: false }).replace(/,/g, '') : ""
      },
    ]

    return (
      <div>
        <div style={{ marginTop: "30px" }} className="container">
          <div className="row valign-wrapper">
            <div className="col s12 center-align">
              <form noValidate onSubmit={this.onSubmit} >
                <Row>
                  <Col s={12} m={12} >
                    <h4>{t('dashboard_main.view_statistics_title')}</h4>
                    <h6>{t('dashboard_main.view_statistics')}</h6>
                  </Col>
                </Row>
                <Row>
                  {/* *** Input Start Date *** */}
                  <Col s={12} m={3} >
                    <div className="input-field" style={{ top: "1rem" }}>
                      <DatePickerFactory label={t('input.startDate')} id="startDate" value={startDate}  onChange={this.setStartDate}/>
                    </div>
                  </Col>
                  {/* *** Input End Date *** */}
                  <Col s={12} m={3} >
                    <div className="input-field" style={{ top: "1rem", left: "2rem" }}>
                      <DatePickerFactory label={t('input.endDate')} id="endDate" value={endDate}  onChange={this.setEndDate}/>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col s={12} m={12}>
                    <button
                      style={{
                        width: "300px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        top: "3rem",
                        right: "2rem"
                      }}
                      type="submit"
                      id="btnsubmit"
                      error={errors.report}
                      disabled={ startDate === "" || endDate === "" }
                      className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                      >{t('dashboard_main.generate_report')}
                    </button>
                    <button
                      style={{
                        width: "300px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        top: "3rem",
                        left: "1rem"
                      }}                      
                      type="cancel"
                      id="btncancel"
                      error={errors.report}
                      onClick={this.onClearReport}
                      disabled={ items.length === 0 }
                      className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                      >{t('dashboard_main.clear_report')}
                    </button>
                    <button
                      style={{
                        width: "300px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        top: "3rem",
                        left: "4rem"
                      }}
                      type="button"
                      id="btnExport"
                      error={errors.report}
                      onClick={this.onExport}
                      disabled={ items.length === 0 }
                      className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                      >{t('dashboard_main.export_csv')}
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
            <Row>
              <div style={{ fontSize: "large", fontWeight: "bold", marginTop: "4rem", marginBottom: "1rem" }} className="center-align">{t('inventory.statistics.repairReportTitle')}</div>
            </Row>
            <Row>
                <ReactTableBuilder columns={columns} data={items} maxRows={10} />
            </Row>
        </div>
      </div>
    );
  }
}

Statistics.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
withTranslation(),
connect(
  mapStateToProps
)
)(Statistics);