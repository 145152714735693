import React, { useState, useEffect } from "react";
import { Col } from "react-materialize";
import { useForm } from "react-hook-form";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ButtonFactory from "../../../components/material/ButtonFactory";
import { ROLES_AVAILABLE } from "../../../utils/roleHelper";

function AddRoles(props) {
  const { t } = props;
  const possibleRoles = ROLES_AVAILABLE
  const { handleSubmit, formState: { errors } } = useForm()
  const [roles, setRoles] = useState([])
  const [rolesLinked, setRolesLinked] = useState(props.roles)

  useEffect(() => {
    let rolesArray = []
    possibleRoles.forEach(role => {
        if(!rolesLinked.includes(role)) {
            rolesArray.push(role)
        }
    });
    setRoles(rolesArray);
  }, []);

  function addElement() {
    const dropDown = document.getElementById("roles")
    const index = roles.indexOf(dropDown.value)
    roles.splice(index, 1)
    setRoles([...roles])
    rolesLinked.push(dropDown.value)
    setRolesLinked(rolesLinked)
  }

  function deleteElement(e) {
    roles.push(e.target.id)
    setRoles([...roles])
    const index = rolesLinked.indexOf(e.target.id)
    rolesLinked.splice(index, 1)
    setRolesLinked(rolesLinked)
  }

  function onSubmit() {
    props.onClose(rolesLinked)
  }

    function makeTable() {
        return rolesLinked.map((role, key) =>
        <tr key={key}>
          <td className="center-align">{role}</td>
          <td><ButtonFactory name={role} icon='delete' onClick={deleteElement}/></td>
        </tr>)
    }

    return(
      <div id="linkCameraModel" style={{  maxHeight: "95%", position:"fixed", width:"700px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"90px", marginLeft:"200px", zIndex:"100",overflowY:"scroll" }} className="container center-align">
       <Col s={12} >
        <h3 className="center-align defaultTheme white-text" >
          {t('setting.reporting_add_roles')}
        </h3>
       </Col>
       <form onSubmit={handleSubmit(onSubmit)} name="userForm" id="userForm">
        <table style={{ marginTop: "25px" }} >
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2" }}>
              <td className="center-align">{t('setting.users')}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {makeTable()}
            <tr>
                <td>
                    <select id="roles" name="roles" className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "12.5%", marginRight: "12.5%", marginBottom: "5px", width: "75%" }}>
                        {roles.map((role, index) => (
                            <option key={index} value={role}>{role}</option>
                        ))}
                    </select>
                </td>
                <td>
                    <ButtonFactory isLargeIcon={true} name="add" icon='add' onClick={addElement}/>
                </td>
            </tr>
          </tbody>
        </table>
        <div>
            
        </div>
        <hr/>
         <div className="row " style={{float:"right"}}>
          <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
        </div>
       </form>
      </div>
    );
}

AddRoles.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(AddRoles);
