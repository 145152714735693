import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { searchQuery } from "../../actions/searchActions";
import classnames from "classnames";
import Camera from "./components/camera"
import Masterpack from "./components/masterpack"

import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import { Row } from "react-materialize";

class Search extends Component {
  constructor() {
    super();
    this.state = {
      search: "",
      errors: {},
      searchResult: [],
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.search.searchResult) {
      this.setState({
        searchResult: nextProps.search.searchResult
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
      this.setState({ [e.target.id]: e.target.value });
  };


  onSubmit = e => {
      let valid = true;

      if (!this.state || this.state.search === ""){
        //document.getElementById("pwdMessage").innerHTML = "Password field is required";
        this.errors.search = "Nothing to search";
        valid = false;
      }

      //Edit for Activation code
      const editValue = this.state.search.replace(/-/g, "");

      e.preventDefault();

      const searchData = {
        search: editValue,
        date: Date()
      };

      this.onChange(e);
      // envoyer à l'api seulement si envoyer est true
      if(valid) {
        this.props.searchQuery(searchData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
      }

  };

  render() {
    const { errors } = this.state;
    const { searchResult } = this.props.search;
    const searchCount = searchResult.length;
    const { t } = this.props;

    const listSearch = searchResult
      .map((item) =>
        <li key={item.key}>
          {item.reference === "camera" ? <Camera camera={item.search} /> : <Masterpack masterpack={item.search} />}
        </li>
      );

    return (
        <div>
          <div style={{ minHeight: "75vh" }} className="container">
            <div className="row valign-wrapper">
              <div className="col m12 s12">
                <form noValidate onSubmit={this.onSubmit}>
                <Row>
                  <div className="input-field col m8 s8">
                    <input
                      onChange={this.onChange}
                      value={this.search}
                      error={errors.search}
                      id="search"
                      type="text"
                      className={classnames("", {
                        invalid: errors.search
                      })}
                    />
                    <label htmlFor="search">{t('search_main.search_label')}</label>
                    <span className="red-text">{errors.search}</span>
                  </div>
                  <div className="col m2 s4" style={{ paddingLeft: "11.250px" }}>
                    <button
                      style={{
                        width: "150px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        marginTop: "1rem"
                      }}
                      type="submit"
                      className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                    >
                      {t('search_main.search_button')}
                    </button>
                  </div>
                  </Row>
                </form>
                <div className="searchContent">
                  {searchCount>0 && (<ul>{listSearch}</ul>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}
Search.propTypes = {
  searchQuery: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  search: state.search,
  searchResult : state.searchResult,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    { searchQuery }
  )
)(Search);
