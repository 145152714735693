import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import { Row, Col } from "react-materialize";
import classnames from "classnames";
import "../../App.css";
import ButtonFactory from "../../components/material/ButtonFactory"
import cameraApi from "../../api/cameraApi"
import cameraModelApi from "../../api/cameraModelApi"
import rmaInfoApi from "../../api/rmaInfoApi"
import plantApi from "../../api/plantApi"
import ReactTableBuilder from "../../utils/reactTableBuilder";

const handleFocus = (event) => event.target.select();
function RMAReturn (props) {
    const { t } = props;
    const ref = useRef(null);
    const [scanner, setScanner] = useState("");
    const [errors, setErrors] = useState("");
    const [snackBar, setSnackBar] = useState([]);
    const [snackBarError, setSnackBarError] = useState([]);
    const [rmaNetsuite, setRmaNetsuite] = useState([])
    const [rmaNumber, setRmaNumber] = useState("")
    const [notes, setNotes] = useState("")
    var   everyItemsEqual = false
    var   everyItemsEqualQty = false
    const [isDisabled, setIsDisabled] = useState(true);
    const [camTable, setCamTable] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorRmaNotFound, setErrorRmaNotFound] = useState(false);
    const [allCameraModels, setAllCameraModels] = useState([])
    const [accessoriesNumbers, setAccessoriesNumbers] = useState([])
    const [selectValue, setSelectValue] = useState({})
    const [isChecked, setIsChecked] = useState(false)
    const [rmaAlreadyClosed, setRmaAlreadyClosed] = useState(false)
    const [rmaIsRefunded, setRmaIsRefunded] = useState(false)
    const [location, setLocation] = useState("")
    const [urlLink, setUrlLink] = useState("")
    const [atLeastOneItemIsPack, setAtLeastOneItemIsPack] = useState(false)

    useEffect(() => {
        setIsDisabled(isEveryItemsEqual())
     }, [rmaNetsuite, camTable, notes, accessoriesNumbers, urlLink]);

     useEffect(() => {
      if (camTable.length > 0) {
        restockByDefault()
      }
   }, [rmaNetsuite]);


    useEffect(() => {
      getAllCameraModels()
      getLocation()
    }, []);

    const isValidUrl = urlString=> {
      try {
      	return Boolean(new URL(urlString));
      }
      catch(e){
      	return false;
      }
  }

    function isEveryItemsEqual() {
        if (rmaNetsuite.length > 0) {
            if (everyItemsEqualQty === true || notes !== "") {
                if (isCredit()) {
                  if (urlLink === "") {
                    return true
                  }
                  if (isValidUrl(urlLink)) {
                    return false
                  } else {
                    return true
                  }
                } else {
                  return false
                }
            }
        } else {
            return true
        }
        return true
    }

      const columns = [
        {
          Header: t('dashboard_main.serialNumber'),
          accessor: 'serialNumber',
        },
        {
          Header: t('dashboard_main.camera_model'),
          accessor: 'cameraModel',
        },
        {
          Header: t('product_code'),
          accessor: 'productCode',
        },
        {
          Header: t('Restock Camera'),
          id: 'restock',
          accessor: 'restock',
          Cell: ({ row }) => (
            <div style={{ textAlign: "center" }}>
              <label>
                <input type="checkbox" class="filled-in checkbox-blue-disabled" defaultChecked={row.values.restock} onClick={() => {
                    var internalIndex = 0
                    const newRestockState = camTable.map(element => {
                      if (row.index === internalIndex) {
                        element.restock = !element.restock
                      }
                      internalIndex = internalIndex + 1
                      return element
                    });
                    setCamTable(newRestockState);
                }}/>
                <span>{}</span>
              </label>
            </div>
          ),
        },
        {
          Header: t('Remove Camera'),
          id: 'delete',
          accessor: 'delete',
          Cell: ({ row }) => (
            <div style={{ textAlign: "center" }}>
              <ButtonFactory icon='delete'
                onClick={() => {
                    const newArray = [...camTable]
                    newArray.splice(row.index, 1)
                    setCamTable(newArray)
                }}
              />
            </div>
          ),
        },
      ]

    const openSnackBar = (text, isSuccess) => {
      const newSnackBar = [];
      newSnackBar.push({text: text})
      if (isSuccess === true) {
        setSnackBar(newSnackBar);
      } else {
        setSnackBarError(newSnackBar);
      }
      setTimeout(() => {
        const emptyArray = [];
        setSnackBar(emptyArray);
        setSnackBarError(emptyArray);
      }, 3000);
    };

      function onChange(e) {
        setScanner(e.target.value);
      };

      function handleKeyPress(e) {
        if (e.key === 'Enter' && e.target.value !== "") {
            if(isValidRMA()) {
                setErrors("")
                var res = e.target.value.replace(/\D/g, "");
                rmaInfoApi.getRmaItemsNetsuite(res, callBackgetRMAReturn)
            } else if (isValidDataCamera()) {
              setErrors("")
              cameraApi.getCamera(takeOnlyIMEI()).then(res => {
                  cameraModelApi.getCameraModel(res.data.cameraModel).then(res2 => {
                    if(res2.data.productCode) {
                        if (camTable.filter(obj => obj.serialNumber === res.data.serialNumber).length > 0) {
                            openSnackBar(t("already_in_table"), false)
                        } else {
                            if (res2.data.isPack) {
                              res2.data.productCode = res2.data.productCodeLinked
                            }
                            var netsuiteRestock = rmaNetsuite.filter(obj => obj.productCode === res2.data.productCode)
                            setCamTable([...camTable, {id: res.data.id, productCode: res2.data.productCode, serialNumber: res.data.serialNumber, cameraModel: res.data.cameraModel, restock: netsuiteRestock.length ? netsuiteRestock[0].restock : true}])
                            openSnackBar(t("get_camera_success"), true)
                        }
                    } else {
                        openSnackBar(t("error_no_product_code"), false)
                    }
                  }).catch(err => {
                    openSnackBar(t("error_get_cam_model"), false)
                  })
                }
              ).catch(err => {
                openSnackBar(t("get_camera_failed"), false)
              })
            }
             else {
                setErrors(t("error_format"))
            }
          }
      }

      function getAllCameraModels() {
        cameraModelApi.getCameraModels()
          .then(res => {
            let models = res.data;
            models =  models.filter(cm => {return !cm.masterPackOnly && cm.active})
            models = [{ "name": t("global.selectAModel") }, ...models]
            models.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            setAllCameraModels(models)
          })
      }

      function takeOnlyIMEI() {

        var simNumber20 = new RegExp(/\d{20}/).exec(scanner);
        if (simNumber20) {
          return simNumber20[0]
        }
        var simNumber = new RegExp(/\d{19}/).exec(scanner);
        if (simNumber) {
          return simNumber[0]
        }

        var imeiNumber = new RegExp(/\d{15}/).exec(scanner);
        if (imeiNumber) {
          return imeiNumber[0]
        }
      }

      function restockByDefault() {
        var camTableRestock = [...camTable]
        camTableRestock.forEach(item => {
          var netsuiteRestock = rmaNetsuite.filter(obj => obj.productCode === item.productCode)
          item.restock = netsuiteRestock.length > 0 ? netsuiteRestock[0].restock : true
        })
        setCamTable(camTableRestock)
      }

      function callBackgetRMAReturn(data) {
        resetPage()
        if (data.status === "closed") {
            openSnackBar(t("rma_is_closed"), true);
            setRmaAlreadyClosed(true);
            setRmaNumber(data.items[0].rmaNumber);
        } else if (data.status === "refunded") {
            openSnackBar(t("rma_is_refunded"), true);
            setRmaIsRefunded(true);
            setRmaNumber("");
        } else {
            if (data.items && data.items.length > 0) {
                openSnackBar(t("get_RMA_info_success"), true);
                setRmaNumber(data.items[0].rmaNumber);
                data.items = flipItemTwinPack(data.items);
                setRmaNetsuite(data.items.filter(isCamOrMandatoryAcc));
            } else {
                setErrorRmaNotFound(true);
                openSnackBar(t("RMA_not_found"), false);
                setRmaNumber("");
            }
        }
    }

      function flipItemTwinPack(items) {
        items.forEach(item => {
          var productCodeGrey = findTwinPackGrey(item.productCode)

          if (productCodeGrey.isTwinPack) {
            item.quantity = parseInt(item.quantity) * parseInt(productCodeGrey.numberByPack)
            item.productCode = productCodeGrey.productCode
            setAtLeastOneItemIsPack(true)
          }
        })
        return items
      }

      function findTwinPackGrey(productCode) {
        var camModel = allCameraModels.filter(obj => obj.productCode === productCode)
        if (camModel.length > 0 && camModel[0].isPack) {
          return {isTwinPack: true, productCode: camModel[0].productCodeLinked, numberByPack: camModel[0].numberByPack}
        }
        return {isTwinPack: false}
      }

      function isCamOrMandatoryAcc(item) {
          return true
      }

      function isValidRMA() {
        var returnValue = false;
          if ((new RegExp(/^RMA\d/).test(scanner)) || (new RegExp(/^RMA-\d{8}/).test(scanner) && scanner.length === 12 ) || ((new RegExp(/\d{8}/).test(scanner)) && scanner.length === 8) || (new RegExp(/^RMA-CR\d{8}/).test(scanner))) {
            return true;
          }
          if ((new RegExp(/\d{9}/).test(scanner)) && scanner.length === 9) {
            return true
          }
          return returnValue;
      }

      function isValidDataCamera() {
        var returnValue = false;
          if ((new RegExp(/\d{15}/).test(scanner)) || (new RegExp(/\d{19}/).test(scanner))) {
            return true;
          }

          return returnValue;
      }

      function calculCamNetsuite(rmaNet) {
        var total = 0
        rmaNet.forEach(item => {
          total = total + item.quantity
        })
        return total
      }

      function createAllCount() {
        var noError = true

        var qtyCamNetsuite = calculCamNetsuite(rmaNetsuite)
        var qtyCamTable = camTable.length + accessoriesNumbers.reduce((sumTotal, accessory) => sumTotal + parseInt(accessory.value), 0)
        let dashboard = [];

        if (qtyCamNetsuite === qtyCamTable && qtyCamTable > 0) {
          everyItemsEqualQty = true
          dashboard.push(
            <Col md="auto" >
            <span style={{ fontSize: 20, color: (qtyCamNetsuite === qtyCamTable) ? "green" : "black" }}>{t("total_items")}</span><br></br>
            <span style={{ fontSize: 20, color: (qtyCamNetsuite === qtyCamTable) ? "green" : "black" }}> {qtyCamTable + " / " + qtyCamNetsuite} </span>
            </Col>
        )
        } else if ( qtyCamTable > 0 || qtyCamNetsuite > 0){
          everyItemsEqualQty = false
          dashboard.push(
            <Col md="auto" >
            <span style={{ fontSize: 20, color: "red" }}>{t("total_items")}</span><br></br>
            <span style={{ fontSize: 20, color: "red" }}> { qtyCamTable + " / " + qtyCamNetsuite } </span>
            </Col>
        )

        }

        const unique = [...new Set(camTable.map(data => data.productCode))]

        unique.forEach(model => {
            var filterRMA = rmaNetsuite.filter(obj => obj.productCode === model)
            var filterCamTable = camTable.filter(obj => obj.productCode === model)

            if (filterRMA.length > 0) {
                if (filterCamTable.length !== filterRMA[0].quantity) {
                    noError = false
                }
                dashboard.push(
                    <Col md="auto" >
                    <span style={{ fontSize: 20, color: (filterCamTable.length === filterRMA[0].quantity) ? "green" : "black" }}>{filterRMA[0].productCode}</span><br></br>
                    <span style={{ fontSize: 20, color: (filterCamTable.length === filterRMA[0].quantity) ? "green" : "black" }}>{getModelName(model)}</span><br></br>
                    <span style={{ fontSize: 20, color: (filterCamTable.length === filterRMA[0].quantity) ? "green" : "black" }}> {filterCamTable.length + " / " + filterRMA[0].quantity} </span>
                    </Col>
                )
            }
         })

         var index = 0
         rmaNetsuite.forEach(model => {
            var filterRMA = rmaNetsuite.filter(obj => obj.productCode === model.productCode)
            var filterCamTable = camTable.filter(obj => obj.productCode === model.productCode)
            if (filterCamTable.length === 0) {
                noError = false
                if (model.category !== "Accessory")
                dashboard.push(
                    <Col md="auto" >
                       <span style={{ fontSize: 20, color: "red" }}>{ model.productCode }</span><br></br>
                       <span style={{ fontSize: 20, color: "red" }}>{ getModelNameFromNetsuite(model) }</span><br></br>
                       <span style={{ fontSize: 20, color: "red" }}> { "0 /" + filterRMA[0].quantity} </span>
                    </Col>
                )
            }

            if (model.category === "Accessory") {
              if (parseInt(accessoriesNumbers[index]?accessoriesNumbers[index].value : 0) === filterRMA[0].quantity) {
                dashboard.push(
                  <Col md="auto" >
                     <span style={{ fontSize: 20, color: "green" }}>{ model.productCode }</span><br></br>
                     <span style={{ fontSize: 20, color: "green" }}>{ getModelNameFromNetsuite(model) }</span><br></br>
                     <input type="number" a-key={index} b-key={filterRMA[0].productCode} style={{width: "50px"}} onChange={setMultipleAccessory} value={accessoriesNumbers[index]?accessoriesNumbers[index].value : 0}></input>
                     <span style={{ fontSize: 20, color: "green" }}> { " / " + filterRMA[0].quantity} </span>
                  </Col>
                )
              } else {
                dashboard.push(
                  <Col md="auto" >
                     <span style={{ fontSize: 20, color: "grey" }}>{ model.productCode }</span><br></br>
                     <span style={{ fontSize: 20, color: "grey" }}>{ getModelNameFromNetsuite(model) }</span><br></br>
                     <input type="number" a-key={index} b-key={filterRMA[0].productCode} style={{width: "50px"}} onChange={setMultipleAccessory} value={accessoriesNumbers[index]?accessoriesNumbers[index].value : 0}></input>
                     <span style={{ fontSize: 20, color: "grey" }}> { " / " + filterRMA[0].quantity} </span>
                  </Col>
                )
              }
              index = index + 1
            }
         })

        everyItemsEqual = noError
        return dashboard
      }

      function setMultipleAccessory(e) {
        var updatedNumbers = [...accessoriesNumbers]
        updatedNumbers[e.target.getAttribute("a-key")] = {value: e.target.value, productCode: e.target.getAttribute("b-key")}
        setAccessoriesNumbers(updatedNumbers)
      }

      function getModelNameFromNetsuite(camera) {
          return camera.name
      }

      function getModelName(productCode) {
        var cam = allCameraModels.filter(camModel => productCode === camModel.productCode)
        if (cam.length > 0){
          return cam[0].name
        }
      }

      function resetPage() {
        setRmaNumber("")
        setNotes("")
        setUrlLink("")
        setCamTable([])
        setRmaNetsuite([])
        setAccessoriesNumbers([])
        setScanner("")
        setIsChecked(false)
        setRmaAlreadyClosed(false)
        setRmaIsRefunded(false)
        setErrorRmaNotFound(false)
        setAtLeastOneItemIsPack(false)
        everyItemsEqual = false
        everyItemsEqualQty = false
        ref.current.focus();
      }

      function getLocation() {
        plantApi.getPlant(props.auth.user.plant).then(res => {
          return setLocation(res.data.name)
        }).catch(err => {
          return setLocation("")
        })
      }

      function uniqueArray (objects, uniqueBy, keepFirst) {
        return Array.from(
            objects.reduce((map, e) => {
                let key = uniqueBy.map(key => [e[key], typeof e[key]]).flat().join('-')
                if (keepFirst && map.has(key)) return map
                return map.set(key, e)
            }, new Map()).values()
        )
    }

      function sendToNetsuite() {
        setIsLoading(true)
        var data = { id: rmaNumber, notes: notes, items: [], location: location, itemAreIdentical: everyItemsEqual, qtyItemsEqual: everyItemsEqualQty, urlLink: urlLink}
        const unique = uniqueArray(camTable, ["productCode", "restock"], true)
        unique.forEach(model => {
            data.items.push({
                id: model.productCode,
                quantity: camTable.filter(obj => obj.productCode === model.productCode && obj.restock === model.restock).length,
                restock: model.restock
            })
         })


         accessoriesNumbers.forEach(acc => {
          var netsuiteRestock = rmaNetsuite.filter(obj => obj.productCode === acc.productCode)
          data.items.push({
              id: acc.productCode,
              quantity: acc.value,
              restock: netsuiteRestock.length > 0 ? netsuiteRestock[0].restock : true
          })
         })
         if (atLeastOneItemIsPack === true) {
           data.itemAreIdentical = false
         }
        rmaInfoApi.putRMA(data, callBackPutRMA)
      }

    function callBackPutRMA(data) {
        setIsLoading(false)
        if (data.status == "success") {
            saveFootPrint()
        } else {
            openSnackBar(t("error_while_sending_RMA"), false)
        }
      }

      async function saveFootPrint() {
        const newStage = {
          step: "RMA Return",
          notes: rmaNumber
        }

      var isSuccess = await saveAllFootPrint(newStage);
      if (isSuccess) {
        resetPage()
        openSnackBar(t("RMA_saved_successfully"), true)
      } else {
        resetPage()
        openSnackBar(t("save_camera_footPrints_failed"), false)
      }
    }

    const saveAllFootPrint = async (newStage) => {
      var onlyRealCamera = camTable.filter(cam => !(cam.serialNumber === null || cam.serialNumber === "") )
      if (onlyRealCamera.length > 0) {
        return await Promise.all(camTable.map(async camera => {
          try {
            return await cameraApi.putCameraFootprint(camera.id, newStage)
          } catch (err) {
            openSnackBar(t("save_camera_footPrints_failed"), false)
          }
        }));
      }
      return true
    }

    function addModelToTable() {
      var newCamTable = [...camTable]
      var restock = rmaNetsuite.filter(obj => obj.productCode === selectValue.productCode)

      if (restock.length > 0) {
        restock = restock[0].restock
      } else {
        restock = true
      }

      newCamTable.push({id: "", productCode: selectValue.productCode, serialNumber: "", cameraModel: selectValue.model, restock: restock})

      setCamTable(newCamTable)
      setIsChecked(false)
    }

    function selectOnChange(e) {
      setSelectValue({productCode: e.target.value, model: e.target.options[e.target.selectedIndex].text})
    }

    function handleInputChange() {
      setIsChecked(!isChecked)
    }

    function isCredit() {
      const regex = /RMA-CR/;
      return regex.test(rmaNumber)
    }

    function adjustRestock() {
      var resetRestockTableView = [...camTable]
      setCamTable(resetRestockTableView)
    }

    return (
        <div>
        <Row className="center-align">
            <Col s={12} >
                <h4>{t('RMA')}</h4>
                <h6>{t('send_RMA_package')}</h6>
            </Col>
        </Row>
    <div style={{ marginTop: "10px" }} className="container">
    <div className={isLoading ? 'parentDisable' : ''} width="100%">
            <div className={isLoading ? 'loader' : ''}></div>
          </div>
        <div className="row valign-wrapper">
          <div className="col s12 center-align">
            <Row className="center-align">
                <Col s={12} m={4} >
                  <div className="input-field">
                    <input
                      disabled={isLoading}
                      onChange={onChange}
                      onFocus={handleFocus}
                      onKeyPress={handleKeyPress}
                      value={scanner}
                      error={errors.scanner}
                      id="scanner"
                      type="text"
                      autoFocus
                      className={classnames("", {
                        invalid: errors.scanner
                      })}
                      ref={ref}
                    />
                    <label htmlFor="scanner" className="active">{t('dashboard_main.scanner')}</label>
                    <span id="scannerMessage" className="red-text">
                      {errors.scanner}
                    </span>
                  </div>
                </Col>
                <Col s={8} m={8} style={{marginTop:"2%"}}>
                    {!errorRmaNotFound &&
                      <>
                      <strong  style={{fontSize:"22px"}}>
                          {t('#RMA : ')}
                      </strong>
                      <strong  style={{fontSize:"22px"}}>
                          {rmaNumber}
                      </strong>
                      { rmaAlreadyClosed &&
                        <strong  style={{fontSize:"22px", color: "red"}}>
                          {t('already_close')}
                        </strong>
                      }
                      { rmaIsRefunded &&
                        <strong style={{fontSize:"22px", color: "red"}}>
                          {t('rma_is_refunded')}
                        </strong>
                      }
                    </>
                    } {errorRmaNotFound &&
                      <strong  style={{fontSize:"22px", color: "red"}}>
                        {t('rma_not_find')}
                      </strong>
                    }

                </Col>
            </Row>
            <Row>
              <Col s={4} m={4} >
                <div style={{marginTop: "15px"}}>
                    <label>
                        <input type="checkbox" onClick={handleInputChange} class="filled-in checkbox-blue-disabled" checked={isChecked !== false ? "checked" : ""} />
                        <span>{t('check_only_if')}</span>
                  </label>
                </div>
              </Col>
            <Col s={4} m={4} >
                 <select type='select'
                      label="Camera models"
                      onChange={selectOnChange}
                      id="cameraModel"
                      disabled={isLoading || !isChecked}
                      style={{ display: isChecked ? "": "none" }}
                      >
                     {// eslint-disable-next-line array-callback-return
                       allCameraModels.map((item, index) => {
                          return <option key={index} value={item.productCode} >{item.name}</option>
                      })}
                    </select>
              </Col>
              <Col s={4} m={4}  >
                <ButtonFactory disabled={isLoading || !isChecked} name="Add this camera model" text={t("add_this_cam_model")} style={{width:"100%", marginTop: "2%", display: isChecked ? "": "none" }} icon='edit' onClick={function() { addModelToTable() }}/>
              </Col>
            </Row>
            <Row>
                {createAllCount()}
            </Row>
            <Row>
              <ReactTableBuilder columns={columns} data={camTable} maxRows={20} />
            </Row>
            { isCredit() &&
            <Row>
              <Col s={8} >
                <div className="input-field">
                    <input
                      disabled={isLoading}
                      onChange={e => setUrlLink(e.target.value)}
                      value={urlLink}
                      id="urlLink"
                      type="text"
                      className={classnames("", {
                        invalid: !isValidUrl(urlLink)
                      })}
                    />
                    <label htmlFor="urlLink" className="active">{t('URL Link Sharepoint/Trello')}</label>
                    {!isValidUrl(urlLink) && <span id="urlLink" className="red-text"> * Required </span>}
                </div>
              </Col>
            </Row>
            }
            <Row>
                <Col s={4} >
                <div className="input-field">
                    <input
                      disabled={isLoading}
                      onChange={e => setNotes(e.target.value)}
                      value={notes}
                      id="notes"
                      type="text"
                    />
                    <label htmlFor="notes" className="active">{t('notes_RMA')}</label>
                  </div>
                </Col>
                <ButtonFactory disabled={isLoading} name="Reset" text={t('reset')} style={{width:"30%", marginTop: "2%", marginRight: "50px", backgroundColor: "red"}} icon='edit' onClick={function() { resetPage() }}/>
                <ButtonFactory disabled={isDisabled || isLoading} name="Save" text={t('dashboard_main.save')} style={{marginTop: "2%", width:"30%"}} icon='edit' onClick={function() { sendToNetsuite() }}/>
            </Row>
            <div className = {snackBarError.length > 0 ? ['snackbarError', 'show'].join(" ") : 'snackbarError'}>
                { snackBarError.length > 0 ? snackBarError[0].text : '' }
            </div>
            <div className = {snackBar.length > 0 ? ['snackbar', 'show'].join(" ") : 'snackbar'}>
                { snackBar.length > 0 ? snackBar[0].text : '' }
            </div>
          </div>
          </div>
        </div>
      </div>
    )
}

RMAReturn.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });

  export default compose(
    withTranslation(),
    connect(
      mapStateToProps
    )
  )(RMAReturn);