import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-materialize";
import { connect } from "react-redux";
import classnames from "classnames";
import { formatActivationCode } from '../../utils/formatHelper';
import { isLocalPrinterReady, printDatamatrixSerialNumber, printDatamatrixFull } from '../../utils/prodlocalhelper';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import cameraModelApi from "../../api/cameraModelApi";
import { t } from "i18next";
import { getProductPageLink } from "../../utils/site"
import { getSimLetter } from "../../utils/simSupplierUtils";
import cameraApi from "../../api/cameraApi";
import simSupplierApi from "../../api/simSupplierApi";

class ReworkChangeModel extends Component {
  constructor() {
    super();
    this.state = {
      scanner: "",
      cameraModelFrom: "",
      cameraModelTo: "",
      camera: {},
      cameraModels: [],
      simSuppliers: [],
      errors: {},
      printSerialNumberLabel: true,
      printActivationLabel: true,
      printerStatus: "Loading...",
      printerName: "",
      printMessage: ""
    };

    this.onSubmit = this.onSubmit.bind(this);
  };

  loadCamerasModels = e => {
    const { t } = this.props;
    cameraModelApi.getCameraModels()
      .then(res => {
        let models = res.data;
        localStorage.setItem("cameraModels", JSON.stringify(models));
        models = [{ "name": t("global.selectAModel") }, ...models]
        models.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        this.setState({ cameraModels: models })
      })
      .catch(err =>
        localStorage.setItem("cameraModels", JSON.stringify([]))
      );
  }

  getSimLetter = sim => {
    return getSimLetter(sim, this.state.simSuppliers);
  }

  loadSimSuppliers = () => {
    simSupplierApi.getSimSuppliers()
      .then(res => {
        this.setState({ simSuppliers: res.data })
      })
      .catch(err =>
        this.setState({ simSuppliers: [] })
      );
  }

  componentDidMount() {
    this.loadCamerasModels();
    this.loadSimSuppliers();
    isLocalPrinterReady((err, res) => {
      if (err) {
        this.setState((state) => ({
          printerStatus: "ERROR",
          printerName: t("dashboard_main.local_printer_not_ready")
        }))
      } else {
        this.setState({
          printerStatus: res.data.status,
          printerName: res.data.name
        })
      }
    });
  }

  handleFocus = e => e.target.select();

  handleAfterPrint = e => {
    this.setState({ items: [] });
  }

  onCameraModelFromChange = e => {
    this.setState({ cameraModelFrom: e.target.value })
  }

  onCameraModelToChange = e => {
    this.setState({ cameraModelTo: e.target.value })
  }

  handleInputChange = e => {
    if (e.target.id === "printSerialNumberLabel") {
      this.setState({ printSerialNumberLabel: !this.state.printSerialNumberLabel })
    } else {
      this.setState({ printActivationLabel: !this.state.printActivationLabel })
    }
  }

  onChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
      printMessage: ""
     });
  };

  printActivationLabel = () => {
    cameraModelApi.getCameraModel(this.state.camera.cameraModel)
    .then(res => {
      const data = {
        datamatrix: `${this.state.camera.activationCode},${this.state.camera.imei},${this.state.camera.serialNumber},${res.data.commonName}`.padEnd(45, '-'),
        activationCode: formatActivationCode(this.state.camera.activationCode),
        serialNumber: this.state.camera.serialNumber,
        numberOfCopies: 1
      }
      printDatamatrixFull(data, (err, res) => {
        if (err) {
          this.setState({
            printerStatus: "ERROR",
            printMessage: t('dashboard_main.print_error')
          })
        } else {
          this.setState({
            printerStatus: "ready",
            printMessage: t('dashboard_main.print_success')
          })
        }
      });
    })
    .catch(err => {
      this.setState({
        printerStatus: "ERROR",
        printMessage: t('dashboard_main.print_error')
      })
    })
  }

  printSerialNumberLabel = () => {
    let sim1, sim2;
    let indicator = [];

    if (this.state.camera.sims.length === 1) {
      sim1 = this.state.camera.sims[0]
      indicator.push(this.getSimLetter(sim1));
    } else if (this.state.camera.sims.length > 1) {
      sim1 = this.state.camera.sims[0]
      sim2 = this.state.camera.sims[1]
      indicator.push(this.getSimLetter(sim1));
      indicator.push(this.getSimLetter(sim2));
    }

    const data = {
      serialNumber: this.state.camera.serialNumber,
      datamatrix: `${this.state.camera.serialNumber},${getProductPageLink()},`.padEnd(45, '-'),
      simsIndicator: indicator,
      numberOfCopies: 1
    }
    printDatamatrixSerialNumber(data, (err, res) => {
      if (err) {
        this.setState({
          printerStatus: "ERROR",
          printMessage: t('dashboard_main.print_error')
        })
      } else {
        this.setState({
          printerStatus: "ready",
          printMessage: t('dashboard_main.print_success')
        })
      }
    });
  }

  onSubmit = e => {
    this.setState({ errors: {} })
    const { cameraModelFrom, cameraModelTo, scanner } = this.state;
    let valid = true;

    if(!cameraModelFrom || cameraModelFrom === "") {
      this.setState((state) => ({
        errors: { ...state.errors,
        cameraModelFrom: t('dashboard_main.rework_change_model_from_required')
      }}))
      valid = false;
    }

    if(!cameraModelTo || cameraModelTo === "") {
      this.setState((state) => ({
        errors: { ...state.errors,
        cameraModelTo: t('dashboard_main.rework_change_model_to_required')
      }}))
      valid = false;
    }

    if(!scanner || scanner === "") {
      this.setState((state) => ({
        errors: { ...state.errors,
        scanner: t('dashboard_main.scan_required')
      }}))
      valid = false;
    }

    if (valid) {
      let sn = ""
      if (scanner.split(/,|;/).length === 4) {
        sn = scanner.split(/,|;/)[2]
      } else {
        sn = scanner.split(/,|;/)[0]
      }

      cameraApi.getCamera(sn)
      .then(res => {
        if (res.data.cameraModel === cameraModelFrom) {
          res.data.cameraModelFrom = this.state.cameraModelFrom
          res.data.cameraModelTo = this.state.cameraModelTo
          this.setState({ camera: res.data });
          const camData = {
            cameraModel: cameraModelTo
          }
          cameraApi.putCamera(res.data.id, camData)
          .then(res => {
            const footprint = {
              "step": "rework change model",
              "notes": "change the model of the camera"
            }
            cameraApi.putCameraFootprint(this.state.camera.id, footprint)
            .then(res => {
              if (this.state.printActivationLabel) {
                this.printActivationLabel()
              }
              if (this.state.printSerialNumberLabel) {
                this.printSerialNumberLabel()
              }
              this.setState({ scanner: "" })
              document.getElementById("scanner").focus();
            })
          })
          .catch(err => {
            this.setState((state) => ({
              errors: { ...state.errors,
              scanner: t('dashboard_main.change_camera_model_error')
            }}))
            valid = false
          })
        } else {
          this.setState((state) => ({
            errors: { ...state.errors,
            cameraModelFrom: t('dashboard_main.camera_model_from_error')
          }}))
          valid = false
        }
      })
      .catch(err => {
        this.setState((state) => ({
          errors: { ...state.errors,
          scanner: t('dashboard_main.scanNotRecognized')
        }}))
        valid = false
      })
    }
    e.preventDefault();
  };

  render() {
    const { errors, cameraModels, cameraModelFrom, cameraModelTo, scanner } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div style={{ minHeight: "75vh", marginTop: "30px" }} className="container">
          <div className="row valign-wrapper">
            <div className="col s12 center-align">
              <Row>
                <Col s={0}>
                  <Link to="/" className="btn-flat waves-effect">
                    <i className="material-icons left" >keyboard_backspace</i> {t('userprofile.back_to_home')}
                  </Link>
                </Col>
              </Row>
              <Row>
              <Col s={12} m={3} >
                </Col>
                <Col s={12} m={6} >
                <h4>{t('dashboard_main.rework_change_model')}</h4>
                </Col>
                <Col s={12} m={3}>
                  <i className={classnames("large material-icons", {
                    "green-text": this.state.printerStatus === "ready",
                    "red-text": this.state.printerStatus !== "ready"
                  })}>print</i>
                  <div>{this.state.printerName}</div>
                  <div>{this.state.printMessage}</div>
                </Col>
              </Row>
              <form noValidate onSubmit={this.onSubmit}>
                <Row>
                  <div className="input-field col s8">
                    <input
                      onChange={this.onChange}
                      onFocus={this.handleFocus}
                      value={scanner}
                      error={errors.scanner}
                      id="scanner"
                      type="text"
                      autoFocus
                      className={classnames("", {
                        invalid: errors.scanner
                      })}
                    />
                    <label htmlFor="scanner" className="active">{t('dashboard_main.scan_camera')}</label>
                    <span id="scannerMessage" className="red-text">
                      {errors.scanner}
                    </span>
                  </div>
                </Row>
                <Row>
                  <div className="input-field col s6">
                    <label htmlFor="cameraModelFrom" className="active">{t('dashboard_main.rework_change_model_from')}</label>
                    <select type='select'
                      onChange={this.onCameraModelFromChange}
                      value={cameraModelFrom}
                      error={errors.cameraModelFrom}
                      id="cameraModelFrom"
                      className={classnames("", {
                        invalid: errors.cameraModelFrom
                      })} >
                      {cameraModels.map((item, index) => (item.active !== false ? <option key={index} value={item.name}>{item.name}</option> : ""))}
                    </select>
                    <span id="cameraModelFrom" className="red-text">
                      {errors.cameraModelFrom}
                    </span>
                  </div>
                  <div className="input-field col s6">
                    <label htmlFor="cameraModelTo" className="active">{t('dashboard_main.rework_change_model_to')}</label>
                    <select type='select'
                      onChange={this.onCameraModelToChange}
                      value={cameraModelTo}
                      error={errors.cameraModelTo}
                      id="cameraModelFrom"
                      className={classnames("", {
                        invalid: errors.cameraModel
                      })} >
                      {cameraModels.map((item, index) => (item.active !== false ? <option key={index} value={item.name}>{item.name}</option> : ""))}
                    </select>
                    <span id="cameraModelTo" className="red-text">
                      {errors.cameraModelTo}
                    </span>
                  </div>
                </Row>
                <Row>
                  <Col s={0}>
                  <label className="active" htmlFor="printSerialNumberLabel"  style={{ width: "300px", display: "inline-block" }} >{t('dashboard_main.print_serial_number_label')}</label>
                  <input type="checkbox" id="printSerialNumberLabel" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible", width: "20px", height: "20px"}}  defaultChecked={true} onClick={this.handleInputChange}/>
                  </Col>
                </Row>
                <Row>
                  <Col s={0}>
                  <label className="active" htmlFor="printActivationLabel"  style={{ width: "300px", display: "inline-block" }} >{t('dashboard_main.print_activation_label')}</label>
                  <input type="checkbox" id="printActivationLabel" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible", width: "20px", height: "20px"}}  defaultChecked={true} onClick={this.handleInputChange}/>
                  </Col>
                </Row>
                <br />
                <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                  <button className="btn btn-large waves-effect waves-light col m4 hoverable blue accent-3"
                    style={{
                      width: "250px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem",
                      marginRight: "2px"
                    }}
                    type="submit"
                  >
                    {t('input.submit')}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="itemsContent">
            <h5>{t('dashboard_main.last_scanned_device')}</h5>
            <table>
              <thead>
                <tr>
                  <th>{t('inventory.newproduct.activationcode')}</th>
                  <th>{t('dashboard_main.serialNumber')}</th>
                  <th>Imei</th>
                  <th>{t('dashboard_main.camera_model_from')}</th>
                  <th>{t('dashboard_main.camera_model_to')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{formatActivationCode(this.state.camera.activationCode)}</th>
                  <th>{this.state.camera.serialNumber}</th>
                  <th>{this.state.camera.imei}</th>
                  <th>{this.state.camera.cameraModelFrom}</th>
                  <th>{this.state.camera.cameraModelTo}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
ReworkChangeModel.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  items: state.items
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(ReworkChangeModel);
