import React, { Component } from "react";

import PropTypes from "prop-types";
import { Col, Row } from "react-materialize";
import { connect } from "react-redux";
import classnames from "classnames";
import { regXp, isValidMasterpackBarcode } from '../../utils/validation';
import ButtonFactory from '../../components/material/ButtonFactory';
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import masterpackApi from "../../api/masterpackApi";
import ModalFactory from "../../components/modal/ModalFactory";
import cameraApi from "../../api/cameraApi";

const handleFocus = (event) => event.target.select();

class NewMasterPack extends Component {
  constructor() {
    super();
    this.state = {
      masterPackBarcode: "",
      masterpackCameraModel: "",
      serialNumber: "",
      items: [],
      scanner: "",
      errors: {},
      notes: "",
      showModal: false,
      showModalOk: false,
      shipping: {
        to: "",
        notes: "",
        date: new Date(),
        errors: {}
      }
    };
  }

  componentDidMount() {

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newmasterpack.items) {
      this.setState({ items: nextProps.newmasterpack.items });

    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange = e => {
    if (e.target.id.indexOf("shipping") !== -1) {
      var shipping = { ...this.state.shipping }
      const newId = e.target.id.replace("shipping.", "");
      this.setState({ "shipping": { ...shipping, [newId]: e.target.value } });
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }

  };

  openModal = () => {
    this.setState({showModal: true})
  }

  closeModal = () => {
    this.setState({showModal: false})
  }

  openModalOk = () => {
    this.setState({showModalOk: true})
  }

  closeModalOk = () => {
    this.setState({showModalOk: false})
  }
  
  resetMasterPack = () => {
    for (let i = 0, p = Promise.resolve(); i < this.state.items.length; i++) {
      p = p.then(_ => new Promise(resolve =>
            masterpackApi.removeItemFromMasterPack(this.state.masterPackBarcode, this.state.items[i].serialNumber).then(res => {
              resolve();
              if (i === this.state.items.length - 1) {
                this.loadMasterpack(this.state.masterPackBarcode)
              }
            }).catch(err => {
              this.setState({errors: "Error removing camera from master pack : " + err})
            })
      ));
    }

    this.setState({showModal: false})
  }

  onSubmit = e => {
    // const { t } = this.props;
    const data = {
      "notes": this.state.notes,
      "barcode": this.state.masterPackBarcode
    }

    masterpackApi.saveNotesMasterpack(data)
    .then(res => {
      // this.setState({ notes: res.data.notes })
    })
    .catch(err => {
      const errors = err;
      this.setState({ errors: errors })
    });

    e.preventDefault();
  }

  checksumMasterpack = serialNumber => {
    cameraApi.getCamera(serialNumber)
      .then(res => {
        if (this.state.masterpackCameraModel !== res.data.cameraModel) {
          this.openModalOk()
        }
      })
      .catch(err => {
        const error = err.response.data.error
        this.setState({ errors: { general: error } })
      });
  }

  addItemToMasterpack = serialNumber => {

    const shipping = Object.assign({}, this.state.shipping, { errors: undefined });

    const data = {
      "barcode": this.state.masterPackBarcode,
      "serialNumber": serialNumber,
      "shipping": shipping
    }
    masterpackApi.newMasterpack(data)
      .then(res => {
        this.setState({ items: res.data.items })
      })
      .catch(err => {
        const error = err.response.data.error

        this.setState({ errors: { general: error } })
      });

  }

  loadMasterpack = e => {
    var { value } = e
    if (!value) {
      value = e
    }
    masterpackApi.getMasterpackSN(value)
      .then(res => {
        const items = res.data.items;
        this.setState({
          masterPackBarcode: value,
          scanner: "",
          notes: res.data.notes,
          items: items || [],
          masterpackCameraModel: res.data.cameraModel?.name
        });
      })
      .catch(err => {
        const error = err.response.data.error;
        e.select();
        this.setState({ errors: { general: error } })
      });
  }

  handleKeyPress = e => {
    const { items, masterPackBarcode } = this.state;
    const errors = {};

    this.setState({ errors: errors });

    if (e.key === 'Enter' && e.target.value !== "") {
      if (e.target.value === "GG-ENTER") {
        this.setState({ scanner: "" });
        this.onSubmit(e);
      } else if (e.target.value === "GG-CLEAR") {
        this.setState({
          serialNumber: "",
          scanner: "",
          notes: "",
          items: []
        });
      } else if (isValidMasterpackBarcode(e.target.value)) {
        this.loadMasterpack(e.target);
      } else if (regXp.cameraSerialNumber.test(e.target.value.split(/,|;/)[0])) {
        if (masterPackBarcode === "") {
          errors.general = "Please select a valid master pack first";
          this.setState({ errors: errors });
          e.target.select();
        } else if (items.find(i => i.serialNumber === e.target.value.split(/,|;/)[0])) {
          errors.general = `Serial number ${e.target.value} already exists in this MasterPack`
          this.setState({ errors: errors });
        } else {
          this.checksumMasterpack(e.target.value.split(/,|;/)[0])
          this.addItemToMasterpack(e.target.value.split(/,|;/)[0]);
          this.setState({
            serialNumber: "",
            scanner: ""
          });
        }
      } else {
        errors.scanner = "Scan not recognized";
        this.setState({ errors: errors });
        e.target.select();
      }
    }
  };

  render() {
    const { errors, masterPackBarcode, notes, items } = this.state;
    const { t } = this.props;

    let listCameras = "";
    if (Array.isArray(items)) {
      listCameras = items.map((item, index) =>
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{item.serialNumber}</td>
          <td>{item.cameraModel}</td>
        </tr>
      );
    }

    return (
      <>
      <div>
        <div style={{ minHeight: "75vh" }} className="container">
          <div className="row valign-wrapper">
            <div className="col s12 center-align">
              <div className="input-field col s12">
                <Row>
                  <Col s={12} m={3} >
                  </Col>
                  <Col s={12} m={6} >
                    <h4>{t('dashboard_main.new_masterpack_title')}</h4>
                    <h6>{t('dashboard_main.build_masterpack')}</h6>
                  </Col>
                </Row>
                <div>
                  <Row >
                    <Col m={6} s={12} className="input-field">
                      <input
                        onChange={this.onChange}
                        onFocus={handleFocus}
                        onKeyPress={this.handleKeyPress}
                        value={this.state.scanner}
                        error={errors.scanner}
                        id="scanner"
                        type="text"
                        autoFocus
                        className={classnames("", {
                          invalid: errors.scanner
                        })}
                      />
                      <label htmlFor="scanner">{t('dashboard_main.scanner')}</label>
                      <span id="scannerMessage" className="red-text">
                        {errors.scanner}
                      </span>
                    </Col>
                    <Col s={12} m={6} style={{paddingTop:"25px"}}>
                      <ButtonFactory disabled={this.state.masterPackBarcode === ""} text={t('resetMasterPack')} onClick={this.openModal} style={{width:"220px"}}></ButtonFactory>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                        <span className="red-text">{errors.general}</span>
                    </Col>
                  </Row>
                  <div>
                    <span style={{ fontSize: "30px" }}>{t('dashboard_main.masterpack')} : {masterPackBarcode} </span>
                </div>
                </div>
              </div>
              <div className="itemsContent">
              {listCameras.length > 0 && <table className="prodTable striped">
                  <thead>
                    <tr>
                      <th>{t('dashboard_main.count')}</th>
                      <th>{t('dashboard_main.serialNumber')}</th>
                      <th>{t('dashboard_main.camera_model')}</th>
                    </tr>
                  </thead>
                   <tbody>
                    {listCameras}
                  </tbody>
                </table>}
              {listCameras.length === 0 && this.state.masterPackBarcode !== "" && <p>{t('noCamMasterPack')}</p>}
              </div>
            </div>
          </div>
            <Col s={12} m={12}>
              <div style={{ marginTop: "3rem" }}>
                <label className='left'>{t('input.notes')}</label>
                <div className="input-field">
                  <input
                    onChange={this.onChange}
                    onFocus={handleFocus}
                    // onKeyPress={this.handleKeyPress}
                    value={notes || ""}
                    error={errors.notes}
                    id="notes"
                    type="text"
                    className={classnames("", {
                      invalid: errors.notes
                    })}
                  />
                </div>
              </div>
          </Col>
          <Row>
            <Col s={12} m={12}>
              <div className="col s12 center-align" >
                <button
                  style={{
                    width: "305px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1.5rem",
                    marginBotton: "0.3rem"
                  }}
                  type="button"
                  id="saveNotes"
                  onClick={this.onSubmit}
                  disabled={ !notes || !masterPackBarcode }
                  className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                >{t('dashboard_main.save_notes')}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ModalFactory modalMode="modalYesNo" buttonSaveText={t('resetMasterPack')}  buttonCancelText={t('cancel')}  onClickSave={this.resetMasterPack} onClickCancel={this.closeModal} show={this.state.showModal} title={t('resetMasterPackQuestion') + " : " + this.state.masterPackBarcode} paragraph={t('resetMasterPackExplanation')} />
      <ModalFactory modalMode="modalOk" buttonOk={t('ok')} onClickOk={this.closeModalOk} show={this.state.showModalOk} title={t('attention')} paragraph={t('attention_masterpack')} />
      </>
    );
  }
}
NewMasterPack.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  items: state.items
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(NewMasterPack);
