import React from "react";
import { Col } from "react-materialize";
import CardComponent from "../material/CardComponent";
import { getSite } from '../../utils/site';


const DashboardMat = (props) => {
  let arrayOfCard = props.cardInfo;
  let {user} = props;
  const createDashboard = () => {
    let dashboard = [];
    for (let i = 0; i < arrayOfCard.length; i++) {
      // If the authorized users aren't defined, put an empty array to prevent errors
      if (typeof arrayOfCard[i].users === 'undefined') {
        arrayOfCard[i].users = [];
      }
      // Show the square to the authorized roles or the authorized users
      if ((arrayOfCard[i].roles.includes(user.role) || arrayOfCard[i].users.includes(user.email)) && arrayOfCard[i].sites.includes(getSite())) {
        dashboard.push(
          <Col m={4} s={12} className="" key={i.toString()} >
            <CardComponent 
              idCard={arrayOfCard[i].idCArd}
              toLink={arrayOfCard[i].toLink}
              keyCard={arrayOfCard[i].keyCard}
              textSpan={arrayOfCard[i].textSpan}
              textAction={arrayOfCard[i].textAction}
              text={arrayOfCard[i].text}
              >
            </CardComponent>
          </Col>
        )
      }
    }
    return dashboard
  }

  return (
        createDashboard()
  )
}
export default DashboardMat