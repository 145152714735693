import React, { Component } from 'react';
import { Row, Col } from "react-materialize";
import { Link } from "react-router-dom";
import Conditional from "../../../utils/conditional";
import { formatActivationCode } from '../../../../src/utils/formatHelper';

class Camera  extends Component {

  render() {
    // "assembledDate": "2019-02-19T12:00:00.000Z",
    // "masterPackId": "5c827e3360543b0012295e14",
    // "packagedBy": "5c818d0f9ab3ea0012e97445",
    // "packagedDate": "2019-03-20T04:45:19.256Z",
    // "shipping": null,

    const {camera} = this.props;

    return (
      <div>
        <input type="hidden" value={camera.id} />
        <Row s={12} m={12}>
          <Link to={`/inventory/viewCamera/${camera.id}`}>
            <h5>{camera.cameraModel}</h5>
          </Link>
          <Row>
            <Col s={12} m={3}>
              {camera.sims.length > 1 ? <Conditional camera={camera} /> :
              <cite style={{color:"black"}}>Serial Number : {camera.serialNumber}<br></br>Sim : {camera.sims}</cite>}
            </Col>
            <Col s={12} m={4}>
              <cite style={{color:"black"}}>IMEI : {camera.imei}<br></br>Activation code : {formatActivationCode(camera.activationCode)}</cite>          
            </Col>
          </Row>
        </Row>
      </div>
    );
  }
};

export default Camera;
