import React, { useState } from "react";
import {  Col } from "react-materialize";
import { useForm } from "react-hook-form";

import { withTranslation } from 'react-i18next';
import { compose } from "redux";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import cameraModelApi from "../../../api/cameraModelApi";
import ButtonFactory from "../../../components/material/ButtonFactory";

function AddCameraModel(props) {
  const {t} = props;
  const theYear = new Date().getFullYear();
  const { register, handleSubmit, formState: { errors }} = useForm()
  const [isMasterPackOnly, setIsMasterPackOnly] = useState(true)
  const [isPack, setIsPack] = useState(false)

  function cancelButton(e) {
    props.onClose(e);
  }

  function onSubmit(data) {
    const newCameraModel = {
          name : data.cameraModel,
          commonName: data.camera_model,
          barcode: data.barCode,
          masterPackDescription: data.description,
          nameSufix: data.sufix,
          year: parseInt(data.year),
          productCode: data.productCode,
          isPack: isPack,
          masterPackOnly: !isMasterPackOnly,
          active: true,
          productCodeLinked: data.productCodeLinked,
          numberByPack: data.numberByPack,
          versions: [{
            name: data.version,
            stage: "default",
            date: new Date().toISOString()
          }]
        };
        cameraModelApi.newCameraModel(newCameraModel)
        .then( res => {
          props.onClose();
        })
        .catch(err => {
          throw err
      });
  }

    function handleInputChange(e) {
      if (e.target.id === "isTwinPack") {
        setIsPack(!isPack)
      } else {
        setIsMasterPackOnly(!isMasterPackOnly)
      }
    };

    return(
      <div id="addCamera" style={{  maxHeight: "95%", position:"fixed", width:"550px", backgroundColor:"#e6e6e6",borderRadius:"7px",paddingTop:"0px", marginTop:"-95px", marginLeft:" calc(50% - 730px)", zIndex:"999",overflowY:"scroll" }} className="container center-align"   >
       <Col s={12} >
        <h3 className="center-align defaultTheme white-text" >
          {t('setting.camera_add')}
        </h3>
       </Col>
       <form onSubmit={handleSubmit(onSubmit)} name="cameraForm" id="cameraForm">
       <Col m={4} s={12}>
          <label className="active" htmlFor="cameraModel" style={{ width: "300px", display: "inline-block" }} >{t('setting.camera_name')}</label>
          <input type="text" name="cameraModel" className=" center-align " {...register("cameraModel", { required:  t('input.cameraModel_required') })} style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.cameraModel && <p className="red-text" >{errors.cameraModel.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="barCode" style={{ width: "300px", display: "inline-block" }} >{t('setting.camera_barcode')}</label>
          <input type="text" name="barCode" {...register("barCode", {required: t('input.barCode_required') })} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.barCode && <p className="red-text" >{errors.barCode.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="description" style={{ width: "300px", display: "inline-block" }} >{t('setting.Camera_description')}</label>
          <input type="text" name="description" {...register('description', {required: t('input.description_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.description && <p className="red-text" >{errors.description.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="camera_model" style={{ width: "300px", display: "inline-block" }} >{t('dashboard_main.camera_model')}</label>
          <input type="text" name="camera_model" {...register('camera_model', {required: t('input.camera_model_required')})} className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.camera_model && <p className="red-text" >{errors.camera_model.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="productCode" style={{ width: "300px", display: "inline-block" }} >{t('setting.productCode')}</label>
          <input type="text" name="productCode"  {...register('productCode', {required: t('input.productCode_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.productCode && <p className="red-text" >{errors.productCode.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="sufix" style={{ width: "300px", display: "inline-block" }} >{t('setting.camera_sufix')}</label>
          <input type="text" name="sufix"  {...register('sufix', {required: t('input.sufix_required')})} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.sufix && <p className="red-text" >{errors.sufix.message}</p>}
        </Col>
        <Col m={4} s={12}>
        <label className="active" htmlFor="masterPackOnly"  style={{ width: "300px", display: "inline-block" }} >{t('setting.masterPackOnly')}</label>
          <input type="checkbox" id="masterPackOnly" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible"}}  defaultChecked={isMasterPackOnly}  onClick={handleInputChange} />
        </Col>
        <Col m={4} s={12}>
        <label className="active" htmlFor="isTwinPack"  style={{ width: "300px", display: "inline-block" }} >{t('setting.isTwinPack')}</label>
          <input type="checkbox" id="isTwinPack" style={{backgroundColor: "#ffffff", opacity: "100", position: "relative", pointerEvents: "visible"}}  defaultChecked={isPack}  onClick={handleInputChange} />
        </Col>
        {isPack && 
          <>
            <Col m={4} s={12}>
              <label className="active" htmlFor="productCodeLinked" style={{ width: "300px", display: "inline-block" }} >{t('setting.productCodeLinked')}</label>
              <input type="text" name="productCodeLinked"  {...register('productCodeLinked')} className=" center-align " style={{ backgroundColor: "#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
            </Col>
            <Col m={4} s={12}>
              <label className="active" htmlFor="numberByPack" style={{ width: "300px", display: "inline-block" }} >{t('setting.numberByPack')}</label>
              <input type="number" name="numberByPack"  {...register('numberByPack')} className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
            </Col>
          </>
        }
        <Col m={4} s={12}>
          <label className="active" htmlFor="version" style={{ width: "300px", display: "inline-block" }} >{t('setting.version')}</label>
          <input type="text" name="version" {...register('version', {required: t('input.version_required')})} className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.version && <p className="red-text" >{errors.version.message}</p>}
        </Col>
        <Col m={4} s={12}>
          <label className="active" htmlFor="year" style={{ width: "300px", display: "inline-block" }} >{t('setting.year')}</label>
          <input type="number" max={theYear + 5}  name="year"  {...register('year', {required: t('input.year_required')})} className=" center-align " style={{ backgroundColor:"#ffffff", borderRadius: "7px", marginLeft: "10%", marginRight: "10%", width: "75%" }} />
          {errors.year && <p className="red-text" >{errors.year.message}</p>}
        </Col>
         <hr/>
         <div className="row " style={{float:"right"}}>
          <ButtonFactory name='Submit' text={t('ok')} onClick={handleSubmit(onSubmit)} style={{marginRight:"35px"}}/>
          <ButtonFactory name='Cancel' text={t('cancel')} onClick={cancelButton} style={{marginRight:"15px"}}/>
        </div>
       </form>
      </div>
    );
}

AddCameraModel.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
  )
)(AddCameraModel);
