import React from "react";

function ButtonRef(props) {

    let className = (props.className) ? props.className : "medium material-icons button-color-ref"

    return (
        <a href={props.href}>
            <i 
                className={className}
                style={{ marginTop: "50px" }}
            >{props.icon}</i>
        </a>
    );
}
export default ButtonRef;