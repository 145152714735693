import React, {useEffect,  useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { compose } from "redux";
import CardReporting from "../dashboard/card/CardInfoReporting";
import DashboardMat from "../../components/material/DashboardMat";
import { useTranslation } from 'react-i18next';


function ReportType (props) {
  const { t } = useTranslation()
  const { user} = props.auth;

  return (
    <div>
      <div style={{ minHeight: "75vh", marginTop: "30px" }} className="container">
        <div className="row valign-wrapper">
          <div className="col s12 center-align dashboard_containter">
              <DashboardMat user={user} cardInfo={CardReporting()}></DashboardMat>
          </div>
        </div>
      </div>
    </div>
  )
}

ReportType.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps
  )
)(ReportType);