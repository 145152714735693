import { prodapiAxiosClient } from "./prodApiClient";

const newCameraModel = (newCameraModel) => {
    return prodapiAxiosClient
    .post("/api/v1/cameraModel" , newCameraModel)
}

const getCameraModels = () => {
    return prodapiAxiosClient
    .get("/api/v1/camera/cameraModels");
}

const getCameraModel = (cameraModelName) => {
    return prodapiAxiosClient
    .get("/api/v1/cameraModel/getinfo/" + cameraModelName)
}

const putCameraModel = (cameraModel) => {
    return prodapiAxiosClient
      .put("/api/v1/cameraModel/updateCameraModel/" , cameraModel) 
}

const deleteCameraModel = (cameraName) => {
    return prodapiAxiosClient
    .delete("/api/v1/cameraModel/deleteCameraModel/" + cameraName )
}

const cameraModelApi = { getCameraModels, newCameraModel, putCameraModel, getCameraModel, deleteCameraModel}
export default cameraModelApi