import { prodapiAxiosClient } from "./prodApiClient";

const getSimSuppliers = () => {
    return prodapiAxiosClient
    .get("/api/v1/simSupplier/all")
}

const editSimSupplier = (simSupplierData, simId) => {
    return prodapiAxiosClient
    .put("/api/v1/simSupplier/" + simId, simSupplierData)
}

const insertSimSupplier = (simSupplierData) => {
    return prodapiAxiosClient
    .post("/api/v1/simSupplier/newSimSupplier", simSupplierData)
}

const simSupplierApi = { getSimSuppliers, editSimSupplier, insertSimSupplier }
export default simSupplierApi;