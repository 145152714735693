import searchApi from "../../src/api/searchApi";

export const validationDB = async (idCamera, checkData, cb) => {
  const invalid = {
    valid: true,
    sim: "",
    serialNumber: "",
    imei: ""
  };

  const camera = {
      sim0: checkData.sims0,
      sim0Check: false,
      sim1: checkData.sims1,
      sim1Check: false,
      serialNumber: checkData.serialNumber,
      serialNumberCheck: false,
      imei: checkData.imei,
      imeiCheck: false
  };

  // Check if serial number is available on DB
  if (camera.serialNumber) {
    const searchSN = {
      search: camera.serialNumber,
      date: Date()
    };
    
    camera.serialNumberCheck = await searchApi.search(searchSN)
    .then(res => {
      if (res.data[0].search.serialNumber) {
        invalid.valid = false;            
        invalid.serialNumber = "Serial number not available";        
        cb(null, invalid)
      } else {
        return true;
      } 
    })
    .catch(err => {
      //invalid.valid = true;
      //invalid.serialNumber = "Serial number not found";
      cb(err, invalid)
    });
  } else {
    camera.serialNumberCheck = true
  }

  // Check if IMEI is available on DB
  if (camera.imei) {
    const searchImei = {
      search: camera.imei,
      date: Date()
    };

    camera.imeiCheck = await searchApi.search(searchImei)
    .then(res => {
      if (res.data[0].search.imei) {
        invalid.valid = false;
        invalid.imei = "IMEI not available";
        cb(null, invalid)
      } else {
        return true
      }
    })
    .catch(err => {
      cb(err, invalid)
    });
  } else {
    camera.imeiCheck = true
  }

  // Check if sim number 0 is available on DB
  if (camera.sim0) {
    const searchSim0 = {
      search: camera.sim0,
      date: Date()
    };
    camera.sim0Check = await searchApi.search(searchSim0)
    .then(res => {
      if (res.data[0].search.id != idCamera) {
        const found = res.data[0].search.sims.find(i => i === camera.sim0)
        if (found) {
          invalid.valid = false;
          invalid.sim = "Sim card number not available";
          cb(null, invalid)
        }
      } else {
        return true
      }
    })
    .catch(err => {
      //invalid.valid = true;
      //invalid.sim = "Sim card number not found";
      cb(err, invalid)
    });
  } else  {
    camera.sim0Check = true
  }

  // Check if sim number 1 is available on DB
  if (camera.sim1) {
    const searchSim1 = {
      search: camera.sim1,
      date: Date()
    };

    camera.sim1Check = await searchApi.search(searchSim1)
    .then(res => {
      const found = res.data[0].search.sims.find(i => i === camera.sim1)
      if (found) {
        invalid.valid = false;
        invalid.sim = "Sim card number not available";
        cb(null, invalid)
      } else {
        return true
      }
    })
    .catch(err => {
      //invalid.valid = true;
      //invalid.sim = "Sim card number not found";
      cb(err, invalid)
    });
  } else {
    camera.sim1Check = true
  }

  if (camera.sim1Check && camera.sim0Check && camera.imeiCheck && camera.serialNumberCheck) {
    console.log("all checked")
    cb(null, invalid)
  }
  //cb(null, invalid)
}
